@charset "utf-8";
/* @import url(modules/fonts.css); */

html,
body {
    font-family: 'segoe_ui_regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    font-size: 12px;
    font-weight: normal;
}

@media (max-width: 419px) {
    html,
    body {
        font-size: 12px
    }
}

body {
    color: #666666;
    background: #EBEBEB;
    /*background-size: 286px 156px;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'segoe_ui_regular', sans-serif;
    font-weight: 300;
}

h1 {
    color: #004e95;
    margin: 0 0 20px 0;
    padding-left: 14px;
    font-size: 2em;
}

h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    padding: 10px 0 10px 30px;
}

h3 {
    padding-left: 5px;
    margin-bottom: 15px;
    margin-top: 30px;
    /* changed by dhilshad*/
    font-size: 1em;
}

h4 {
    font-size: 1.2em
}

h5 {
    font-size: 1em
}

h6 {
    font-size: 0.875em
}

a {
    color: #004e95;
    outline: none !important;
}

a:hover,
a:focus {
    color: #004e95
}

.container {
    padding-left: 8px;
    padding-right: 8px;
}

@media (max-width: 767px) {
    .container {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.row {
    margin-left: -8px;
    margin-right: -8px;
}

@media (max-width: 767px) {
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    padding-left: 8px;
    padding-right: 8px;
}

@media (max-width: 767px) {
    .col-xs-1,
    .col-sm-1,
    .col-md-1,
    .col-lg-1,
    .col-xs-2,
    .col-sm-2,
    .col-md-2,
    .col-lg-2,
    .col-xs-3,
    .col-sm-3,
    .col-md-3,
    .col-lg-3,
    .col-xs-4,
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xs-5,
    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xs-6,
    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xs-7,
    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xs-8,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xs-9,
    .col-sm-9,
    .col-md-9,
    .col-lg-9,
    .col-xs-10,
    .col-sm-10,
    .col-md-10,
    .col-lg-10,
    .col-xs-11,
    .col-sm-11,
    .col-md-11,
    .col-lg-11,
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12 {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.bold {
    font-weight: bold;
}

@media (max-width: 419px) {
    h1 {
        padding-left: 5px;
        font-size: 1.8em;
    }

    h2 {
        font-size: 1.5em
    }

    .hidden-xxs {
        display: none !important
    }

    tr.hidden-xxs {
        display: table-row !important
    }

    th.hidden-xxs,
    td.hidden-xxs {
        display: table-cell !important
    }

    .visible-xxs {
        display: block !important
    }

    tr.visible-xxs {
        display: none !important
    }

    th.visible-xxs,
    td.visible-xxs {
        display: none !important
    }

    .breadcrumb {
        padding-left: 6px
    }
}

#flashcontent {
    display: none;
}

#theme-wrapper {
    border-right: 8px solid #faa519;
}

#page-wrapper {
    display: block;
    background-color: #EBEBEB;
    margin-top: 74px
}

object {
    display: none;
}

.container {
    margin: 0;
    width: 100%;
}

#content-wrapper {
    background: #fff;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    border-left: 1px solid #f0eeee;
    margin-left: 54px;
    padding-bottom: 50px;
}

#primary-nav-col, #content-wrapper {
    transition: all 0.3s;
}

body #primary-nav-col {
    width: 21%;
}

body #primary-nav-col .panel-group {
    margin-bottom: 0;
}

body #primary-nav-col li section {
    display: none;
}

body #primary-nav-col li section.opened {
    z-index: 100;
    position: fixed;
    display: block;
    height: 100%;
    padding: 0 0 0 8px;
    left: 16%;
    top: 0;
}

body #content-wrapper {
    margin-left: 21%;
    background-color: #fff;
    min-height: 100vh;
}

body.dashboard #primary-nav-col {
    width: 55px;
}

body.dashboard #content-wrapper {
    margin-left: 55px;
}

@media (max-width: 991px) {
    body.dashboard #content-wrapper {
        margin-left: 0;
    }

    body #primary-nav-col,
    body.dashboard #primary-nav-col {
        position: fixed;
        overflow: auto;
        top: 73px;
        width: 99%;
        bottom: 0;
        left: 0;
        padding-bottom: 40px;
        background: #fff;
        right: 0;
    }

    #page-wrapper {
        position: relative;
    }

    body #content-wrapper {
        padding: 10px 8px 30px 8px;

        margin-left: 0;
        border-left: 0 !important;
        border-right: 0 !important;
    }
}

@media (max-width: 767px) {
    #content-wrapper {
        padding: 10px 8px 0 8px
    }
}

@media (max-width: 419px) {
    #content-wrapper {
        padding: 5px 5px 0 5px
    }
}

#content-wrapper > .row {
    opacity: 1
}

#content-header {
    background: #fff;
    margin: -15px -15px 2px -15px;
    padding: 15px;
}

@media (max-width: 767px) {
    #content-header {
        margin: -15px -5px 20px -5px
    }
}

.main-box {
    background: #FFFFFF;
    margin-bottom: 16px;
    border-radius: 0px;
    background-clip: padding-box;
    /*border: 1px solid #f0eeee;*/
}

@media (max-width: 767px) {
    .main-box {
        margin-bottom: 10px
    }
}

.main-box h2 {
    font-size: 1.3em;
    line-height: 29px;
    margin: 0;
    padding: 0 8px;
}

@media (max-width: 419px) {
    .main-box h2 {
        margin-bottom: 5px
    }
}

.main-box.no-header {
    padding-top: 20px
}

.main-box .main-box-header {
    min-height: 50px;
    padding: 10px 20px;
}

.main-box .main-box-header.with-border {
    border-bottom: 1px solid #ecf0f1
}

.main-box .main-box-body {
    padding: 0 20px 20px 20px
}

/*** ***/

.side-box .main-box-body {
    padding: 0
}

.side-box .side-box-header {
    border-radius: 0;
    background-clip: padding-box;
}

.side-box .side-box-header.bg-fd-yellow {
    background-color: #faa519
}

.side-box .side-box-header.bg-fd-grey {
    background-color: #eeeeee
}

.side-box .side-box-header .name {
    padding: 14px 0 8px 10px;
}

.side-box .side-box-header .name i {
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    padding: 0;
    float: left;
}

.side-box .side-box-header .name a {
    color: #fff;
    font-family: 'segoe_ui_bold', sans-serif;
    font-size: 16px;
    font-weight: normal;
    padding-left: 9px;
    border-radius: 0;
    background-clip: padding-box;
    line-height: 14px;
    float: left;
}

.side-box .side-box-header .name a.side-box-toggle {
    padding-right: 10px;
    float: right;
    cursor: pointer;
}

.side-box .side-box-header .name a.side-box-toggle > i {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
}

.name h3 {
	text-transform: upper;
}

.side-box .side-box-header .name a:hover {
    text-decoration: none
}

.side-box .side-box-header.bg-fd-grey .name i,
.side-box .side-box-header.bg-fd-grey .name a {
    color: #888;
}

.side-box .side-box-header.bg-fd-grey .name a.side-box-toggle > i {
    color: #888;
}

.side-box .side-box-content {
    padding: 0;
    border-left: 1px solid #fafafa;
    border-right: 1px solid #fafafa;
    border-bottom: 1px solid #fafafa;
}

.side-bar-wrapper {
    padding: 0
}

@media (max-width: 767px) {
    .side-bar-wrapper {
        padding: 0
    }
}

.side-bar-wrapper .fast-payment-inner {
    padding: 0;
}

.fast-payment-inner .fast-payment-head {
    background-color: #fff;
    padding: 13px 5px 4px 12px;
}

.fast-payment-inner > .fast-payment-head > .fast-payment-sort {
    float: left;
    padding-top: 0;
}

.fast-payment-inner > .fast-payment-head > .fast-payment-sort > h5 {
    float: left;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 14px;
    color: #004e95;
    font-weight: bold;
}

.fast-payment-inner > .fast-payment-head > .fast-payment-sort > a {
    margin: 0 0 0 6px;
    padding: 0;
    float: left;
    cursor: pointer;
    line-height: 12px;
    text-decoration: none;
}

.fast-payment-inner > .fast-payment-head > .fast-payment-sort > a > i {
    line-height: 14px;
    font-size: 14px;
    color: #004e95;
}

.fast-payment-head > .fast-payment-right {
    float: right;
}

.fast-payment-head > .fast-payment-right > .fast-payment-search {
    float: left;
}

#dashboard-search {
    width: 130px;
    margin: 0;
    position: relative;
    height: 19px;
    float: left;
    padding: 0;
    position: relative;
}

#dashboard-search input[type="text"] {
    height: 19px;
    font-family: 'segoe_ui_regular';
    font-size: 14px;
    display: inline-block;
    font-weight: normal;
    border: none;
    outline: none;
    color: #666;
    padding: 3px 22px 0 0;
    width: 0px;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    z-index: 3;
    transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
    cursor: pointer;
}

#dashboard-search input[type="text"]:focus:hover {
    border-bottom: 1px solid #BBB;
}

#dashboard-search input[type="text"]:focus {
    min-width: 130px;
    z-index: 1;
    border-bottom: 1px solid #BBB;
    cursor: text;
}

#dashboard-search input[type="submit"] {
    height: 19px;
    width: 22px;
    display: inline-block;
    color: red;
    float: right;
    /* background: url('../../img/search.png') center center no-repeat; */
    text-indent: -10000px;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    cursor: pointer;
    transition: opacity 1s ease;
}

#dashboard-search input[type="submit"]:hover {
    opacity: 0.5;
}

.fast-payment-head > .fast-payment-right > .dashboard-add-payee {
    float: right;
    margin-left: 15px;
}

.fast-payment-head > .fast-payment-right > .dashboard-add-payee > a {
    text-decoration: none;
}

.fast-payment-head > .fast-payment-right > .dashboard-add-payee > a > i {
    font-size: 22px;
    color: #6fb743;
    line-height: 20px;
}

.side-bar-wrapper .fast-payment-inner > .fast-payment-item {
    padding: 6px 10px;
    position: relative;
    background: #fafafa;
}

.side-bar-wrapper .fast-payment-inner > .fast-payment-item:nth-child(2n-1) {
    background: #fff;
}

.fast-payment-item > .fast-payment-title {
    cursor: pointer;
}

.fast-payment-item > .fast-payment-title > .fast-payment-user {
    float: left;
}

.fast-payment-item > .fast-payment-title > .fast-payment-user > img {
    float: left;
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 50%;
    background-clip: padding-box;
}

.fast-payment-item > .fast-payment-title > .fast-payment-user > .name {
    float: left;
    font-weight: normal;
    font-size: 15px;
    color: #666;
    height: 34px;
    padding-top: 5px;
    padding-left: 11px;
}

.fast-payment-item > .fast-payment-title > .fast-payment-user > .name a {
    color: #666;
}

.fast-payment-item > .fast-payment-title > .fast-payment-more {
    float: right;
    height: 34px;
    padding-top: 7px;
}

.fast-payment-item > .fast-payment-title > .fast-payment-more > i.icon-fb_bank {
    font-size: 19px;
    color: #004e95;
    padding-right: 20px;
    display: none;
    float: left;
}

.fast-payment-item > .fast-payment-title > .fast-payment-more > i.fast-drop-down {
    font-size: 12px;
    float: right;
    color: #cdcdcd;
    margin-right: 0;
    padding-top: 5px;
}

.fast-payment-item > .fast-payment-details {
    margin-top: 8px;
    display: none;
}

.fast-payment-item > .fast-payment-details > form > .fast-payment-label {
    margin-top: 3px;
    margin-bottom: 10px;
}

.fast-payment-item > .fast-payment-details > form > .fast-payment-label > h3 {
    margin: 0;
    padding: 0 8px 0 0;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    float: left;
    color: #768082;
}

.fast-payment-item > .fast-payment-details > form > .fast-payment-label > h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    float: left;
    color: #768082;
}

.fast-payment-item > .fast-payment-details > form > .form-group {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
}

.recent-transactions-inner {
    position: relative;
}

.side-bar-wrapper .recent-transactions-item {
    padding: 8px 10px;
    position: relative;
    background: #fafafa;
}

.side-bar-wrapper div:nth-child(2n).recent-transactions-item {
    background: #fff;
}

.side-bar-wrapper .recent-transactions-item > div > .recent-transactions-amount {
    float: left;
}

.side-bar-wrapper .recent-transactions-item > div > .recent-transactions-amount > i {
    float: left;
    color: #6fb743;
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
    padding-right: 13px;
    line-height: 17px;
}

.side-bar-wrapper .recent-transactions-item > div > .recent-transactions-amount > .amount {
    float: left;
    font-weight: normal;
    font-size: 12px;
    color: #666;
    padding: 0;
    line-height: 17px;
}

.side-bar-wrapper .recent-transactions-item > div > .recent-transactions-amount > .amount > i {
    margin-right: 0;
    font-size: 15px;
}

.side-bar-wrapper .recent-transactions-item > div > .recent-transactions-date {
    float: right;
}

.side-bar-wrapper .recent-transactions-item > div > .recent-transactions-date > .amount-date {
    font-weight: normal;
    font-size: 12px;
    color: #b0b0b0;
}

.side-bar-wrapper .recent-transactions-item > .recent-transactions-lower > .recent-transactions-user {
    float: left;
}

.side-bar-wrapper .recent-transactions-item > .recent-transactions-lower > .recent-transactions-user > .name {
    float: left;
    padding: 0;
}

.side-bar-wrapper .recent-transactions-item > .recent-transactions-lower > .recent-transactions-user > .name > h3 {
    float: left;
    font-weight: normal;
    font-size: 12px;
    color: #004e95;
    padding: 0px;
    margin: 0;
    line-height: 14px;
}

.side-bar-wrapper .recent-transactions-item > div > .recent-transactions-user > .name > h3:first-child {
    padding-right: 7px;
    color: #666;
}

.side-bar-wrapper .recent-transactions-repeat {
    float: right;
    padding-top: 0;
}

.side-bar-wrapper .recent-transactions-repeat > a > i.icon-fb_repeat {
    float: left;
    color: #6fb743;
    font-size: 16px;
    font-weight: normal;
    margin-top: 0px;
    line-height: 17px;
}

.side-bar-wrapper .recent-transactions-repeat > h3 {
    float: left;
    font-weight: normal;
    font-size: 14px;
    color: #666464;
    padding: 0px;
    margin: 0;
    line-height: 16px;
    padding-left: 4px;
}

.recent-transactions-inner > .recent-transactions-details {
    position: absolute;
    border: 2px solid #004e95;
    padding: 13px 10px 4px 10px;
    left: 0;
    top: 60px;
    z-index: 999;
    background-color: #fff;
}

.recent-transactions-inner > .recent-transactions-details > a.recent-popup-close {
    display: block;
    background-color: #004e95;
    width: 24px;
    height: 24px;
    padding: 2px 0 0 0;
    text-align: center;
    text-decoration: none;
    position: absolute;
    right: -2px;
    top: -24px;
    cursor: pointer;
}

.recent-transactions-details > a.recent-popup-close > i {
    color: #fff;
    font-size: 16px;
    line-height: 14px;
    font-weight: normal;
}

.recent-transactions-inner > .recent-transactions-details > form > .form-group {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
}

.side-bar-wrapper .mini-statement-item {
    padding: 0;
    position: relative;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    margin: 7px 10px;
    padding-bottom: 3px;
}

.side-bar-wrapper div:last-child.mini-statement-item {
    border-bottom: 0;
}

.side-bar-wrapper .mini-statement-item > .mini-statement-date {

}

.side-bar-wrapper .mini-statement-item > .mini-statement-date > h4 {
    font-weight: bold;
    font-size: 12px;
    color: #faa519;
    display: block;
    margin: 0 0 9px 0;
    padding: 0;
    line-height: 13px;
}

.side-bar-wrapper .mini-statement-item > .mini-statement-summary {
    min-height: 30px;
}

.side-bar-wrapper .mini-statement-item > .mini-statement-summary > .mini-statement-details {
    float: left;
    width: 70%;
}

.side-bar-wrapper .mini-statement-item > .mini-statement-summary > .mini-statement-details > .name {
    float: left;
    font-weight: normal;
    font-size: 12px;
    color: #666;
    padding: 0px;
    margin: 0;
    line-height: 14px;
    vertical-align: middle;
}

.side-bar-wrapper .mini-statement-item > .mini-statement-summary > .mini-statement-amount {
    float: right;
}

.side-bar-wrapper .mini-statement-item > .mini-statement-summary > .mini-statement-amount > .amount {
    font-weight: normal;
    font-size: 14px;
    color: #666;
    padding: 0;
    line-height: 14px;
}

.side-bar-wrapper .mini-statement-item > .mini-statement-summary > .mini-statement-amount > .amount > i {
    margin-right: 0;
    font-size: 14px;
}

/* ====================
Account Balance Box
--------------------------------------------------------------*/

.account-balance-box {
    position: relative;
    border: 0;

}

.account-balance-box .main-box-body {
    padding: 0
}

.account-balance-box .account-balance-summary {
    padding: 11px 9px 13px 16px;
    background-color: #6fb743;
    color: #fff;
    text-align: right;
}

@media (max-width: 767px) {
    .account-balance-box .account-balance-summary {
        text-align: center;
    }
}

.account-balance-box .account-balance-summary > .title > h3 {
    /* float: left; */
    font-size: 18px;
    margin: 0;
    padding: 0;
    line-height: 28px;
}

.account-balance-box .account-balance-summary > .title > a.account-balance-button {
    float: right;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

.account-balance-box .account-balance-summary > .title > a.account-balance-button > i {
    color: #6fb743;
    font-size: 15px;
    font-weight: normal;
    margin-top: 7px;
}

.account-balance-summary > .account-balance-amount {
    padding-top: 3px;
}

.account-balance-summary > .account-balance-amount > h1 {
    font-size: 22px;
    font-weight: bold;
    /* float: left; */
    margin: 0;
    padding: 0;
    line-height: 34px;
    color: #fff;
}

.account-balance-summary > .account-balance-amount > h1 span {
    font-size: 0.75em;
    padding-right: 5px;
}

.account-balance-summary > .account-balance-amount > h1 > span.balance-amount-end {
    font-size: 24px;
    color: #fff;
}

.account-balance-summary > .account-balance-amount > h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    padding: 0 4px 0 0;
    line-height: 36px;
    float: left;
}

.account-balance-box > .main-box-body > .account-balance-content {
    display: none;
    position: absolute;
    left: 0;
    top: 100;
    z-index: 100;
    width: 100%;
    border-bottom: 1px solid #cbcbcb;
}

.account-balance-content > .account-content-item > .account-balance-header {
    border: 0;
}

.account-balance-content > .account-content-item > .account-balance-header.bg-fd-green {
    background-color: #a0d57f;
    border-left: 1px solid #a0d57f;
    border-right: 1px solid #a0d57f;
}

.account-balance-content > .account-content-item > .account-balance-header.bg-fd-grey {
    background-color: #eeeeee;
    border-left: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.account-balance-content > .account-content-item > .account-balance-header > h4 {
    float: left;
    font-size: 16px;
    color: #fff;
    margin: 0;
    padding: 0 0 0 16px;
    line-height: 40px;
}

.account-balance-content > .account-content-item > .account-balance-header > a.account-balance-toggle {
    padding-right: 10px;
    padding-top: 10px;
    float: right;
    cursor: pointer;
}

.account-balance-content > .account-content-item > .account-balance-header > a.account-balance-toggle > i {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
}

.account-balance-content > .account-content-item > .account-balance-header.bg-fd-grey > h4 {
    color: #888;
}

.account-balance-content > .account-content-item > .account-balance-header.bg-fd-grey > a.account-balance-toggle > i {
    color: #888;
}

.account-balance-content > .account-content-item > .account-balance-body {
    display: none;
    background-color: #fff;
    color: #999;
    border: 0;
    border-left: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}

.account-content-item > .account-balance-body > .account-balance-item {
    padding: 0 6px 0 16px;
}

.account-content-item > .account-balance-body > div:first-child.account-balance-item {
    padding-top: 9px;
}

.account-balance-body > .account-balance-item > h5 {
    font-size: 14px;
    font-weight: bold;
    color: #999999;
    margin: 0;
    padding: 0;
    line-height: 35px;
    float: left;
}

.account-balance-body > .account-balance-item > h6 {
    font-size: 14px;
    font-weight: normal;
    color: #999999;
    margin: 0;
    padding: 0 0 0 3px;
    line-height: 35px;
    float: left;
}

.accounts-highlight-box .main-box-body {
    padding: 0
}

.accounts-highlight-box #accounts-highlight-body {
    background-color: #004e95;
}

#accounts-highlight-body .accounts-highlight-item {
    display: block;
    width: 100%;
    background-color: #004e95;
    text-align: center;

}

#accounts-highlight-body .accounts-highlight-item > h2 {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    display: block;
    margin: 7px 0 6px 0;
    padding: 0;
    line-height: 24px;
}

#accounts-highlight-body .accounts-highlight-item > h3 {
    color: #faa519;
    font-weight: normal;
    font-size: 17px;
    display: block;
    margin: 12px 0 0 0;
    padding: 0;
    line-height: 17px;
    text-transform: uppercase;
}

#accounts-highlight-body .accounts-highlight-item > h5 {
    color: #80b9ed;
    font-weight: normal;
    font-size: 12px;
    display: block;
    margin: 0 0 13px 0;
    padding: 0;
    line-height: 13px;
}

.accounts-highlight-box > .main-box-body > .customNavigation {
    width: 100%;
    position: absolute;
    top: 32px;
}

.accounts-highlight-box > .main-box-body > .customNavigation > a {
    position: absolute;
    text-decoration: none;
    cursor: pointer;
}

.accounts-highlight-box > .main-box-body > .customNavigation > a.prev-arrow {
    left: 20px;
}

.accounts-highlight-box > .main-box-body > .customNavigation > a.next-arrow {
    right: 40px;
}

.accounts-highlight-box > .main-box-body > .customNavigation > a.next-arrow > i,
.accounts-highlight-box > .main-box-body > .customNavigation > a.prev-arrow > i {
    font-size: 30px;
    color: #fff;
}

/*
#accounts-highlight-body.owl-theme .owl-controls{
    margin-top: 0px;
    text-align: center;
}



#accounts-highlight-body.owl-theme .owl-controls .owl-buttons div{
    color: #FFF;
    display: block;
    zoom: 1;
    position: relative;
    bottom: 40%;
    margin: 0px;
    padding: 0;
}
#accounts-highlight-body.owl-theme .owl-controls .owl-buttons .owl-prev{
    color: #000;
    position: absolute;
    zoom: 1;
    left:31px;
    margin: 0;
    padding: 0;
    font-size: 15px;
    background: #869791;

}
#accounts-highlight-body.owl-theme .owl-controls .owl-buttons .owl-next{
    color: #000;
    position: absolute;
    zoom: 1;
    right:31px;
    margin: 0;
    padding: 0;

    font-size: 15px;
    background: #869791;
}*/

.account-details-tab {
    border: 1px solid #f9f9f9;
}

.account-details-tab .main-box-body {
    padding: 0
}

.account-details-tab .main-box-body .tabs-wrapper > .nav-tabs {
    margin-bottom: 0;
    border: 0
}

.account-details-tab .main-box-body .tabs-wrapper > .tab-content {
    margin-bottom: 0;
    padding: 0;
}

.tabs-wrapper > ul.nav-tabs {
    background-color: #fff;
    border-radius: 0;
    background-clip: padding-box;
    color: #fff;
    text-align: center;
}

.tabs-wrapper > ul.nav-tabs > li {
    width: 33.33%;
    display: inline-block;
}

.tabs-wrapper > ul.nav-tabs > li > a {
    border-radius: 0;
    background-clip: padding-box;
    outline: none;
    color: #fff;
    border: 0;
    background-color: #999;
    line-height: 14px;
}

.tabs-wrapper > .nav-tabs > li > a > span {
    font-size: 14px;
    font-weight: bold;
    outline: none;
    text-transform: uppercase;
    line-height: 14px;
    padding: 0;
}

.tabs-wrapper > .nav-tabs > li > a > i {
    font-size: 18px;
    font-weight: bold;
}

.tabs-wrapper > .nav-tabs > li > a:hover,
.tabs-wrapper > .nav-tabs > li > a:focus {
    border-color: transparent;
    border-radius: 0;
    background-clip: padding-box;
    color: #555;
    background-color: #fff;
    color: #004e95;
}

.tabs-wrapper > .nav-tabs > li.active > a,
.tabs-wrapper > .nav-tabs > li.active > a:hover,
.tabs-wrapper > .nav-tabs > li.active > a:focus {
    border-radius: 0;
    background-clip: padding-box;
    background-color: #fff;
    color: #004e95;
    border: 0;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}

#tab-account-summary > .main-box-body {
    padding: 0
}

#tab-account-summary > .main-box-body .panel-group {
    margin-bottom: 0;
}

#accordion-account-summary .panel {
    background-color: #fff;
    margin-bottom: 0;
}

#accordion-account-summary .panel-group .panel {
    border-radius: 0;
    background-clip: padding-box;
    border: 0 none;
}

#accordion-account-summary .panel-default > .panel-heading {
    background-color: #fff;
    border-top: 1px solid #fafafa;
    border-bottom: 0;
    border-radius: 0;
    background-clip: padding-box;
}

#accordion-account-summary div:first-child.panel-default > .panel-heading {
    border-top: 0;
}

#accordion-account-summary .panel .panel-collapse {
    border-bottom: 0;
    border-top: 0;
}

#accordion-account-summary .panel-title > a.accordion-toggle {
    background-color: #faa519;
    color: #fff;
}

#accordion-account-summary .panel-title > a.accordion-toggle.collapsed {
    background-color: #eee;
    color: #948888;
}

#accordion-account-summary .panel-title > a {
    display: block;
    position: relative;
    outline: none;
    text-decoration: none;
    font-family: 'segoe_ui_bold', sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: #8888a1;
    padding: 12px 0 8px 8px;
    background: #eeeeee;
}

.panel-title > a.account-summary {
    display: block;
    position: relative;
    outline: none;
    text-decoration: none;
    font-family: 'segoe_ui_bold', sans-serif;
    font-size: 16px;
    font-weight: normal;
    background-color: #faa519;
    color: #fff;
    padding: 12px 0 8px 33px;
}

#accordion-account-summary .panel-title > a:hover,
#accordion-account-summary .panel-title > a:focus {
    text-decoration: none;
    color: #fff;
    background-color: #faa519;
}

#accordion-account-summary .panel-title > a:after {
    content: "";
    font-family: 'fednet-fonts';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin-top: -5px;
    font-size: 12px;
    position: relative;
    right: 0;
    top: 50%;
    padding-left: 10px;
    float: right;
}

#accordion-account-summary .panel-title > a.accordion-toggle.collapsed:after {
    content: "";
    font-family: 'fednet-fonts';
    float: right;
}

#accordion-account-summary .panel-title > a.accordion-toggle > i {
    width: 24px;
    text-align: center;
}

.summary-table-box {
    border: 0;
}

.summary-table-box > .main-box-body {
    padding: 10px;
}

.summary-table-box > .main-box-body .table-bordered {
    padding: 0 33px;
}

.summary-table-box > .main-box-body .table-bordered th {
    border: 1px solid #e0eefa;
}

.summary-table-box > .main-box-body .table-bordered th {
    border: 1px solid #e0eefa;
    border-left: 0;
    border-right: 0;
    padding: 3px;
}

.summary-table-box > .main-box-body .table-bordered td {
    padding: 4px;
    border: 1px solid #e0eefa;
}

.account-summary-brief {
    border: 0px solid #ff0000;
    padding: 17px 0;
}

.account-summary-brief > .summary-money-have {
    border: 0px solid #ff0000;
    float: left;
    width: 50%;
    padding-left: 15px;
}

.account-summary-brief > .summary-money-borrowed {
    border: 0px solid #ff0000;
    float: right;
    width: 50%;
    padding-left: 0;
}

.account-summary-brief > .summary-money-have  h4,
.account-summary-brief > .summary-money-borrowed  h4 {
    border: 0px solid #000;
    float: left;
    margin: 0;
    padding: 2px 0 5px 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    color: #333;
}

.account-summary-brief > .summary-money-have  h3,
.account-summary-brief > .summary-money-borrowed  h3 {
    float: left;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

.account-summary-brief > .summary-money-have  h3 > span.balance-amount-end,
.account-summary-brief > .summary-money-borrowed  h3 > span.balance-amount-end {
    font-size: 16px;
}

.account-summary-brief > .summary-money-have  h5,
.account-summary-brief > .summary-money-borrowed  h5 {
    float: left;
    margin: 0;
    padding: 5px 0 0 8px;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;

}

.account-summary-brief > .summary-money-have  h3,
.account-summary-brief > .summary-money-have  h5 {
    color: #5db12a;
}

.account-summary-brief > .summary-money-borrowed  h3,
.account-summary-brief > .summary-money-borrowed  h5 {
    color: #df1414;
}

.dashboard-ad-box .main-box-body {
    padding: 0
}

.dashboard-ad-box .main-box-body > a.dashboard-ad-component {
    display: block;
    text-align: center;
    padding: 0px;
    color: #878787;
    text-decoration: none;
    border: 1px solid #bfbfbf;
    min-height: 40px;
}

.dashboard-ad-box .main-box-body > a.dashboard-ad-component > img {
    display: inline-block;
    padding-top: 8px;
}

.ad-center-box .main-box-body {
    padding: 0
}

.ad-center-box .ad-center-box-header {
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    background-clip: padding-box;
    min-height: 97px;
    position: relative;
}

.ad-center-box a.ad-center-button {
    position: absolute;
    width: 118px;
    height: 26px;
    text-align: center;
    padding: 0;
    color: #fff;
    text-decoration: none;
    border: 2px solid #faa519;
    right: 16px;
    top: 40%;

}

.ad-center-box a.ad-center-button > span {
    color: #fff;
    font-family: 'segoe_ui_regular', sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
}

.ad-center-box a.ad-center-button > i {
    margin-left: 0px;
    text-align: left;
    font-weight: normal;
}

/* clearfix */
.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

/* display none until init */
.owl-carousel {
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
    float: left;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
    cursor: pointer;
}

.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
    /* cursor: url(grabbing.png) 8 8, move; */
}

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.owl-theme .owl-controls {
    margin-top: 10px;
    text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div {
    color: #FFF;
    display: inline-block;
    zoom: 1;
    display: inline; /*IE7 life-saver */
    margin: 5px;
    padding: 3px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: #869791;
    filter: Alpha(Opacity=50); /*IE7 fix*/
    opacity: 0.5;
}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
    filter: Alpha(Opacity=100); /*IE7 fix*/
    opacity: 1;
    text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page {
    display: inline-block;
    zoom: 1;
    display: inline; /*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
    display: block;
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    filter: Alpha(Opacity=50); /*IE7 fix*/
    opacity: 0.5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    filter: Alpha(Opacity=100); /*IE7 fix*/
    opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers {
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

/* preloading images */
.owl-item.loading {
    min-height: 150px;
    /* background: url(AjaxLoader.gif) no-repeat center center */
}

#tab-account-summary,
#tab-send-money,
#tab-services {
    min-height: 150px;
}

.bubble {
    position: relative;
    width: 120px;
    height: 45px;
    padding: 0px;
    background: #faa519;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 6px 4px 0;
    border-color: #faa519 transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -6px;
    left: 56px;
}

#send-money-wrapper, #dashboard-services-wrapper {
    position: relative;
}

#send-money-wrapper #send-money-slider,
#dashboard-services-wrapper #dashboard-services-slider {
    margin: 0;
    padding: 0;
    border-right: 1px solid #faa519;
}

#send-money-slider li.send-money-item,
#dashboard-services-slider li.dashboard-services-item {
    border: 1px solid #faa519;
    border-right: 0;
    list-style: none;
    height: 46px;
    text-align: center;
}

#send-money-slider li.send-money-item > a,
#dashboard-services-slider li.dashboard-services-item > a {
    color: #666;
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    line-height: 19px;
    padding: 13px 0 12px 0;
    display: block;
    background-color: #fff;
}

#send-money-slider li.send-money-item > a:hover,
#dashboard-services-slider li.dashboard-services-item > a:hover {
    color: #fff;
    background-color: #faa519;
}

#send-money-slider li.send-money-item > a.slider-select,
#dashboard-services-slider li.dashboard-services-item > a.slider-select {
    color: #fff;
    background-color: #faa519;
}

#send-money-wrapper > h4 {
    color: #175e9f;
    font-size: 16px;
    font-weight: bold;
    margin: 26px 0 14px 0;
    padding: 0;
    line-height: 16px;
}

#send-money-wrapper > .customNavigation {
    width: 100%;
    position: absolute;
    top: 45px;
}

#dashboard-services-wrapper > .customNavigation {
    width: 100%;
    position: absolute;
    top: 14px;
}

#send-money-wrapper > .customNavigation > a,
#dashboard-services-wrapper > .customNavigation > a {
    position: absolute;
    text-decoration: none;
    cursor: pointer;
}

#send-money-wrapper > .customNavigation > a.prev-arrow,
#dashboard-services-wrapper > .customNavigation > a.next-arrow {

}

#send-money-wrapper > .customNavigation > a.prev-arrow,
#dashboard-services-wrapper > .customNavigation > a.prev-arrow {
    left: -22px;
}

#send-money-wrapper > .customNavigation > a.next-arrow,
#dashboard-services-wrapper > .customNavigation > a.next-arrow {
    right: -22px;
}

#send-money-wrapper > .customNavigation > a.next-arrow > i,
#send-money-wrapper > .customNavigation > a.prev-arrow > i,
#dashboard-services-wrapper > .customNavigation > a.next-arrow > i,
#dashboard-services-wrapper > .customNavigation > a.prev-arrow > i {
    font-size: 18px;
}

.dashboard-form-box {
    margin-top: 12px;
}

.dashboard-form-box .main-box-body {
    padding: 0
}

.dashboard-form-box .form-box-content {
    padding: 0;
    text-align: center;
}

.dashboard-form-box .form-box-content p.form-field-title {
    font-family: 'segoe_ui_bold', sans-serif;
    font-size: 16px;
    font-weight: normal;
    padding: 24px 0 10px 12px;
    color: #666;
}

.dashboard-form-row {
    margin-left: -40px;
    margin-right: -40px;
}

/*** ***/

.form-box .main-box-body {
    padding: 0;
    border: 1px solid #004e95;
    overflow-wrap: break-word !important;
}

.form-box .form-box-header {
    border-radius: 0;
    background-color: #004e95;
    background-clip: padding-box;

}

.form-box .form-box-header .name a {
    color: #fff;
    display: block;
    font-family: 'segoe_ui_bold', sans-serif;
    /*font-size: 15px;*/
    font-size: 12px;
    font-weight: normal;
    padding: 10px 12px;
    border-radius: 0;
    background-clip: padding-box;
    transition: background-color 0.1s ease-in-out 0s;
}

.form-box .form-box-header .name a:hover {
    text-decoration: none
}

.form-box .form-box-header.blue-bg .name a:hover {
    background: #68b3a5
}

.form-box .form-box-content {
    padding: 17px 20px 5px;
}

.form-box .form-box-content .chart {
    position: relative;
    text-align: center;
    font-weight: 300;
    font-size: 1em;
    width: 130px;
    height: 130px;
    display: block;
    margin: 0 auto;
    padding-top: 37px;
}

.form-box .form-box-content .chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.form-box .form-box-content .chart .percent {
    font-size: 2.1em;
    font-weight: 600;
}

.form-box .form-box-content .chart .lbl {
    display: block;
    font-size: 0.875em;
    margin-top: -7px;
}

.form-box .form-box-footer {
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
}

.form-box .form-box-footer a {
    border-right: 1px solid #eee;
    color: #929292;
    display: block;
    float: left;
    margin: 20px 0;
    padding: 5px 10px;
    text-align: center;
    width: 33.33%;
}

.form-box .form-box-footer a:last-child {
    border: none
}

.form-box .form-box-footer a:hover {
    text-decoration: none
}

.form-box .form-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 300;
}

.form-box .form-box-footer .label {
    display: block;
    font-size: 0.875em;
    font-weight: 300;
    color: #929292;
}

.form-box .form-box-ultrafooter {
    background: #f7fafa;
    border-top: 1px solid #e9eef0;
    padding: 8px 15px;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
}

.form-box .form-box-ultrafooter .form-img-owner {
    border-radius: 50%;
    background-clip: padding-box;
    display: block;
    float: left;
    height: 33px;
    margin-right: 5px;
    overflow: hidden;
    width: 33px;
}

.form-box .form-box-ultrafooter .link {
    margin-top: 6px;
    color: #c1c5c6;
}

.form-box.emerald-box .form-box-footer a:hover {
    color: #004e95
}

.form-box.red-box .form-box-footer a:hover {
    color: #f4786e
}

.form-box.yellow-box .form-box-footer a:hover {
    color: #ffc107
}

.form-box.green-box .form-box-footer a:hover {
    color: #7FC8BA
}

.form-box.purple-box .form-box-footer a:hover {
    color: #9972B5
}

.form-box.gray-box .form-box-footer a:hover {
    color: #90a4ae
}

/*
.form-box {
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
    margin-bottom: 16px;
    border-radius: 3px;
    background-clip: padding-box;
}
@media (max-width: 767px) {
    .form-box { margin-bottom: 10px }
}
.form-box h2 {
    font-size: 1.3em;
    line-height: 29px;
    margin: 0;
    padding: 0;
}
@media (max-width: 419px) {
    .form-box h2 { margin-bottom: 5px }
}
.form-box.no-header { padding-top: 20px }
.form-box .form-box-header {
    min-height: 50px;
    padding: 10px 20px;
}
.form-box .form-box-header.with-border { border-bottom: 1px solid #ecf0f1 }
.form-box .form-box-body { padding: 0 20px 20px 20px }*/

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
    padding-left: 8px
}

.navbar-toggle {
    float: left;
    border: medium none;
    font-size: 1.4em;
    height: 65px;
    margin: 0;
    text-shadow: none;
    width: 30px;
    z-index: 100;
    border-radius: 0;
    background-clip: padding-box;
}

.navbar-toggle .icon-bar {
    background: none repeat scroll 0 0 white
}

.nav > li {
    float: left
}

.nav > li > a:hover, .nav > li > a:focus {
    background: none;
}

.navbar-nav {
    margin: 0 0 0 10px
}

.navbar-nav > li > .dropdown-menu {
    border-radius: 8px;
    background-clip: padding-box;
    min-width: 223px;
}

.dropdown-menu > li > a {
    color: #707070;
    font-size: 12px;
    line-height: 1.7;
    padding-left: 35px;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-color: #f6f6f6;
    color: #707070;
}

.dropdown-menu > li > a > i {
    position: absolute;
    margin-left: -18px;
    margin-top: 4px;
}

.nav-pills > li {
    float: none
}

.nav-pills > li > a {
    border-radius: 0;
    background-clip: padding-box;
}

.navbar > .container .navbar-brand {
    background-color: transparent;
    width: 221px;
    color: #fff;
    font-family: 'segoe_ui_regular', sans-serif;
    font-weight: normal;
    margin-left: -18px;
    padding: 10px 15px;
    margin-top: 10px;
}

@media (max-width: 991px) {
    .navbar > .container .navbar-brand {
        background: transparent;
        color: #262626;
    }
}

@media (max-width: 767px) {
    .navbar > .container .navbar-brand {
        padding-top: 12px;
        padding-top: 12.5px;
    }
}

@media (max-width: 767px) {
    #logo {
        width: 150px;
        padding-left: 0;
        font-size: 1em;
        margin-left: 6px;
    }
}

@media (max-width: 400px) {
    #logo {
        width: auto;
        margin-left: 15px;
    }
}

@media (max-width: 767px) {
    #logo img {
        height: 19px
    }
}

@media (max-width: 400px) {
    #logo span {
        display: none
    }
}

#logo.navbar-brand > img {
    margin: 0 auto;
    padding-right: 4px;
    height: 30px;
}

@media (max-width: 767px) {
    #logo.navbar-brand > img {
        height: 25px
    }
}

@media (min-width: 992px) {
    #logo.navbar-brand > img.normal-logo.logo-white {
        display: block
    }
}

@media (max-width: 991px) {
    #logo.navbar-brand > img.normal-logo.logo-white {
        display: none
    }
}

@media (min-width: 992px) {
    #logo.navbar-brand > img.normal-logo.logo-black {
        display: none
    }
}

@media (max-width: 991px) {
    #logo.navbar-brand > img.normal-logo.logo-black {
        display: block
    }
}

#header-navbar {
    background-color: #004e95;
    border: 0 none;
    border-radius: 0;
    background-clip: padding-box;
    margin: 0;
    min-height: 74px;
    color: #fff;
    border-bottom: 8px solid #faa519;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
}

#header-navbar .nav > li > a { /*height: 50px;*/
}

#header-navbar .nav > li > button > span.count,
#header-navbar .nav > li > a > span.count {
    background: none repeat scroll 0 0 #e64f3f;
    border-radius: 50%;
    background-clip: padding-box;
    color: #fff;
    display: block;
    font-size: 10px;
    font-weight: bold;
    height: 18px;
    line-height: 16px;
    position: absolute;
    right: -2px;
    text-align: center;
    top: 5px;
    width: 18px;
}

#header-navbar .profile-dropdown > a {
    padding-top: 13px;
    /*padding-bottom: 7px;
    line-height: 35px;*/
}

#header-navbar .profile-dropdown > a > img {
    border-radius: 50%;
    background-clip: padding-box;
    float: left;
    height: 42px;
    margin-right: 5px;
    width: 42px;
    border: 0;
}

#header-navbar .profile-dropdown > a > div.profile-picture-parent {
    float: left;
}

#header-navbar .profile-dropdown > a > div.profile-picture-parent > div.form-group {
    margin-bottom: 0;
}

#header-navbar .profile-dropdown > a > div.profile-picture-parent img {
    border-radius: 50%;
    background-clip: padding-box;
    height: 42px;
    margin-right: 5px;
    width: 42px;
    border: 0;
}

#header-navbar .profile-dropdown > a > span.profile-dropdown-details {
    float: left;
    /*max-width: 172px;*/

}

#header-navbar .profile-dropdown > a > span.profile-dropdown-details > span.profile-dropdown-name {
    float: left;
    width: 156px;
    font-weight: bold;
    line-height: 16px;
}

#header-navbar .profile-dropdown > a > span.profile-dropdown-details > b {
    margin-left: 0;
    margin-top: -5px;
}

#header-navbar .profile-dropdown .profile-dropdown-menu {
    margin-left: 13px;
    margin-top: 6px;
}

/*#header-navbar .profile-dropdown>a>span {
    float: left;
    display: block;
    margin-right: 3px;
    font-size: em;
}*/
#header-nav .form-control {
    border-radius: 0;
    background-clip: padding-box;
    border: 0;
}

#header-navbar .navbar-left .navbar-nav {
    margin-left: 0
}

#header-navbar .nav > li > button,
#header-navbar .nav > li > a {
    font-family: 'segoe_ui_regular', sans-serif;
    font-size: 12px;
    font-weight: normal;
    /*padding-left: 18px;
    padding-right: 18px;*/
    color: #fff;
    border: none;
    border-radius: 0;
    background-clip: padding-box;
    cursor: pointer;

    /*border: 1px solid #ff0000;*/
}

#sidebar-nav .nav > li > a > i {
    font-size: 16px;
    margin-top: 5px;
}

#sidebar-nav .nav > li > a:focus,
#sidebar-nav .nav .open > a,
#sidebar-nav .nav .open > a:focus { /*background: inherit */
}

#sidebar-nav .nav > li > a:hover,
#sidebar-nav .nav .selected > a,
#sidebar-nav .nav .open > a:hover {
    /*
    background-color: #004e95;
    background-color: #faa519;
    color: #fff;
    */
    outline: none;
}

#sidebar-nav .nav > li > a:hover {
    background-color: #c3c3c3;
}

#header-navbar .nav > li > button:hover,
#header-navbar .nav > li > button:focus,
#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus,
.navbar-toggle:hover,
.navbar-toggle:focus,
.mobile-search.active > .btn {
    background: #004e95;
    color: #fff;
}

#header-navbar .nav > li > button:hover,
#header-navbar .nav > li > button:focus,
#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus {
    background-color: #004e95
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
#sidebar-nav .nav-pills > li.active > a,
#sidebar-nav .nav-pills > li.active > a:hover,
#sidebar-nav .nav-pills > li.active > a:focus,
.nav-pills > li.open > a,
.nav-pills > li.open > a:hover,
.nav-pills > li.open > a:focus,
#sidebar-nav .nav-pills > li.open > a,
#sidebar-nav .nav-pills > li.open > a:hover,
#sidebar-nav .nav-pills > li.open > a:focus,
.nav-small #primary-nav-col #sidebar-nav .nav-pills > li.open > a {
    background-color: #004e95;
    color: #fff;
}

#header-navbar .nav > li .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}

#header-navbar .nav a:hover .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}

#header-navbar .nav > li > button.navbar-links,
#header-navbar .nav > li > a.navbar-links {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    background-clip: padding-box;
    color: #fff;
    display: block;
    line-height: 14px;
    text-align: center;
    top: 12px;
    margin-right: 20px;
    padding-top: 11px;
}

#header-navbar .nav > li > button.navbar-links > i,
#header-navbar .nav > li > a.navbar-links > i {
    margin-left: -2px;
    margin-top: 0;
    font-size: 18px;
    /* font-weight: normal; */
}

/*
#header-navbar .nav>li>a.nav-link-trophy>i{
    font-size: 22px;
    font-weight: normal;
    margin-left: -4px;
}
#header-navbar .nav>li>a.nav-link-help>i{
    font-size: 20px;
    font-weight: normal;
    margin-left: -5px;
}
#header-navbar .nav>li>a.nav-link-guide>i{
    font-size: 20px;
    font-weight: normal;
    margin-left: -4px;
}*/

#header-navbar .nav > li > a.notifications-btn,
#header-navbar .nav > li > a:hover.notifications-btn,
#header-navbar .nav > li > a:focus.notifications-btn {
    /*changed by dhilshad*/
    /*background: #3989d1;*/
}

#header-navbar .nav > li > a.nav-btn,
#header-navbar .nav > li > a:hover.nav-btn,
#header-navbar .nav > li > a:focus.nav-btn {
    /*changed by dhilshad*/
    /*background: #004686;*/
}

.drowdown-search {
    background: #FFFFFF;
    display: block;
    left: 168px;
    padding: 4px 0;
    position: absolute;
    top: 0;
    transition: left 0.25s ease-out 0s, right 0.25s ease-out 0s;
    width: 0;
    z-index: 1;
    overflow: hidden;
}

@media (max-width: 991px) {
    .drowdown-search {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        display: block;
        left: 0;
        padding: 10px 0;
        top: 50px;
        width: 100%;
        display: none;
        transition: none;
    }
}

.drowdown-search form .form-group {
    position: relative;
    margin: 0 15px;
}

.drowdown-search form .form-control {
    background: none repeat scroll 0 0 #FFFFFF;
    box-shadow: none !important;
    color: #929292;
    font-size: 1em;
    font-weight: 400;
    height: 42px;
    line-height: 42px;
    padding-left: 5px;
    padding-right: 26px;
}

@media (max-width: 991px) {
    .drowdown-search form .form-control {
        font-size: 1.6em;
        font-weight: 600;
    }
}

.drowdown-search form .nav-search-icon {
    color: #929292;
    font-size: 1.2em;
    position: absolute;
    right: 5px;
    top: 13px;
    cursor: pointer;
}

@media (max-width: 991px) {
    .drowdown-search form .nav-search-icon {
        font-size: 1.8em;
        right: 15px;
        top: 8px;
    }
}

.drowdown-search form .form-control::-webkit-input-placeholder {
    color: #929292
}

.drowdown-search form .form-control:-moz-placeholder {
    color: #929292
}

.drowdown-search form .form-control::-moz-placeholder {
    color: #929292
}

.drowdown-search form .form-control:-ms-input-placeholder {
    color: #929292
}

.mobile-search {
    overflow: hidden
}

@media (max-width: 991px) {
    .mobile-search {
        position: inherit !important;
        overflow: visible;
    }
}

.mobile-search > a.btn {
    float: right
}

.mobile-search.active {
    width: 220px
}

@media (max-width: 991px) {
    .mobile-search.active {
        width: auto
    }
}

.mobile-search.active .drowdown-search {
    width: 220px;
    left: 0;
}

@media (max-width: 991px) {
    .mobile-search.active .drowdown-search {
        display: block;
        width: 100%;
        left: 0;
    }
}

.navbar-nav > li > .dropdown-menu.notifications-list {
    min-width: 310px;
    padding-bottom: 0;
    padding-top: 0;
    color: #707070;
    margin-top: 18px;
}

.tooltip {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
}

.notifications-list .item-header {
    font-size: 12px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
}

.notifications-list .item {
    border-top: 1px solid #f6f6f6;
    line-height: 1.4;
    padding-bottom: 0;
    padding-top: 0;
    clear: both;
}

.notifications-list .item > a > i {
    color: #f4786e
}

.notifications-list .item a {
    clear: both;
    white-space: normal;
    padding-bottom: 8px;
    padding-top: 8px;
}

.notifications-list .item a .time {
    color: #004e95;
    margin-left: 10px;
    position: absolute;
    right: 13px;
    white-space: normal !important;
}

.profile-dropdown-menu.dropdown-menu {
    padding: 5px 0 0 0;
}

.notifications-list .item a .time i {
    margin-right: 3px
}

.notifications-list .item-footer,
.profile-dropdown-menu .item-footer {
    background: #004e95;
    padding-bottom: 0;
    padding-top: 0;
    border-radius: 0 0 8px 8px;
    background-clip: padding-box;
}

.notifications-list .item-footer a,
.profile-dropdown-menu .item-footer a {
    font-family: 'segoe_ui_bold', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    padding: 8px 20px;
    text-align: center;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    color: #fff;
}

.notifications-list .item-footer a:hover,
.notifications-list .item-footer a:focus,
.profile-dropdown-menu .item-footer a:hover,
.profile-dropdown-menu .item-footer a:focus {
    background-color: #0288d1;
    color: #fff;
    border-radius: 0 0 8px 8px;
}

.notifications-list .pointer,
.profile-dropdown-menu .pointer {
    height: 12px;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 15px;
    top: -12px;
    width: 12px;
    /*display: none!important;*/
}

.notifications-list .pointer .pointer-inner,
.profile-dropdown-menu .pointer .pointer-inner {
    position: relative
}

.notifications-list .pointer .arrow,
.profile-dropdown-menu .pointer .arrow {
    border-color: transparent transparent #FFFFFF;
    border-style: solid;
    border-width: 6px;
    cursor: pointer;
    left: auto;
    position: absolute;
    right: 0;
    top: 1px;
    z-index: 1002;
}

.notifications-list .pointer .arrow-border,
.profile-dropdown-menu .pointer .arrow-border {
    border-color: transparent transparent rgba(0, 0, 0, 0.15);
    border-style: solid;
    border-width: 7px;
    cursor: pointer;
    left: -1px;
    position: absolute;
    top: -2px;
    z-index: 1001;
}

.messages-list {
    margin-left: -30px
}

.dropdown.language.hidden-xs ul.dropdown-menu {
    min-width: 100px;
    max-width: 200 pz;
    margin-left: -20px;
}

.messages-list .item.first-item {
    border-top: 0 !important
}

.messages-list .item > a {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}

.messages-list .item > a > img {
    position: absolute;
    margin-top: 10px;
}

.messages-list .item > a > .content {
    display: block;
    padding-left: 50px;
    padding-top: 5px;
}

.messages-list .item > a > .content .content-headline {
    color: #605F5F;
    display: block;
    font-weight: 600;
}

.messages-list .item > a > .content .content-text {
    display: block;
    line-height: 1.4;
}

@media (max-width: 400px) {
    #header-navbar .container {
        padding: 0
    }
}

@media (max-width: 400px) {
    .profile-dropdown .dropdown-toggle {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

@media (max-width: 400px) {
    #header-nav .nav {
        margin-left: 0
    }
}

#col-left {
    position: relative;
    color: #003940;
    height: 100%;
}

#col-left a {
    color: #e1e1e1
}

#col-left a:hover,
#col-left .nav-active a.nav-link,
#col-left a.active {
    color: #fff
}

#col-left * {
    outline: none
}

#primary-nav-col {
  z-index: 100;
  position: absolute;
  background-color: #EBEBEB;
  display: block;
  padding: 0 0 0 8px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 74px;
  /*overflow: auto;*/
}

@media (max-width: 991px) {
    #primary-nav-col {
        position: absolute;
        width: auto;
        min-height: auto;
    }
}

#secondary-nav-col {
    padding: 0;
    background-color: #fff;
}

#sidebar-nav {
    max-height: 100%;
    padding-left: 0;
    padding-right: 0;
}

#sidebar-nav .nav > li {
    margin: 0
}

#sidebar-nav .nav > li > a {

    color: #666;
    min-height: 52px;
    line-height: 28px;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
    overflow: hidden;
    padding: 14px 15px 8px 11px;
    border-left: 0 solid transparent;
}

#sidebar-nav .nav > li > a:hover {
    border-left-color: #004e95
}

#sidebar-nav .nav > li > a > i {
    position: absolute;

}

#sidebar-nav .nav > li > a > span {
    margin-left: 35px;
    font-family: 'segoe_ui_bold', sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
}

#sidebar-nav .nav > li > a > span.label {
    font-size: 16px;
    margin: 5px 0 0 0;
    padding: 4px 0.6em;
}

#sidebar-nav .nav > li > a > span.label.label-circle {
    margin-right: 5px
}

#sidebar-nav .nav > li.open > a {
    border-bottom-color: #252525;
    outline: none;
    text-decoration: none;
}

#sidebar-nav .nav li a.dropdown-toggle > .drop-icon {
    color: #868b98;
    font-size: 12px;
    margin-top: -6px;
    position: absolute;
    right: 25px;
    top: 50%;
    transition: transform 0.2s ease-in-out 0.1s;
}

#sidebar-nav .nav li.open > a.dropdown-toggle > .drop-icon,
#sidebar-nav .nav li.active > a.dropdown-toggle > .drop-icon {
    color: #fff;
    transform: rotate(90deg);
}

.navbar-nav .open .dropdown-menu {
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    left: 0;
    position: absolute;
}

@media (max-width: 991px) {
    .navbar-nav .open .dropdown-menu {
        left: auto;
        right: 20px;
    }

    .profile-dropdown-menu .pointer {
        left: auto;
        right: 20px;
    }
}

@media (min-width: 992px) {
    .nav-small #primary-nav-col {
        width: 48px
    }

    .nav-small #content-wrapper { /*margin-left: 328px*/
        margin-left: 48px;
    }

    .nav-small #nav-col #sidebar-nav .nav > li > a {
        padding-left: 15px !important;
        padding-right: 15px;
        text-align: center;
    }

    .nav-small #primary-nav-col #sidebar-nav .nav > li > a > i {
        position: relative;
        font-size: 24px;
    }

    .nav-small #primary-nav-col #sidebar-nav .nav > li > a > span {
        display: none
    }

    .nav-small #primary-nav-col #sidebar-nav .nav li > a.dropdown-toggle > .drop-icon {
        display: none
    }

    .nav-small #secondary-nav-col {
        left: 48px
    }

}

@media (max-width: 991px) {
    .navbar-toggle {
        display: block
    }

    #sidebar-nav.navbar-collapse {
        max-height: 336px
    }
}

#footer-bar {
    background: #f1f1f1;
    /*border-bottom:8px solid #faa519;*/
    bottom: 0;
    left: 8px;
    right: 8px;

    font-size: 0.8em;
    height: 37px;
    line-height: 36px;
    position: fixed;

    padding-left: 25px;
    padding-right: 25px;
    
    z-index: 9999999;
}
#footer-bar.pg {
	left: 8px;
	right: 8px;
}

#footer-bar.pg .m-l-210 {
	margin-left: 0;
}

@media (max-width: 991px) {
    #footer-bar {
        left: 8px;
    }
}

#footer-copyright {
    margin: 0;
}

.fixed-footer #footer-bar {
    bottom: 0;
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 999;
}

.fixed-footer #content-wrapper {
    padding-bottom: 36px
}

@media (max-width: 991px) {
    .fixed-header #primary-nav-col {
        position: fixed;
        width: 100%;
    }
}

.emerald-bg {
    background-color: #004e95 !important
}

.red-bg {
    background-color: #f4786e !important
}

.yellow-bg {
    background-color: #ffc107 !important
}

.blue-bg {
    background-color: #7FC8BA !important
}

.purple-bg {
    background-color: #9972B5 !important
}

.gray-bg {
    background-color: #90a4ae !important
}

.white-bg {
    background-color: #ffffff !important
}

.emerald {
    color: #004e95 !important
}

.red {
    color: #f4786e !important
}

.yellow {
    color: #ffc107 !important
}

.green {
    color: #5db12a !important
}

.purple {
    color: #9972B5 !important
}

.gray {
    color: #90a4ae !important
}

.status-green {
    color: #005826
}

.status-red {
    color: #9e0b0f
}

.txt-white {
    color: #fff !important
}

.txt-white-hover:hover {
    color: #fff !important
}

.mrg-b-xs {
    margin-bottom: 3px
}

.mrg-b-sm {
    margin-bottom: 6px
}

.mrg-b-md {
    margin-bottom: 10px
}

.mrg-b-lg {
    margin-bottom: 20px
}

.mrg-t-xs {
    margin-top: 3px
}

.mrg-t-sm {
    margin-top: 6px
}

.mrg-t-md {
    margin-top: 10px
}

.mrg-t-lg {
    margin-top: 20px
}

.mrg-r-xs {
    margin-right: 3px
}

.mrg-r-sm {
    margin-right: 6px
}

.mrg-r-md {
    margin-right: 10px
}

.mrg-r-lg {
    margin-right: 20px
}

.mrg-l-xs {
    margin-left: 3px
}

.mrg-l-sm {
    margin-left: 6px
}

.mrg-l-md {
    margin-left: 10px
}

.mrg-l-lg {
    margin-left: 20px
}

.btn {
    border: none;
    padding: 6px 12px;
    border-bottom: 2px solid;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    outline: none;
    border-radius: 3px;
    background-clip: padding-box;
}

.hd-btn {
    border: none;
    padding: 6px 12px;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    outline: none;
    border-radius: 3px;
    background-clip: padding-box;
    width: 100%;
    color: #fff;
    font-size: 12px;
    display: inline-block;
    text-align: center;
}

.hd-icon-btn {
    padding: 4px 8px;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    outline: none;
    border-radius: 0px;
    background-clip: padding-box;
    width: 100%;
    text-transform: capitalize;
    border: 2px solid #faa519;
    background-color: #fff;
    color: #666;
    line-height: 13px;

}

.hd-icon-btn > i {
    font-size: 21px;
    vertical-align: middle;
}

.hd-icon-btn > span {
    margin-left: 6px;
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
}

button:hover.hd-icon-btn,
button:focus.hd-icon-btn {
    color: #fff;
    background-color: #faa519;
}

.hd-round-btn {
    font-size: 16px;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    outline: none;
    border-radius: 50%;
    background-clip: padding-box;
    width: 32px;
    height: 32px;
    text-transform: capitalize;
    border: 0;

}

.hd-round-green {
    background-color: #6fb743;
    color: #fff;
}

.hd-round-green:hover,
.hd-round-green:focus {
    background-color: #fff;
    color: #6fb743;
    border: 1px solid #6fb743;
}

.make-photo {
    background-color: #ededed;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: block;
    border: 0;
    /* background: url('../../img/samples/ryan-300.jpg'); */
}

a.make-photo > img {
    width: 40px;
    height: 40px;
}

.btn-frm-right {
    background-color: #fff;
    border: 1px solid #6fb743;
    color: #6fb743;
    max-width: 120px;
    float: left;
}

.btn-frm-right:hover,
.btn-frm-right:focus,
.btn-frm-right:active,
.btn-frm-right.active {
    background-color: #6fb743;
    color: #fff;
    border: 1px solid #6fb743;
}

.btn-submit {
    background-color: #6fb743;
    border: 1px solid #6fb743;
}

.btn-submit:hover,
.btn-submit:focus,
.btn-submit:active,
.btn-submit.active {
    background-color: #fff;
    color: #6fb743;
    border: 1px solid #6fb743;
}

.btn-save {
    background-color: #faa519;
    border: 1px solid #faa519;
}

.btn-save:hover,
.btn-save:focus,
.btn-save:active,
.btn-save.active {
    background-color: #fff;
    color: #faa519;
    border: 1px solid #faa519;
}

.btn-table-action {
    background-color: #fff;
    color: #faa519;
    border: 1px solid #faa519;
}

.btn-table-action:hover,
.btn-table-action:focus,
.btn-table-action:active,
.btn-table-action.active {
    background-color: #faa519;
    color: #fff;
    border: 1px solid #faa519;
}

.btn-reset {
    background-color: #004e95;;
    border: 1px solid #004e95;
}

.btn-reset:hover,
.btn-reset:focus,
.btn-reset:active,
.btn-reset.active {
    background-color: #fff;
    color: #004e95;
    border: 1px solid #004e95;
}
.btn-yellow {
    background-color: #faa519;;
    border: 1px solid #faa519;
}

.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active,
.btn-yellow.active {
    background-color: #fff;
    color: #faa519;
    border: 1px solid #faa519;
}
.btn-default,
.wizard-cancel,
.wizard-back {
    background-color: #90a4ae;
    border-color: #607d8b;
    color: #fff;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.wizard-cancel:hover,
.wizard-cancel:focus,
.wizard-cancel:active,
.wizard-cancel.active,
.wizard-back:hover,
.wizard-back:focus,
.wizard-back:active,
.wizard-back.active {
    background-color: #949e9f;
    border-color: #748182;
    color: #fff;
}

.btn-default .caret {
    border-top-color: #FFFFFF
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    background-color: #607d8b;
    border-color: #5c6667;
}

.btn-info {
    background-color: #01b9fe;
    border-color: #0298d1;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
    background-color: #0298d1;
    border-color: #0178a5;
}

.btn-link {
    border: none
}

.btn-primary {
    background-color: #7FC8BA;
    border-color: #7FC8BA;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-color: #004e95;
    border-color: #004e95;
}

.btn-success {
    background-color: #004e95;
    border-color: #004e95;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    background-color: #faa519;
    border-color: #faa519;
}

.btn-danger {
    background-color: #f4786e;
    border-color: #dd191d;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    background-color: #dd191d
}

.btn-warning {
    background-color: #ffc107;
    border-color: #ffa000;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
    background-color: #e0b50a;
    border-color: #bd9804;
}

.btn-facebook {
    background-color: #3b5998 !important;
    border-color: #2f477b !important;
}

.btn-facebook:hover {
    background-color: #2f477b !important;
    border-color: #263963 !important;
}

.btn-twitter {
    background-color: #00aced !important;
    border-color: #0098d1 !important;
}

.btn-twitter:hover {
    background-color: #0098d1 !important;
    border-color: #0283b4 !important;
}

.icon-box {
    margin-top: 5px
}

.icon-box .btn {
    border: 2px solid #e1e1e1;
    margin-left: 3px;
    margin-right: 0;
}

.icon-box .btn:hover {
    background-color: #eee;
    color: #004e95;
}

.label {
    border-radius: 3px;
    background-clip: padding-box;
    font-size: 12px;
    font-weight: 600;
}

.label-default,
.fc-event.label-default {
    background-color: #90a4ae
}

.label-primary,
.fc-event.label-primary {
    background-color: #004e95
}

.label-success,
.fc-event.label-success {
    background-color: #7FC8BA
}

.label-info,
.fc-event.label-info {
    background-color: #9972B5
}

.label-warning,
.fc-event.label-warning {
    background-color: #ffc107
}

.label-danger,
.fc-event.label-danger {
    background-color: #f4786e
}

.label.label-large {
    font-size: 1em;
    padding: 0.4em 0.8em 0.5em;
}

.label.label-circle {
    border-radius: 50%;
    background-clip: padding-box;
    padding: 4px !important;
}

#calendar .fc-event,
.external-event {
    border-left: 0 solid #004e95;
    border-radius: 2px;
    background-clip: padding-box;
    font-weight: 700;
}

#calendar .fc-event.label-success,
.external-event.label-success {
    border-left: 0 solid #689f38
}

#calendar .fc-event.label-warning,
.external-event.label-warning {
    border-left: 0 solid #ffa000
}

calendar .fc-event.label-danger,
.external-event.label-danger {
    border-left: 0 solid #dd191d;
    color: #fff;
}

#calendar .fc-event.label-info,
.external-event.label-info {
    border-left: 0 solid #7b1fa2
}

#calendar .fc-event.label-default,
.external-event.label-default {
    border-left: 0 solid #607d8b
}

@media only screen and (max-width: 440px) {
    #calendar .fc-header-right,
    #calendar .fc-button-today,
    #calendar .fc-header-space {
        display: none
    }
}

.alert {
    border-radius: 0;
    background-clip: padding-box;
    border-image: none;
    border-style: none;
    border-width: 0;
}

.alert-success {
    border-color: #689f38;
    color: #689f38;
}

.alert-warning {
    border-color: #ffa000;
    color: #ffa000;
}

.alert-danger {
    border-color: #dd191d;
    color: #dd191d;
}

.alert-info {
    border-color: #004e95;
    color: #004e95;
}

.alert-info .alert-link {
    color: #004e95
}

.pagination-display {
    margin: 26px 20px 20px 0;
    color: #004e95;
}

.pagination {
    margin: 20px 0
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    background-clip: padding-box;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > li > a:active,
.pagination > li > span:active {
    color: #666666;
    border-width: 1px;
    margin-left: -2px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #004e95;
    border-color: #004e95;
}

.pager li > a,
.pager li > span {
    border-width: 2px
}

.list-group-item {
    border-width: 2px;
    margin-bottom: -2px;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
    background-color: #004e95;
    border-color: #004e95;
}

.badge.badge-danger {
    background-color: #f4786e
}

.badge.badge-success {
    background-color: #7FC8BA
}

.badge.badge-warning {
    background-color: #ffc107
}

.badge.badge-primary {
    background-color: #004e95
}

.badge.badge-info {
    background-color: #9972B5
}

/*.nav-tabs {
    background: #e7ebee;
    border-color: transparent;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
}
.nav-tabs>li>a {
    border-radius: 0;
    background-clip: padding-box;
    font-size: 1.125em;
    font-weight: 300;
    outline: none;
    color: #555;
    margin-right: 3px;
}
.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
    border-color: transparent;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    color: #555;
    border-bottom: 0;
    background: rgba(219,221,223,0.46);
}
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    border-left: 0;
    border-top: 2px solid #004e95;
    border-right: 0;
}*/
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    border-color: #e7ebee
}

.nav .caret {
    border-bottom-color: #004e95;
    border-top-color: #004e95;
}

.main-box .tabs-header {
    min-height: 50px;
    padding: 10px 20px;
}

.main-box .tab-content-body {
    padding: 0 20px 20px 20px;
    margin-bottom: 0;
}

.tabs-wrapper.tabs-no-header .tab-content {
    padding: 0 20px 20px
}

.panel {
    background-color: #fff;
}

.panel-group .panel {
    border-radius: 0;
    background-clip: padding-box;
    border: 0 none;

}

.panel-default > .panel-heading {
    background-color: #fafafa;
    border-bottom: 1px solid #f0eeee;
    border-top: 0;
    border-radius: 0;
    background-clip: padding-box;
}

div:first-child.panel-default > .panel-heading {
    /*border-top: 1px solid #f0eeee;*/
}

.panel-collapse {
    border-bottom: 1px solid #f0eeee;
    border-top: 0;
}

.accordion .panel-title > a {
    display: block;
    position: relative;
    outline: none;
    text-decoration: none;
    font-family: 'segoe_ui_bold', sans-serif;
    /*updated by dhilshad*/
    font-size: 12px;
    font-weight: normal;
    color: #9e9e9e;
    padding: 12px 0 13px 12px;

}

.accordion .panel-title > a:hover {
    text-decoration: none;
    /*color: #fff;*/
    /*background-color: #2c6ea9;*/

    color: #004e95;
    background-color: #f0f0f0;
}

.accordion .panel-title > a:after {
    /*content: "\f068";
    font-family: FontAwesome;*/
    content: "\e612";
    font-family: 'fednet-fonts';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin-top: -9px;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    padding-right: 12px;
}

.accordion .panel-title > a.accordion-toggle.collapsed:after {
    content: "\e614";
    font-family: 'fednet-fonts'; /*content: "\f067"*/
}

.accordion .panel-title > a.accordion-toggle > i {
    width: 24px;
    text-align: center;
}

.panel-default .panel-collapse .panel-sub-heading {
    background-color: #fff;
    padding: 2px 7px 2px 12px;
}

.panel-default .panel-collapse .panel-sub-heading.active {
    color: #004e95;
}

.panel-default .panel-collapse .panel-sub-heading.active h4.panel-sub-title > a {
    font-weight: bold;
    color: #004e95;
}

.accordion .panel-sub-title > a {
    display: block;
    position: relative;
    outline: none;
    text-decoration: none;
    font-family: 'segoe_ui_regular', sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #666;
    padding-right: 15px;
}

.accordion .panel-sub-title > a.active {
    color: #004e95;
}

.accordion .panel-sub-title > a:hover {
    text-decoration: none;
    color: #999898;
}

.progress {
    background-color: #eee;
    border-radius: 0;
    background-clip: padding-box;
    height: 8px;
    margin-bottom: 12px;
    box-shadow: none;
}

.progress-bar {
    background-color: #004e95;
    box-shadow: none;
}

.progress-bar-success {
    background-color: #7FC8BA
}

.progress-bar-info {
    background-color: #9972B5
}

.progress-bar-warning {
    background-color: #ffc107
}

.progress-bar-danger {
    background-color: #f4786e
}

.progress.progress-2x {
    height: 12px
}

.progress.progress-3x {
    height: 16px
}

.progress.progress-4x {
    height: 20px
}

.popover-content {
    font-size: 0.875em
}

.modal-header {
    border-width: 2px
}

.modal-content {
    border-radius: 0;
    background-clip: padding-box;
}

.modal-footer {
    border-width: 2px
}

blockquote,
blockquote.pull-right {
    border-color: #004e95;
    padding: 10px 20px 10px 40px;
}

blockquote.pull-right {
    padding-left: 20px;
    padding-right: 40px;
}

blockquote p {
    font-style: italic
}

blockquote:before {
    content: "\f10d";
    color: #b0bec5;
    display: inline;
    font-family: FontAwesome;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    height: auto;
    line-height: normal;
    margin-left: -28px;
    margin-top: 0;
    position: absolute;
    width: auto;
}

blockquote.pull-right:before {
    margin-left: 0;
    right: 50px;
}

.breadcrumb {
    background: none;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.breadcrumb > li {
    text-transform: capitalize;
    color: #616161;
}

.breadcrumb > li i {
    color: #004e95;
    margin-left: 0px;
    font-size: 17px;
}

.breadcrumb > li span {
    font-family: 'segoe_ui_regular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #666666;
    margin-left: 7px;
    line-height: 14px;
}

.breadcrumb > li a {
    font-family: 'segoe_ui_regular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #004e95;
    text-decoration: none;
    margin-left: 7px;
    line-height: 14px;
}

.breadcrumb > li + li:before {
    color: #565656;
    font-family: FontAwesome;
    content: "\f105";
    margin-left: 5px;
}

.breadcrumb > .active {
    text-transform: none;
    color: #666666;
}

.breadcrumb > li:first-child {
    margin-left: 0;
}

.breadcrumb > li:first-child a {
    font-family: 'segoe_ui_bold', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: normal;
    margin-left: 5px;
    padding-top: 1px;
    color: #004e95;
    text-transform: uppercase;
}

.table-box {
    background: #FFFFFF;
    margin-bottom: 16px;
    border-radius: 0px;
    background-clip: padding-box;
    border: 0px solid #f0eeee;
}

@media (max-width: 767px) {
    .table-box {
        margin-bottom: 10px
    }
}

.table-box h2 {
    font-size: 1.3em;
    line-height: 29px;
    margin: 0;
    padding: 0;
}

@media (max-width: 419px) {
    .table-box h2 {
        margin-bottom: 5px
    }
}

.table-box.no-header {
    padding-top: 20px
}

.table-box .table-box-header {
    min-height: 50px;
    padding: 10px 20px;
}

.table-box .table-box-header.with-border {
    border-bottom: 1px solid #ecf0f1
}

.table-box .table-box-body {
    padding: 0
}

.table-hover tr:hover td {
    background-color: #6fb743;
    color: #fff;
}

.table-hover tr:hover td a {
    color: #fff;
}

.table-sideborder {
    border-top: 1px solid #d0d7d8;
    border-bottom: 1px solid #d0d7d8;
    border-collapse: separate;
}

.table-sideborder tr > th {
    border-right: 1px solid #d0d7d8;
    text-align: center;
}

.table-sideborder tr > th:last-child {
    /*border-right: 0;*/
}

.table-sideborder tr > td {
    border-right: 1px solid #d0d7d8;
}

.table-sideborder tr > th:first-child,
.table-sideborder tr > td:first-child {
    border-left: 1px solid #d0d7d8;
}

.table-sideborder tr > td:last-child {
    /*border-right: 0;*/
}

.table th {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 12px;
    background-color: #eaf3fb;
    line-height: 25px;
    padding: 8px 0;
}

.table thead > tr > th > span {
    display: block;
}

.table thead > tr > th > a > span {
    color: #eaf3fb
}

.table th span a {
    color: #666666
}

.table tbody > tr > td {
    font-size: 12px;
    vertical-align: middle;
    border-top: 0;
    padding: 8px 8px;
    color: #666;
}

.table tr > td > .radio {
    width: 70px;
}

/*.table tbody>tr>td:first-child {
    font-size: 1.125em;
    font-weight: 300;
}*/
.table tbody > tr td .call-type {
    display: block;
    font-size: 0.75em;
    text-align: center;
}

.table tbody > tr td .first-line {
    line-height: 1.5;
    font-weight: 400;
    font-size: 1.125em;
}

.table tbody > tr td .first-line span {
    font-size: 0.875em;
    color: #969696;
    font-weight: 300;
}

.table tbody > tr td .second-line {
    font-size: 0.875em;
    line-height: 1.2;
}

.table tbody > tr.table-line-fb > td {
    background-color: #9daccb;
    color: #262525;
}

.table tbody > tr.table-line-twitter > td {
    background-color: #9fccff;
    color: #262525;
}

.table tbody > tr.table-line-plus > td {
    background-color: #eea59c;
    color: #262525;
}

.table a.table-link {
    margin: 0 5px;
    font-size: 1.125em;
}

.table a.table-link:hover {
    text-decoration: none;
    color: #004e95;
}

.table a.table-link.danger {
    color: #f4786e
}

.table a.table-link.danger:hover {
    color: #dd191d
}

.table.table-condensed tbody > tr > td {
    padding: 8px 5px
}

.dataTable thead th:hover {
    color: #004e95
}

.table thead > tr > th > a span:after,
.dataTable .sorting:after,
.dataTable .sorting_asc:after,
.dataTable .sorting_desc:after {
    content: "\f0dc";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin-left: 5px;
    font-size: 0.75em;
}

.table thead > tr > th > a:hover {
    text-decoration: none
}

.table thead > tr > th > a.asc span:after,
.dataTable .sorting_desc:after {
    content: "\f0dd"
}

.table thead > tr > th > a.desc span:after,
.dataTable .sorting_asc:after {
    content: "\f0de"
}

.table thead > tr > th > a:hover span {
    text-decoration: none;
    color: #004e95;
    border-color: #004e95;
}

.table.table-hover tbody > tr > td {
    transition: background-color 0.15s ease-in-out 0s
}

.table-products tbody > tr > td {
    background: none;
    border: none;
    border-bottom: 1px solid #ebebeb;
    transition: background-color 0.15s ease-in-out 0s;
    position: relative;
}

.table-products tbody > tr:hover > td {
    text-decoration: none;
    background-color: #f6f6f6;
}

.table-products .name {
    display: block;
    font-weight: 600;
    padding-bottom: 7px;
}

.table-products .price {
    display: block;
    text-decoration: none;
    width: 50%;
    float: left;
    font-size: 0.875em;
}

.table-products .price > i {
    color: #7FC8BA
}

.table-products .warranty {
    display: block;
    text-decoration: none;
    width: 50%;
    float: left;
    font-size: 0.875em;
}

.table-products .warranty > i {
    color: #ffc107
}

.table-stats .status-social-icon {
    font-size: 1.9em;
    vertical-align: bottom;
}

.table-stats .table-line-fb .status-social-icon {
    color: #556484
}

.table-stats .table-line-twitter .status-social-icon {
    color: #5885b8
}

.table-stats .table-line-plus .status-social-icon {
    color: #a75d54
}

.dataTables_length {
    margin-top: 10px;
    margin-bottom: 15px;
    margin-right: 30px;
    float: left;
}

@media (max-width: 767px) {
    .dataTables_length {
        display: none
    }
}

.dataTables_filter {
    float: right;
    margin-top: 10px;
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .dataTables_filter {
        display: none
    }
}

.dataTables_filter input {
    margin-left: 10px
}

@media (max-width: 767px) {
    .DTTT {
        display: none
    }
}

.DTTT.btn-group {
    float: right;
    margin-left: 10px;
    margin-top: 10px;
}

.DTTT.btn-group a.btn {
    height: 30px;
    line-height: 16px;
}

.user-list tbody td > img {
    position: relative;
    max-width: 50px;
    float: left;
    margin-right: 15px;
    border-radius: 18%;
    background-clip: padding-box;
}

.user-list tbody td .user-link {
    display: block;
    font-size: 1.25em;
    padding-top: 3px;
    margin-left: 60px;
}

.user-list tbody td .user-subhead {
    font-size: 0.875em;
    font-style: italic;
}

.map-stats {
    padding-top: 20px
}

.map-stats .table tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.map-stats .table tbody > tr > td:first-child {
    font-size: 1em;
    font-weight: 400;
}

.map-stats .table tbody > tr > td.status {
    font-size: 1.2em;
    font-weight: 600;
}

.form-group {
    margin-bottom: 16px
}

label {
    float: left;
    font-weight: bold;
    font-size: 12px;
    color: #666;
    text-align: left;
    margin-bottom: 4px;
    display: block;
}

p.form-field-title {
    font-family: 'segoe_ui_semibold', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 15px;
    color: #faa519;
    text-align: left;
    margin: 0 0 12px 0;
    padding: 0 0 0 6px;
}

.form-control {
    border-radius: 3px;
    background-clip: padding-box;
    border-color: #e3e4e8;
    border-width: 2px;
    box-shadow: none;
    font-size: 12px;
    color: #768082;
}

.form-control:focus {
    border-color: rgba(52, 151, 219, 0.6);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(52, 152, 219, 0.6);
}

.form-control-date {
    border-right: 0;
    padding-top: 6px;
    color: #768082;
}

.input-group-addon > i {
    color: #babfc0;
    font-size: 16px;
}

.form-inline-box {
    background: none repeat scroll 0 0 #F6F6F6;
    border: 2px solid #e7ebee;
    padding: 7px;
}

.radio {
    padding-left: 2px;
}

td.radio.clear-after {
	display: table-cell;
}

.radio label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

input[type="radio"] {
    line-height: normal;
    box-sizing: border-box;
}

.radio input[type=radio],
.radio-inline input[type=radio] {
    float: left;
    margin-left: -20px;
}

.radio input[type=radio] {
    display: none
}

.radio label {
    font-weight: bold;
}

.radio label:before {
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #727272;
    border-radius: 50%;
    background-clip: padding-box;
    content: "";
    display: inline-block;
    height: 16px;
    left: 0;
    margin-right: 10px;
    position: absolute;
    width: 16px;
}

.radio label:after {
    background-color: #cdcdcd;
    border-radius: 50%;
    background-clip: padding-box;
    content: "";
    display: inline-block;
    font-size: 12px;
    height: 12px;
    left: 2px;
    position: absolute;
    text-align: center;
    top: 2px;
    width: 12px;
    line-height: 16px;
    transform: scale(0.63);
    transition: transform 0.1s 0s cubic-bezier(0.4, 0.02, 0.3, 1.2);
}

.notopborder.listingcol .left {
    float: left;
}

.notopborder.listingcol .right {
    float: right;
}

.notopborder.listingcol:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.notopborder.listingcol {
    display: inline-block;
}

/* start commented backslash hack \*/
* html .notopborder.listingcol {
    height: 1%;
}

.notopborder.listingcol {
    display: block;
}

.radio input[type=radio]:checked + label:before,
.radio input[type=radio]:hover + label:before {
    border-color: #727272
}

.radio input[type=radio]:checked + label:after {
    background-color: #6fb843;
    color: #6fb843;
}

.radio input[type=radio]:checked + label {
    color: #6fb843;
}

.has-success .form-control {
    border-color: #7FC8BA
}

.has-success .help-block,
.has-success .control-label {
    color: #689f38
}

.has-success .form-control:focus {
    border-color: #689f38;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #689f38;
}

.has-success .input-group-addon {
    color: #7FC8BA;
    background-color: #dff0d8;
    border-color: #7FC8BA;
}

.has-warning .form-control {
    border-color: #ffc107
}

.has-warning .help-block,
.has-warning .control-label {
    color: #ffa000
}

.has-warning .form-control:focus {
    border-color: #ffa000;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffa000;
}

.has-warning .input-group-addon {
    color: #ffc107;
    background-color: #fcf8e3;
    border-color: #ffc107;
}

.has-error .form-control {
    border-color: #f4786e
}

.has-error .help-block,
.has-error .control-label {
    color: #dd191d
}

.has-error .form-control:focus {
    border-color: #dd191d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dd191d;
}

.has-error .input-group-addon {
    color: #f4786e;
    background-color: #f2dede;
    border-color: #f4786e;
}

.input-group > .form-control,
.input-group > .input-group-addon,
.input-group > .input-group-btn > .btn {
    line-height: 1
}

.input-group > .input-group-btn > .btn {
    border: 0;
    height: 34px;
}

.input-group-addon {
    background: transparent;
    border-color: #e7ebee;
    border-width: 2px;
}

.dropdown-menu {
    border-radius: 3px;
    background-clip: padding-box;
}

.help-block {
    font-size: 14px;
    text-align: left;
    color: #333333;
    margin-top: 3px;
    margin-bottom: 0;
}

.help-block > a {
    text-decoration: underline;
    color: #004e95;
    font-weight: bold;
}

.help-block > .strong {
    color: #004e95;
    font-weight: bold;
}

.help-block .green {
    color: #5db12a;
    font-weight: bold;
}

label.label-right {
    padding-top: 7px;
    float: none;
}

label.label-right-btn {
    padding-top: 0px;
    float: none;
}

.required-field {
    color: #faa519;
}

.checkbox-inline > input {
    margin-top: 0
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-color: #004e95
}

.bootstrap-timepicker-widget table td input {
    border: 1px solid #ccc;
    width: 30px !important;
    height: 30px;
}

/*.form-group-select2 label { display: block }*/
.select2-results .select2-result-label {
    font-size: 0.875em
}

.select2-container .select2-choice {
    font-size: 0.875em;
    border-width: 2px !important;
}

.select2-container.select2-container-multi .select2-choices .select2-search-field input {
    font-size: 0.875em;
    font-family: 'Open Sans', sans-serif;
    background-color: #FFFFFF;
    color: #555555 !important;
    display: block;
    font-size: 12px;
    height: 34px;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    vertical-align: middle;
}

.select2-container.select2-container-multi .select2-choices {
    border-color: #ccc;
    border-width: 2px;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border-width: 2px !important
}

.select2-drop.select2-drop-above.select2-drop-active {
    border-width: 2px
}

.select2-container-multi .select2-choices {
    background-image: none !important;
    border-color: #e7ebee !important;
    border-radius: 3px;
    background-clip: padding-box;
}

.select2-container.select2-container-multi .select2-choices .select2-search-choice {
    background: none repeat scroll 0 0 #004e95;
    border: medium none;
    border-radius: 4px;
    background-clip: padding-box;
    box-shadow: none;
    color: #fff;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 20px;
    margin: 5px 0 5px 5px;
}

.select2-container.select2-container-multi .select2-search-choice-close {
    top: 8px
}

.select2-container .select2-choice {
    border-radius: 3px !important;
    background-clip: padding-box;
    background: #fff !important;
    border-color: #e7ebee !important;
    height: 34px !important;
    line-height: 30px !important;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;

    border-color: rgba(52, 151, 219, 0.6) !important;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(52, 152, 219, 0.6);

}

.select2-container .select2-choice .select2-arrow {
    border-radius: 0 !important;
    background-clip: padding-box;
    background: #e3e4e8 !important;
    border-color: #e3e4e8 !important;
    width: 24px !important;
}

.select2-container .select2-choice .select2-arrow b {
    background-position: 3px 4px !important
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border-color: rgba(0, 0, 0, 0) rgba(52, 151, 219, 0.6) rgba(52, 151, 219, 0.6) !important;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
}

.select2-drop.select2-drop-above.select2-drop-active {
    border-top: 2px solid rgba(52, 151, 219, 0.6) !important
}

.select2-drop-active {
    border-color: rgba(52, 151, 219, 0.6) rgba(52, 151, 219, 0.6) !important
}

.select2-container .select2-choice > .select2-chosen {
    font-size: 12px;
    color: #666;
    position: absolute;
    width: 90%;
    overflow: hidden;
}

.tt-query {
    box-shadow: none !important
}

.wysiwyg-editor {
    max-height: 250px;
    height: 250px;
    background-color: #fff;
    border-collapse: separate;
    border: 1px solid #e7ebee;
    border-radius: 3px;
    background-clip: padding-box;
    padding: 10px;
    box-sizing: content-box;
    overflow: scroll;
    outline: none;
}

.btn-toolbar.editor-toolbar {
    margin-bottom: 7px
}

.checkbox-nice {
    position: relative;
    padding-left: 15px;
}

.checkbox-nice input[type=checkbox] {
    visibility: hidden
}

.checkbox-nice label {
    padding-top: 3px
}

.checkbox-nice.checkbox-inline > label {
    margin-left: 16px
}

.checkbox-nice label:before {
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 1px;
    top: 1px;
    background: #ffffff;
    content: "";
    border: 2px solid #e7ebee;
    border-radius: 3px;
    background-clip: padding-box;
}

.checkbox-nice label:after {
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 6px;
    left: 5px;
    border: 2px solid #004e95;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
}

.checkbox-nice label:hover::after {
    opacity: 0.3
}

.checkbox-nice input[type=checkbox]:checked + label:after {
    opacity: 1
}

.onoffswitch {
    position: relative;
    width: 90px;
    box-sizing: content-box;
    margin-right: 20px;
}

.onoffswitch-checkbox {
    display: none
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #999999;
    border-radius: 20px;
    background-clip: padding-box;
}

.onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #004e95;
    color: #FFFFFF;
}

.onoffswitch.onoffswitch-success .onoffswitch-inner:before {
    background-color: #7FC8BA
}

.onoffswitch.onoffswitch-danger .onoffswitch-inner:before {
    background-color: #f4786e
}

.onoffswitch.onoffswitch-warning .onoffswitch-inner:before {
    background-color: #ffc107
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #EEEEEE;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    width: 18px;
    margin: 6px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 20px;
    background-clip: padding-box;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 56px;
    box-sizing: content-box;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px
}

.typeahead,
.tt-query,
.tt-hint {
    width: 400px
}

@media only screen and (max-width: 480px) {
    .typeahead,
    .tt-query,
    .tt-hint {
        width: 100%
    }
}

.twitter-typeahead {
    display: block !important
}

.typeahead {
    background-color: #fff
}

.typeahead:focus {
    border: 2px solid #0097cf
}

.tt-query {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.tt-hint {
    color: #999;
    padding-top: 5px;
    padding-left: 11px;
}

.tt-dropdown-menu {
    width: 422px;
    margin-top: 12px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-clip: padding-box;
}

@media only screen and (max-width: 480px) {
    .tt-dropdown-menu {
        width: 100%
    }
}

.tt-suggestion {
    padding: 3px 20px;
    font-size: 1em;
    line-height: 20px;
}

.tt-suggestion.tt-is-under-cursor {
    color: #fff;
    background-color: #004e95;
}

.tt-suggestion p {
    margin: 0
}

.gist {
    font-size: 12px
}

.example-twitter-oss .tt-suggestion {
    padding: 8px 20px
}

.example-twitter-oss .tt-suggestion + .tt-suggestion {
    border-top: 1px solid #ccc
}

.example-twitter-oss .repo-language {
    float: right;
    font-style: italic;
    font-size: 12px;
}

.example-twitter-oss .repo-name {
    font-weight: bold
}

.example-twitter-oss .repo-description {
    font-size: 12px
}

.slider-vertical {
    height: 200px;
    float: left;
    margin-right: 20px;
}

.datepicker td,
.datepicker th,
.daterangepicker td,
.daterangepicker th {
    text-transform: uppercase;
    font-size: 0.875em;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled.disabled:hover,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active[disabled]:hover,
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled[disabled]:hover {
    background: #004e95 !important
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background: #004e95 !important
}

.daterangepicker td.in-range {
    background: #dceffc !important
}

.daterangepicker .ranges li {
    border-radius: 0 !important;
    background-clip: padding-box;
    color: #004e95 !important;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
    background-color: #004e95 !important;
    border-color: #004e95 !important;
    color: #fff !important;
}

.daterangepicker .range_inputs button.applyBtn {
    min-width: 74px;
    margin-right: 7px;
}

.daterangepicker .range_inputs button.cancelBtn {
    min-width: 74px
}

#gallery-photos-wrapper {
    position: relative
}

.gallery-photos {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.gallery-photos li {
    float: left;
    display: block;
    margin-top: 7px;
    margin-bottom: 7px;
    overflow: hidden;
    position: relative;
}

.gallery-photos li .photo-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #eee;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-width: 100px;
    min-height: 100px;
    padding-top: 98%;
    display: block;
}

.gallery-photos li .photo-box.image-link {
    padding-top: 68%
}

.gallery-photos li:hover .photo-box:after {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    content: "";
    top: 0;
    right: 8px;
    bottom: 0;
    left: 8px;
}

.gallery-photos.gallery-photos-hover {
    cursor: move
}

.gallery-photos .remove-photo-link {
    cursor: pointer;
    display: none;
    left: 50%;
    margin-left: -18.5px;
    position: absolute;
    text-align: center;
    top: 50%;
    margin-top: -18.5px;
}

.gallery-photos .thumb-meta-time {
    bottom: 10px;
    color: #eee;
    font-size: 0.75em;
    left: 18px;
    position: absolute;
}

.gallery-photos li .remove-photo-link .icon-stack {
    font-size: 2em
}

.gallery-photos.gallery-photos-hover li:hover .remove-photo-link {
    display: block
}

.dd {
    position: relative;
    display: block;
    list-style: none;
    font-size: 12px;
    line-height: 20px;
}

@media only screen and (min-width: 700px) {
    .dd {
        float: left;
        width: 48%;
    }
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 30px
}

.dd-collapsed .dd-list {
    display: none
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 12px;
    line-height: 20px;
}

.dd-handle-list {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #E1E1E1;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 1em;
    color: #000000;
    text-align: center;
    border: 1px solid #E1E1E1;
    padding-top: 4px;
}

.dd-handle {
    background: none repeat scroll 0 0 #F6F6F8;
    border: 2px solid #e1e1e1;
    border-radius: 0;
    background-clip: padding-box;
    color: #000000;
    display: block;
    font-size: 0.875em;
    font-weight: 700;
    height: 32px;
    margin: 5px 0;
    padding: 5px 10px;
    text-decoration: none;
    box-sizing: border-box;
}

.dd-handle:hover {
    color: #004e95;
    background: #fff;
}

.dd-handle .nested-links {
    float: right
}

.dd-handle .nested-links .nested-link {
    margin: 0 2px
}

.dd-handle .nested-links .nested-link:last-child {
    margin-right: 0
}

.dd-handle .nested-links .nested-link i {
    font-size: 1.25em
}

.dd-item-list .dd-handle {
    padding-left: 40px
}

.dd-item > button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
}

.dd-item > button:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
    content: '-'
}

.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f2fbff;
    border: 1px dashed #b6bcbf;
    box-sizing: border-box;
}

.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
    margin-top: 0
}

.dd-dragel .dd-handle {
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1)
}

.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
}

.nestable-lists .dd-placeholder {
    background: #E9FDFB;
    border-color: #004e95;
}

.dd-hover > .dd-handle {
    background: #2ea8e5 !important
}

@media only screen and (min-width: 700px) {
    .dd + .dd {
        margin-left: 2%
    }
}

#login-page {
    background-color: #e7ebee
}

#login-page-full {
    background-color: #e7ebee
}

#login-box {
    max-width: 350px;
    min-width: 280px;
    margin: 60px auto 20px;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
}

@media only screen and (max-width: 767px) {
    #login-box {
        margin-top: 20px
    }
}

#login-box #login-box-header {
    height: 5px
}

#login-box #login-box-header > div {
    height: 100%;
    width: 16.6667%;
    float: left;
}

#login-box #login-box-header .login-box-header-red {
    background: #f4786e
}

#login-box #login-box-header .login-box-header-green {
    background: #7FC8BA
}

#login-box #login-box-header .login-box-header-yellow {
    background: #ffc107
}

#login-box #login-box-header .login-box-header-purple {
    background: #9972B5
}

#login-box #login-box-header .login-box-header-blue {
    background: #004e95
}

#login-box #login-box-header .login-box-header-gray {
    background: #90a4ae
}

#login-box-inner {
    background: #fff;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
    border: 1px solid #e1e1e1;
    border-bottom-width: 5px;
    padding: 40px 25px;
}

#login-box-inner.with-heading {
    padding-top: 20px
}

#login-box-inner h4 {
    margin-top: 0;
    margin-bottom: 10px;
}

#login-box-inner .reset-pass-input {
    padding: 15px 0;
    margin-bottom: 0;
}

#login-logo {
    background: none repeat scroll 0 0 #34495e;
    color: #fff;
    display: block;
    font-size: 2em;
    font-weight: 400;
    padding: 35px 0;
    text-align: center;
    text-transform: uppercase;
}

#login-logo > img {
    display: block;
    height: 40px;
    margin: 0 auto;
}

#login-logo > span {
    display: block;
    font-size: 0.6em;
    font-weight: 300;
    text-transform: none;
}

#login-box .input-group {
    margin-bottom: 10px
}

#login-box .input-group input {
    font-weight: 300
}

#login-box .input-group .input-group-addon {
    padding-left: 0;
    padding-right: 0;
    min-width: 50px;
}

#login-box .input-group .input-group-addon i {
    color: #efefef
}

#login-box #login-forget-link {
    display: block;
    font-size: 0.875em;
    text-align: right;
    margin-top: 3px;
}

#login-box #remember-me-wrapper {
    padding: 10px 0
}

#login-box .btn {
    font-size: 1.125em;
    font-weight: 600;
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: uppercase;
    margin-top: 8px;
}

#login-box .form-group {
    margin-bottom: 7px
}

#login-box .form-group .checkbox label {
    padding-left: 7px
}

#login-box .form-group .checkbox input {
    margin-left: 0
}

#login-box .btn-facebook,
#login-box .btn-twitter {
    text-transform: none;
    font-size: 1em;
    margin-bottom: 10px;
}

#login-box .social-text {
    margin: 0;
    padding: 15px 0;
    text-align: center;
    font-size: 0.875em;
}

#login-box-inner .input-group > .form-control,
#login-box-inner .input-group > .input-group-addon {
    height: 46px;
    line-height: 42px;
    padding-top: 0;
    padding-bottom: 0;
}

#login-box-inner .input-group > .input-group-addon {
    height: 44px
}

#login-box-footer {
    text-align: center;
    font-size: 0.875em;
    margin-top: 10px;
}

#login-full-wrapper #login-box-footer {
    color: #fff
}

#login-full-wrapper #login-box-footer a {
    color: #fff;
    text-decoration: underline;
}

#login-full-wrapper #login-box-footer a:hover {
    text-decoration: none
}

#login-page .login-create,
#login-page-full .login-create {
    margin-bottom: 20px
}

#login-full-wrapper {
    /* background: url(../../img/login-img.jpg) no-repeat center center; */
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
}

@media (max-height: 605px) {
    #login-full-wrapper {
        position: relative
    }
}

@media (max-height: 621px) and (max-width: 767px) {
    #login-full-wrapper {
        position: relative
    }
}

#login-full-wrapper #login-box {
    border: none
}

#login-page-full .container {
    max-width: 1440px;
    margin: 0 auto;
}

#login-page-full .login-full-create {
    margin-right: 20px;
    line-height: 50px;
}

#login-full-left {
    margin-top: 50px;
    margin-bottom: 20px;
}

#login-full-left h1 {
    text-align: center;
    color: #363636;
    font-weight: 600;
    margin-bottom: 40px;
}

#login-full-left h2 {
    text-align: center;
    margin-top: 30px;
}

#login-full-left p.login-full-devices {
    margin-top: 30px
}

#login-full-left .login-full-features {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

#login-full-left .login-full-features > li {
    display: inline-block;
    margin: 0 8px;
}

#login-full-left .login-full-features > li > i {
    display: block;
    text-align: center;
    font-size: 1.6em;
    margin-bottom: 4px;
}

#login-full-left .login-full-features > li > span {
    display: block;
    text-align: center;
    font-size: 0.845em;
    line-height: 1.2;
}

@media (max-height: 605px) {
    #login-full-wrapper.reset-password-wrapper {
        position: absolute
    }
}

@media (max-height: 505px) {
    #login-full-wrapper.reset-password-wrapper {
        position: relative
    }
}

#lock-screen-user img {
    border-radius: 18%;
    background-clip: padding-box;
    display: block;
    height: 100px;
    margin: 0 auto;
    width: 100px;
}

#lock-screen-user .user-box .name {
    display: block;
    font-size: 1.3em;
    padding: 10px 0 40px;
    text-align: center;
}

#pwindicator {
    margin-top: 15px;
    margin-bottom: 5px;
    width: 150px;
}

.pwdindicator .bar {
    height: 4px
}

.pwdindicator.pw-very-weak .bar {
    background: #fb241f;
    width: 30px;
}

.pwdindicator.pw-very-weak .pwdstrength-label {
    color: #fb241f
}

.pwdindicator.pw-weak .bar {
    background: #f4786e;
    width: 60px;
}

.pwdindicator.pw-weak .pwdstrength-label {
    color: #f4786e
}

.pwdindicator.pw-mediocre .bar {
    background: #ffc107;
    width: 90px;
}

.pwdindicator.pw-mediocre .pwdstrength-label {
    color: #ffc107
}

.pwdindicator.pw-strong .bar {
    background: #f3b31a;
    width: 120px;
}

.pwdindicator.pw-strong .pwdstrength-label {
    color: #f3b31a
}

.pwdindicator.pw-very-strong .bar {
    background: #7FC8BA;
    width: 150px;
}

.pwdindicator.pw-very-strong .pwdstrength-label {
    color: #7FC8BA
}

.pricing-package {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pricing-package .pricing-package-inner {
    color: #7d7d7d;
    position: relative;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-clip: padding-box;
}

.pricing-package .pricing-star {
    /* background: url(../../img/pricing-star.png) no-repeat; */
    position: absolute;
    width: 39px;
    height: 40px;
    color: #fff;
    font-size: 0.65em;
    text-align: center;
    line-height: 1.1;
    padding-top: 9px;
    right: -10px;
    top: -20px;
}

.pricing-package .package-header {
    background: #004e95;
    border-radius: 2px 2px 0 0;
    background-clip: padding-box;
}

.pricing-package .package-header h3 {
    line-height: 1;
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 20px 0 0;
    border: 0;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 400;
}

.pricing-package .package-header .stars {
    color: #fff;
    text-align: center;
    padding-top: 8px;
    display: none;
}

.pricing-package .package-content {
    padding: 0;
    margin: 0;
    border: 1px solid #e7ebee;
    border-top-color: transparent;
    border-radius: 0 0 2px 2px;
    background-clip: padding-box;
}

.pricing-package .package-content .package-price {
    font-weight: bold;
    text-align: center;
    height: 80px;
    line-height: 75px;
    background: #004e95;
    color: #fff;
    margin: -1px;
    position: relative;
}

.pricing-package .package-content .package-price .package-month {
    font-size: 1em;
    font-weight: normal;
}

.pricing-package .package-content .package-price .price {
    font-size: 4em;
    font-weight: 400;
}

.pricing-package .package-content .package-price .currency {
    font-size: 1.5em;
    font-weight: 600;
}

.pricing-package .package-top-features {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pricing-package .package-top-features li {
    border-bottom: 1px solid #e7ebee;
    text-align: center;
    font-size: 1.125em;
    line-height: 1.5;
    padding: 8px 0;
}

.pricing-package .package-top-features li:first-child {
    border-top: 1px solid #e7ebee
}

.pricing-package .package-features {
    list-style: none;
    margin: 0 18px;
    padding: 15px 0;
    position: relative;
}

.pricing-package .package-features li {
    font-size: 0.875em;
    padding: 6px 0;
    line-height: 1.3;
    color: #7d7d7d;
    padding-left: 25px;
}

.pricing-package .package-features li.has-feature {
    transition: color 0.1s ease-in-out 0s
}

.pricing-package .package-features li.has-nofeature {
    text-decoration: line-through
}

.pricing-package .package-features li.has-feature:before {
    content: "\f00c";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    text-decoration: inherit;
    color: #7FC8BA;
    margin-top: -2px;
    position: absolute;
    left: 0;
    text-decoration: none;
}

.pricing-package .package-features li.has-nofeature:before {
    content: "\f00d";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    text-decoration: inherit;
    color: #f4786e;
    margin-top: -2px;
    position: absolute;
    left: 0;
    text-decoration: none;
}

.pricing-package .package-footer {
    text-align: center;
    padding: 10px 0 25px;
}

.pricing-package .package-footer .btn > span {
    padding-right: 6px;
    transition: color 0.1s ease-in-out 0s;
}

.pricing-package .package-footer .btn:hover > span {
    color: #fff
}

.pricing-package.simple .package-header h3 {
    font-size: 1.3em;
    padding: 0;
    line-height: 42px;
    height: 42px;
}

.pricing-package.simple .package-content .package-price {
    font-size: 1.8em;
    height: 60px;
    line-height: 60px;
    background: transparent;
    color: inherit;
}

.pricing-package.simple .package-top-features li {
    font-size: 0.875em
}

.pricing-package.simple .package-top-features li:last-child {
    border-bottom: 0
}

.pricing-package:hover .pricing-package-inner {
    background-color: #fcfcfc;
    color: #929292;
}

.pricing-package:hover .package-features li.has-feature {
    color: #929292
}

#invoice-companies {
    border: 1px solid #e7ebee;
    border-left: 0;
    border-right: 0;
    margin: 20px -20px;
}

@media (max-width: 419px) {
    #invoice-companies {
        margin-left: -10px;
        margin-right: -10px;
    }
}

#invoice-companies .invoice-box {
    border-right: 1px solid #e7ebee;
    padding: 25px 20px;
    height: 100%;
    min-height: 165px;
}

@media only screen and (max-width: 767px) {
    #invoice-companies .invoice-box {
        border-bottom: 1px solid #e7ebee;
        border-right: 0;
        border-left: 0;
    }
}

#invoice-companies .invoice-box.invoice-box-dates {
    border-right: 0
}

@media only screen and (max-width: 767px) {
    #invoice-companies .invoice-box.invoice-box-dates {
        border-bottom: 0
    }
}

#invoice-companies .invoice-icon {
    float: left;
    margin-right: 20px;
    margin-top: 20px;
    color: #7FC8BA;
    text-align: center;
}

#invoice-companies .invoice-icon > i {
    font-size: 2.4em;
    display: block;
}

#invoice-companies .invoice-company {
    float: left
}

#invoice-companies .invoice-company h4 {
    font-size: 1.4em
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #invoice-companies .invoice-dates {
        padding-top: 15px
    }
}

#invoice-companies .invoice-dates .invoice-date > strong {
    text-transform: uppercase;
    line-height: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #invoice-companies .invoice-dates .invoice-date > strong {
        font-size: 0.875em;
        line-height: 22px;
    }
}

#invoice-companies .invoice-dates .invoice-date > span {
    font-size: 1.3em
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #invoice-companies .invoice-dates .invoice-date > span {
        font-size: 1.1em
    }
}

#invoice-companies .invoice-dates .invoice-date.invoice-due-date > span {
    font-weight: bold
}

#invoice-companies .invoice-dates .invoice-number > strong {
    text-transform: uppercase;
    line-height: 48px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #invoice-companies .invoice-dates .invoice-number > strong {
        font-size: 0.875em;
        line-height: 33px;
    }
}

#invoice-companies .invoice-dates .invoice-number > span {
    color: #004e95;
    font-size: 1.9em;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #invoice-companies .invoice-dates .invoice-number > span {
        font-size: 1.4em
    }
}

.invoice-box-total {
    line-height: 1.8;
    border: 1px solid #e7ebee;
    border-right: 0;
    border-left: 0;
    padding: 20px 0;
}

.invoice-box-total .invoice-box-total-label {
    font-size: 1.125em
}

.invoice-box-total .invoice-box-total-value {
    font-size: 1.125em
}

.invoice-box-total .grand-total {
    font-size: 1.25em;
    text-transform: uppercase;
    font-weight: bold;
}

.invoice-summary {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: -3px;
    margin-right: -3px;
}

.invoice-summary > div {
    padding-left: 3px;
    padding-right: 3px;
}

.invoice-summary .invoice-summary-item {
    background: #dceffc;
    text-align: center;
    padding: 10px 0;
    margin-top: 3px;
    margin-bottom: 3px;
}

.invoice-summary .invoice-summary-item > span {
    font-size: 0.75em
}

.invoice-summary .invoice-summary-item > div {
    font-size: 1.125em
}

#user-profile h2 {
    padding-right: 15px
}

#user-profile .profile-status {
    font-size: 0.75em;
    margin-top: -10px;
    padding-bottom: 10px;
    color: #7FC8BA;
}

#user-profile .profile-status.offline {
    color: #f4786e
}

#user-profile .profile-img {
    border-radius: 50%;
    background-clip: padding-box;
    margin-bottom: 15px;
}

#user-profile .profile-label {
    text-align: center;
    padding: 5px 0;
}

#user-profile .profile-label.label {
    padding: 5px 15px;
    font-size: 1em;
}

#user-profile .profile-stars {
    color: #FABA03;
    padding: 7px 0;
    text-align: center;
}

#user-profile .profile-stars > i {
    margin-left: -2px
}

#user-profile .profile-since {
    text-align: center;
    margin-top: -5px;
}

#user-profile .profile-details {
    padding: 15px 0;
    border-top: 1px solid #e7ebee;
    border-bottom: 1px solid #e7ebee;
    margin: 15px 0;
}

#user-profile .profile-details ul {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 40px;
}

#user-profile .profile-details ul > li {
    margin: 3px 0;
    line-height: 1.5;
}

#user-profile .profile-details ul > li > i {
    padding-top: 2px
}

#user-profile .profile-details ul > li > span {
    color: #004e95
}

#user-profile .profile-header {
    position: relative
}

#user-profile .profile-header > h3 {
    margin-top: 10px
}

#user-profile .profile-header .edit-profile {
    margin-top: -6px;
    position: absolute;
    right: 0;
    top: 0;
}

@media only screen and (max-width: 419px) {
    #user-profile .profile-header .edit-profile {
        display: block;
        position: relative;
        margin-bottom: 15px;
    }
}

#user-profile .profile-tabs {
}

#user-profile .profile-user-info {
    padding-bottom: 20px;
    padding-top: 10px;
}

#user-profile .profile-user-info .profile-user-details {
    position: relative;
    padding: 4px 0;
}

#user-profile .profile-user-info .profile-user-details .profile-user-details-label {
    width: 110px;
    float: left;
    bottom: 0;
    font-weight: bold;
    left: 0;
    position: absolute;
    text-align: right;
    top: 0;
    width: 110px;
    padding-top: 4px;
}

@media only screen and (max-width: 767px) {
    #user-profile .profile-user-info .profile-user-details .profile-user-details-label {
        float: none;
        position: relative;
        text-align: left;
    }
}

#user-profile .profile-user-info .profile-user-details .profile-user-details-value {
    margin-left: 120px
}

@media only screen and (max-width: 767px) {
    #user-profile .profile-user-info .profile-user-details .profile-user-details-value {
        margin-left: 0
    }
}

@media only screen and (max-width: 767px) {
    #user-profile .profile-social {
        margin-top: 20px
    }
}

#user-profile .profile-social li {
    padding: 4px 0
}

#user-profile .profile-social li > i {
    padding-top: 6px
}

@media only screen and (max-width: 419px) {
    #user-profile .profile-message-btn .btn {
        display: block
    }
}

#user-profile .table tbody tr td {
    padding-bottom: 20px;
    padding-top: 20px;
}

#user-profile .table tbody tr:first-child td {
    border-top: none
}

#newsfeed .story {
    padding: 40px 0;
    margin: 0 20px;
    border-bottom: 1px solid #e7ebee;
}

#newsfeed .story:last-child {
    border-bottom: 0
}

@media (max-width: 767px) {
    #newsfeed .story {
        padding: 20px 0;
        margin: 0;
    }
}

#newsfeed .story-user {
    position: absolute
}

#newsfeed .story-user a {
    display: block
}

#newsfeed .story-user a img {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-clip: padding-box;
}

@media (max-width: 767px) {
    #newsfeed .story-user a img {
        width: 30px;
        height: 30px;
    }
}

#newsfeed .story-content {
    padding-left: 100px;
    position: relative;
}

@media (max-width: 767px) {
    #newsfeed .story-content {
        padding-left: 40px
    }
}

#newsfeed .story-content .story-header .story-author {
    font-weight: 300
}

#newsfeed .story-content .story-header .story-author a {
    font-weight: 400
}

#newsfeed .story-content .story-header .story-time {
    color: #999;
    font-size: 0.75em;
    position: absolute;
    right: 0px;
    top: 0;
}

@media (max-width: 1170px) {
    #newsfeed .story-content .story-header .story-time {
        position: relative;
        padding-top: 2px;
    }
}

#newsfeed .story-content .story-inner-content {
    color: #6c6f75;
    font-size: 0.85em;
    padding: 12px 0;
}

#newsfeed .story-content .story-footer {
    font-size: 0.85em
}

#newsfeed .story-content .story-footer .story-comments-link {
    color: #929292;
    margin-right: 10px;
}

#newsfeed .story-content .story-footer .story-comments-link:hover {
    color: #161e1d;
    text-decoration: none;
}

#newsfeed .story-content .story-footer .story-likes-link {
    color: #f4786e
}

#newsfeed .story-content .story-footer .story-likes-link:hover {
    color: #dd191d;
    text-decoration: none;
}

#newsfeed .map-content {
    width: 100%;
    height: 200px;
}

#newsfeed .story-images .story-image-link {
    display: block;
    width: 50%;
    float: left;
    padding-right: 2px;
}

@media (max-width: 767px) {
    #newsfeed .story-images .story-image-link {
        width: 50%
    }
}

#newsfeed .story-images .story-image-link-small {
    width: 25%;
    padding-bottom: 2px;
}

@media (max-width: 767px) {
    #newsfeed .story-images .story-image-link-small {
        width: 50%
    }
}

.widget-users {
    padding: 0 20px
}

@media (max-width: 767px) {
    .widget-users {
        padding: 0 10px
    }
}

.conversation-wrapper {
    padding: 10px 20px
}

@media (max-width: 767px) {
    .conversation-wrapper {
        padding: 10px 5px
    }
}

.conversation-wrapper .conversation-inner {
    padding: 0 0 5px 0;
    margin-right: 10px;
}

.conversation-wrapper .conversation-user {
    width: 50px;
    height: 50px;
    overflow: hidden;
    float: left;
    border-radius: 50%;
    background-clip: padding-box;
    margin-top: 6px;
}

.conversation-wrapper .conversation-item {
    padding: 5px 0;
    position: relative;
}

.conversation-wrapper .conversation-item.item-right .conversation-body {
    background: #dceffc
}

.conversation-wrapper .conversation-item.item-right .conversation-body:before {
    border-color: transparent transparent transparent #dceffc;
    left: auto;
    right: -12px;
}

.conversation-wrapper .conversation-item.item-right .conversation-user {
    float: right
}

.conversation-wrapper .conversation-item.item-right .conversation-body {
    margin-left: 0;
    margin-right: 60px;
}

.conversation-wrapper .conversation-body {
    background: #f5f5f5;
    font-size: 0.875em;
    width: auto;
    margin-left: 60px;
    padding: 8px 10px;
    position: relative;
    border-radius: 3px;
    background-clip: padding-box;
}

.conversation-wrapper .conversation-body:before {
    border-color: transparent #f5f5f5 transparent transparent;
    border-style: solid;
    border-width: 6px;
    content: "";
    cursor: pointer;
    left: -12px;
    position: absolute;
    top: 25px;
}

.conversation-wrapper .conversation-body > .name {
    font-weight: 600;
    font-size: 1.125em;
}

.conversation-wrapper .conversation-body > .time {
    position: absolute;
    font-size: 0.875em;
    right: 10px;
    top: 0;
    margin-top: 10px;
    color: #605f5f;
    font-weight: 300;
}

.conversation-wrapper .conversation-body > .time:before {
    content: "\f017";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin-top: 4px;
    font-size: 0.875em;
}

.conversation-wrapper .conversation-body > .text {
    padding-top: 6px
}

.conversation-wrapper .conversation-new-message {
    padding-top: 10px
}

.widget-users {
    list-style: none;
    margin: 0;
    padding: 0;
}

.widget-users li {
    border-bottom: 2px solid #ebebeb;
    padding: 15px 0;
    height: 96px;
}

.widget-users li > .img {
    float: left;
    margin-top: 8px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    background-clip: padding-box;
}

.widget-users li > .details {
    margin-left: 60px
}

.widget-users li > .details > .name {
    font-weight: 600
}

.widget-users li > .details > .name > a {
    color: #929292
}

.widget-users li > .details > .name > a:hover {
    color: #004e95
}

.widget-users li > .details > .time {
    color: #004e95;
    font-size: 0.75em;
    padding-bottom: 7px;
}

.widget-users li > .details > .time.online {
    color: #7FC8BA
}

.infographic-box {
    padding: 20px
}

.infographic-box i {
    font-size: 2.4em;
    display: block;
    float: left;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background-clip: padding-box;
    color: #fff;
}

.infographic-box .headline {
    display: block;
    font-size: 1.2em;
    font-weight: 300;
    text-align: right;
}

.infographic-box .value {
    font-size: 2.1em;
    font-weight: 600;
    margin-top: -5px;
    display: block;
    text-align: right;
}

.infographic-box.merged {
    border-top: 2px solid #e7ebee;
    border-right: 2px solid #e7ebee;
    width: 50%;
}

@media (max-width: 767px) {
    .infographic-box.merged {
        width: 100%;
        border-right: none;
    }
}

.infographic-box.merged .headline {
    text-align: left;
    font-size: 0.8em;
    margin-top: -2px;
    text-transform: uppercase;
    font-weight: 600;
}

.infographic-box.merged .value {
    text-align: left;
    font-size: 1.8em;
    font-weight: 400;
    margin-top: 2px;
}

.infographic-box.merged.merged-top {
    border-top: none
}

.infographic-box.merged.merged-right {
    border-right: none
}

@media (max-width: 767px) {
    .infographic-box.merged.merged-top.merged-right {
        border-top: 1px solid #e7ebee
    }
}

.infographic-box.colored {
    color: #fff;
    border: 0 !important;
}

.infographic-box.colored i {
    font-size: 3.6em;
    margin-left: 7px;
    color: rgba(255, 255, 255, 0.7);
    transition: all .2s ease-in-out;
}

.infographic-box.colored:hover i {
    transform: scale(1.3)
}

.infographic-box.colored .headline {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 4px;
}

.widget-products {
    list-style: none;
    margin: 0;
    padding: 0;
}

.widget-products li {
    border-bottom: 2px solid #ebebeb
}

.widget-products li > a {
    height: 88px;
    display: block;
    width: 100%;
    color: #929292;
    padding: 3px 10px;
    position: relative;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}

@media only screen and (max-width: 440px) {
    .widget-products li > a {
        height: auto;
        padding-bottom: 10px;
    }
}

.widget-products li > a:hover {
    color: #929292;
    text-decoration: none;
    background-color: #f6f6f6;
}

.widget-products li > a:after {
    content: "\f138";
    font-family: FontAwesome;
    font-size: 0.875em;
    font-style: normal;
    font-weight: normal;
    margin-top: 32px;
    position: absolute;
    right: 10px;
    text-decoration: inherit;
    top: 0;
    color: #cccccc;
    font-size: 1.3em;
}

@media only screen and (max-width: 440px) {
    .widget-products li > a:after {
        display: none
    }
}

.widget-products li .img {
    display: block;
    float: left;
    text-align: center;
    width: 70px;
    height: 68px;
    overflow: hidden;
    margin-top: 7px;
}

.widget-products li .product {
    display: block;
    margin-left: 90px;
    margin-top: 19px;
}

.widget-products li .product > .name {
    display: block;
    font-weight: 600;
    padding-bottom: 7px;
}

.widget-products li .product > .price {
    display: block;
    text-decoration: none;
    width: 50%;
    float: left;
    font-size: 0.875em;
}

@media only screen and (max-width: 440px) {
    .widget-products li .product > .price {
        width: 100%
    }
}

.widget-products li .product > .price > i {
    color: #7FC8BA
}

.widget-products li .product > .warranty {
    display: block;
    text-decoration: none;
    width: 50%;
    float: left;
    font-size: 0.875em;
}

@media only screen and (max-width: 440px) {
    .widget-products li .product > .warranty {
        clear: both;
        width: 100%;
    }
}

.widget-products li .product > .warranty > i {
    color: #ffc107
}

.widget-todo {
    list-style: none;
    margin: 0;
    padding: 0;
}

.widget-todo > li {
    border-bottom: 2px solid #ebebeb;
    padding: 10px 5px;
}

.widget-todo > li:hover {
    background-color: #f8f8f8
}

.widget-todo > li input:checked + label {
    text-decoration: line-through
}

.widget-todo .name {
    float: left
}

.widget-todo .name span.label {
    margin-left: 5px
}

.widget-todo .actions {
    float: right;
    padding-right: 5px;
    padding-top: 2px;
}

.widget-todo .actions > a {
    margin: 0 2px;
    color: #707070;
}

.widget-todo .actions > a.badge {
    color: #fff
}

.widget-todo .actions > a:hover {
    text-decoration: none;
    color: #004e95;
}

.widget-todo .actions > a.badge:hover {
    background-color: #fff
}

.widget-todo .actions > a > i {
    font-size: 1.125em
}

.xs-graph .graph-label {
    font-size: 0.875em;
    padding-bottom: 5px;
    color: #616161;
}

.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    border-radius: 3px;
    border: 0 !important;
    background: rgba(0, 0, 0, 0.7) !important;
    opacity: 1;
}

#flot-tooltip {
    position: absolute;
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(230, 230, 230, 0.8);
    border-radius: 10px;
    color: #666;
    font-family: sans-serif;
    font-size: 12px;
    padding: 6px;
    text-align: center;
}

#flot-tooltip span {
    display: block
}

#flot-tooltip b {
    font-weight: bold;
    margin: 0.25em 0;
    color: #666;
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
}

#flot-tooltip i {
    margin: 0.1em 0;
    white-space: nowrap;
    color: #666;
    font-family: sans-serif;
    font-size: 12px;
    text-align: center;
    font-style: normal;
}

.legend .legendColorBox > div {
    margin-right: 7px;
    border: none !important;
}

.graph-stats {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}

.graph-stats li {
    padding: 0
}

.graph-stats li .title {
    color: #3b3b3b;
    margin-top: 12px;
    font-size: 0.875em;
}

.graph-stats li .value {
    color: #3b3b3b;
    font-size: 1.6em;
    font-weight: 300;
}

.graph-stats li .value > .fa {
    font-size: 0.6em
}

.graph-stats li .value .tooltip {
    font-weight: 400
}

.graph-stats li .progress {
    height: 5px
}

.graph-box {
    padding: 15px 15px;
    border-radius: 3px 0 0 3px;
    background-clip: padding-box;
}

.graph-box h2 {
    color: #fff;
    font-size: 1.6em;
    font-weight: 300;
    margin-bottom: 10px;
    margin-left: 10px;
}

.graph-box .graph .morris-hover.morris-default-style {
    background: #000;
    background: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 6px;
    background-clip: padding-box;
    color: #999;
}

.graph-nice-legend {
    padding-left: 15px;
    padding-top: 65px;
}

@media (max-width: 1199px) {
    .graph-nice-legend {
        padding-left: 0
    }
}

@media (max-width: 991px) {
    .graph-nice-legend {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0;
    }
}

.graph-nice-legend .graph-legend-row {
    padding-top: 10px;
    padding-bottom: 10px;
}

.graph-nice-legend .graph-legend-name {
    display: block;
    font-size: 1.2em;
    font-weight: 300;
}

.graph-nice-legend .graph-legend-value {
    display: block;
    font-size: 2.1em;
    font-weight: 600;
}

@media (max-width: 991px) {
    .graph-nice-legend .graph-legend-row-inner {
        padding: 0 20px
    }
}

.small-graph-box {
    padding: 20px;
    color: #fff;
    border: 0 !important;
}

.small-graph-box .box-button {
    position: absolute;
    top: 10px;
    right: 15px;
    display: none;
}

.small-graph-box .box-button a {
    font-size: 11px;
    color: #fff;
    padding: 3px 4px;
    border: 1px solid #fff;
    line-height: 10px;
    border-radius: 50px;
    display: inline-block;
}

.small-graph-box .box-button a:hover {
    border-color: #666;
    color: #666;
    cursor: pointer;
}

.small-graph-box .headline {
    display: block;
    font-size: 0.875em;
    font-weight: 400;
    margin-top: -5px;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.small-graph-box .value {
    display: block;
    font-size: 1.9em;
    font-weight: 600;
    margin-top: -5px;
}

.small-graph-box .progress {
    background: rgba(0, 0, 0, 0.2);
    height: 5px;
    margin-bottom: 5px;
    margin-top: 10px;
}

.small-graph-box .progress .progress-bar {
    background-color: #fff
}

.small-graph-box .subinfo {
    display: block;
    font-size: 0.8em;
    padding-top: 5px;
}

.small-graph-box .subinfo i {
    font-size: 1.2em
}

.dashboard-stats {
    margin: 20px -20px -20px
}

@media (max-width: 419px) {
    .dashboard-stats {
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: -12px;
    }
}

.chart-small {
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    outline: none;
    padding: 15px 0;
    display: block;
    text-decoration: none;
    color: #929292;
    transition: background-color 0.1s ease-in-out 0s;
}

.chart-small:last-child,
.chart-small.last {
    border-right: 0
}

@media (max-width: 767px) {
    .chart-small:nth-child(2) {
        border-right: 0
    }
}

.chart-small:hover {
    background-color: #f9f9f9;
    text-decoration: none;
    color: #929292;
}

.chart-small .headline {
    font-weight: 600;
    font-size: 0.75em;
    padding: 0 20px;
    margin-bottom: -5px;
}

.chart-small .value {
    font-weight: 600;
    font-size: 1.9em;
    padding: 0 20px;
}

@media (max-width: 419px) {
    .chart-small .value {
        font-size: 1.6em
    }
}

.chart-small-content {
    max-height: 100px;
    height: 50px;
    padding: 0px;
    margin: 20px 20px 0;
    position: relative;
}

.social-box-wrapper {
    box-shadow: 1px 1px 2px 0 #cccccc;
    border-radius: 3px;
    background-clip: padding-box;
    margin-bottom: 16px;
    overflow: hidden;
}

.social-box {
    color: #fff;
    padding: 15px;
    font-weight: 300;
    transition: background-color 0.15s ease-in-out 0s;
}

.social-box .social-count {
    float: left;
    display: block;
    font-size: 1.8em;
    font-weight: 400;
}

.social-box .social-action {
    float: left;
    display: block;
    font-size: 1.2em;
    margin-left: 5px;
    margin-top: 9px;
}

.social-box .social-name {
    display: block;
    font-size: 0.875em;
    padding-top: 4px;
}

.social-box > i {
    font-size: 3.4em;
    text-align: right;
    display: block;
}

.social-box.facebook {
    background: #3b5998
}

.social-box.facebook:hover {
    background-color: #2f477b
}

.social-box.twitter {
    background: #55acee
}

.social-box.twitter:hover {
    background-color: #4091ce
}

.social-box.google {
    background: #dd4b39
}

.social-box.google:hover {
    background-color: #c43a2a
}

.profile-box .main-box-body {
    padding: 0
}

.profile-box .profile-box-header {
    padding: 30px 20px 20px;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
}

.profile-box .profile-img {
    border-radius: 18%;
    background-clip: padding-box;
    width: 130px;
    height: 130px;
    border: 5px solid #fff;
}

.profile-box h2 {
    padding: 10px 0 0;
    margin: 0;
    font-weight: 600;
}

.profile-box .job-position {
    font-weight: 400;
    color: #fff;
    font-size: 0.875em;
}

.profile-box .profile-box-footer {
    background: #004e95;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
}

.profile-box .profile-box-footer a {
    display: block;
    background: #004e95;
    width: 33%;
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 15px 10px;
    color: #fff;
}

.profile-box .profile-box-footer a:first-child {
    border-radius: 0 0 0 3px;
    background-clip: padding-box;
}

.profile-box .profile-box-footer a:last-child {
    border-radius: 0 0 3px 0;
    background-clip: padding-box;
}

.profile-box .profile-box-footer a:hover {
    text-decoration: none;
    color: #fff;
}

.profile-box .profile-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 600;
}

.profile-box .profile-box-footer .label {
    display: block;
    font-size: 0.875em;
    font-weight: 600;
}

.profile-box-menu .main-box-body {
    padding: 0
}

.profile-box-menu .profile-box-header {
    padding: 30px 20px;
    color: #fff;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    background-repeat: no-repeat;
    background-size: cover;
}

.profile-box-menu .profile-img {
    border-radius: 18%;
    background-clip: padding-box;
    width: 110px;
    height: 110px;
    float: left;
    margin-right: 15px;
    border: 5px solid #fff;
}

.profile-box-menu h2 {
    padding: 20px 0 3px;
    margin: 0;
    display: inline-block;
    font-weight: 600;
    line-height: 1.1;
}

.profile-box-menu .job-position {
    font-weight: 600;
    font-size: 0.875em;
}

.profile-box-menu .profile-box-content .menu-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.profile-box-menu .profile-box-content .menu-items li a {
    display: block;
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid #e7ebee;
    padding: 0 20px;
    font-size: 0.875em;
    transition: background-color 0.15s ease-in-out 0s;
    color: #929292;
}

.profile-box-menu .profile-box-content .menu-items li a:hover {
    background-color: #e7ebee;
    text-decoration: none;
}

.profile-box-menu .profile-box-content .menu-items li a i {
    width: 24px
}

.profile-box-menu .profile-box-content .menu-items li a span.label {
    margin-top: 10px
}

.profile-box-menu .profile-box-content .menu-items li:last-child a {
    border-bottom: none
}

.profile-box-stats .main-box-body {
    padding: 0
}

.profile-box-stats .profile-box-header {
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    padding: 30px 20px 70px;
    color: #fff;
    text-align: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.profile-box-stats .profile-img {
    border-radius: 18%;
    background-clip: padding-box;
    border: 5px solid #fff;
    bottom: -45px;
    float: left;
    height: 90px;
    left: 50%;
    margin-left: -45px;
    position: absolute;
    width: 90px;
}

.profile-box-stats h2 {
    padding: 0 0 8px;
    margin: 0;
    display: inline-block;
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.5em;
}

.profile-box-stats .job-position {
    font-weight: 600;
    font-size: 0.875em;
}

.profile-box-stats .profile-box-footer {
    padding-top: 50px;
    padding-bottom: 15px;
}

.profile-box-stats .profile-box-footer a {
    display: block;
    width: 33%;
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 15px 10px;
    color: #929292;
}

.profile-box-stats .profile-box-footer a:hover {
    text-decoration: none
}

.profile-box-stats .profile-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 300;
}

.profile-box-stats .profile-box-footer .label {
    display: block;
    font-size: 1em;
    font-weight: 300;
    color: #929292;
}

.profile-box-contact .main-box-body {
    padding: 0
}

.profile-box-contact .profile-box-header {
    padding: 30px 20px;
    color: #fff;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
}

.profile-box-contact .profile-img {
    border-radius: 18%;
    background-clip: padding-box;
    width: 110px;
    height: 110px;
    float: left;
    margin-right: 15px;
    border: 5px solid #fff;
}

.profile-box-contact h2 {
    padding: 8px 0 3px;
    margin: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.1;
}

.profile-box-contact .job-position {
    font-weight: 300;
    font-size: 0.875em;
}

.profile-box-contact .profile-box-footer {
    padding-top: 10px;
    padding-bottom: 15px;
}

.profile-box-contact .profile-box-footer a {
    display: block;
    width: 33%;
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 15px 10px;
    color: #929292;
}

.profile-box-contact .profile-box-footer a:hover {
    text-decoration: none
}

.profile-box-contact .profile-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 300;
}

.profile-box-contact .profile-box-footer .label {
    display: block;
    font-size: 1em;
    font-weight: 300;
    color: #929292;
}

.profile-box-contact .contact-details {
    padding: 4px 0 0;
    margin: 0;
    list-style: none;
    font-size: 0.875em;
    font-weight: 300;
}

.profile-box-contact .contact-details li i {
    width: 12px;
    text-align: center;
    margin-right: 3px;
}

.ad-box .main-box-body {
    padding: 0
}

.ad-box .ad-box-header {
    /*padding: 30px 20px 20px;*/
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    text-align: center;
    background-clip: padding-box;
    min-height: 200px;
    position: relative;
}

.ad-box a {
    display: block;
    text-align: center;
    color: #fff;
    text-decoration: none;
}

.ad-box a:hover {
    color: #fff;
}

.ad-box a span {
    color: #fff;
    font-family: 'segoe_ui_regular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
}

.ad-box a i {
    margin-left: 0px;
    text-align: left;
    font-weight: normal;
}

.ad-box a.ad-button-left {
    background: #004e95;
    left: 0;
    bottom: 0;
}

.ad-box a.ad-button-right {
    background: #faa519;
    right: 0;
    bottom: 0;
}

/*.ad-box .ad-img {
    border-radius: 18%;
    background-clip: padding-box;
    width: 130px;
    height: 130px;
    border: 5px solid #fff;
}
.ad-box h2 {
    padding: 10px 0 0;
    margin: 0;
    font-weight: 600;
}
.ad-box .job-position {
    font-weight: 400;
    color: #fff;
    font-size: 0.875em;
}
.ad-box .ad-box-footer {
    background: #004e95;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
}
.ad-box .ad-box-footer a {
    display: block;
    background: #004e95;
    width: 33%;
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 15px 10px;
    color: #fff;
}
.ad-box .ad-box-footer a:first-child {
    border-radius: 0 0 0 3px;
    background-clip: padding-box;
}
.ad-box .ad-box-footer a:last-child {
    border-radius: 0 0 3px 0;
    background-clip: padding-box;
}
.ad-box .ad-box-footer a:hover {
    text-decoration: none;
    color: #fff;
}
.ad-box .ad-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 600;
}
.ad-box .ad-box-footer .label {
    display: block;
    font-size: 0.875em;
    font-weight: 600;
}*/
.ad-box-menu .main-box-body {
    padding: 0
}

.ad-box-menu .ad-box-header {
    padding: 30px 20px;
    color: #fff;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    background-repeat: no-repeat;
    background-size: cover;
}

.ad-box-menu .ad-img {
    border-radius: 18%;
    background-clip: padding-box;
    width: 110px;
    height: 110px;
    float: left;
    margin-right: 15px;
    border: 5px solid #fff;
}

.ad-box-menu h2 {
    padding: 20px 0 3px;
    margin: 0;
    display: inline-block;
    font-weight: 600;
    line-height: 1.1;
}

.ad-box-menu .job-position {
    font-weight: 600;
    font-size: 0.875em;
}

.ad-box-menu .ad-box-content .menu-items {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ad-box-menu .ad-box-content .menu-items li a {
    display: block;
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid #e7ebee;
    padding: 0 20px;
    font-size: 0.875em;
    transition: background-color 0.15s ease-in-out 0s;
    color: #929292;
}

.ad-box-menu .ad-box-content .menu-items li a:hover {
    background-color: #e7ebee;
    text-decoration: none;
}

.ad-box-menu .ad-box-content .menu-items li a i {
    width: 24px
}

.ad-box-menu .ad-box-content .menu-items li a span.label {
    margin-top: 10px
}

.ad-box-menu .ad-box-content .menu-items li:last-child a {
    border-bottom: none
}

.ad-box-stats .main-box-body {
    padding: 0
}

.ad-box-stats .ad-box-header {
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    padding: 30px 20px 70px;
    color: #fff;
    text-align: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.ad-box-stats .ad-img {
    border-radius: 18%;
    background-clip: padding-box;
    border: 5px solid #fff;
    bottom: -45px;
    float: left;
    height: 90px;
    left: 50%;
    margin-left: -45px;
    position: absolute;
    width: 90px;
}

.ad-box-stats h2 {
    padding: 0 0 8px;
    margin: 0;
    display: inline-block;
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.5em;
}

.ad-box-stats .job-position {
    font-weight: 600;
    font-size: 0.875em;
}

.ad-box-stats .ad-box-footer {
    padding-top: 50px;
    padding-bottom: 15px;
}

.ad-box-stats .ad-box-footer a {
    display: block;
    width: 33%;
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 15px 10px;
    color: #929292;
}

.ad-box-stats .ad-box-footer a:hover {
    text-decoration: none
}

.ad-box-stats .ad-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 300;
}

.ad-box-stats .ad-box-footer .label {
    display: block;
    font-size: 1em;
    font-weight: 300;
    color: #929292;
}

.ad-box-contact .main-box-body {
    padding: 0
}

.ad-box-contact .ad-box-header {
    padding: 30px 20px;
    color: #fff;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
}

.ad-box-contact .ad-img {
    border-radius: 18%;
    background-clip: padding-box;
    width: 110px;
    height: 110px;
    float: left;
    margin-right: 15px;
    border: 5px solid #fff;
}

.ad-box-contact h2 {
    padding: 8px 0 3px;
    margin: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.1;
}

.ad-box-contact .job-position {
    font-weight: 300;
    font-size: 0.875em;
}

.ad-box-contact .ad-box-footer {
    padding-top: 10px;
    padding-bottom: 15px;
}

.ad-box-contact .ad-box-footer a {
    display: block;
    width: 33%;
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 15px 10px;
    color: #929292;
}

.ad-box-contact .ad-box-footer a:hover {
    text-decoration: none
}

.ad-box-contact .ad-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 300;
}

.ad-box-contact .ad-box-footer .label {
    display: block;
    font-size: 1em;
    font-weight: 300;
    color: #929292;
}

.ad-box-contact .contact-details {
    padding: 4px 0 0;
    margin: 0;
    list-style: none;
    font-size: 0.875em;
    font-weight: 300;
}

.ads-box-contact .contact-details li i {
    width: 12px;
    text-align: center;
    margin-right: 3px;
}

.weather-box .main-box-body {
    padding: 0
}

.weather-box .current {
    text-align: center;
    padding-bottom: 40px;
    padding-top: 36px;
}

.weather-box .current .icon {
    display: block;
    float: left;
}

.weather-box .current .temp-wrapper {
    float: left;
    margin-left: 20px;
}

.weather-box .current .temp-wrapper .temperature {
    font-size: 3.6em;
    font-weight: 300;
    margin-top: -5px;
    text-align: left;
}

.weather-box .current .temp-wrapper .desc {
    margin-top: -4px;
    text-align: left;
}

.weather-box .next {
    background: #004e95;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
    color: #fff;
    padding: 20px;
}

.weather-box .next ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.weather-box .next ul li {
    float: left;
    width: 20%;
    text-align: center;
}

.weather-box .next .day {
    font-size: 1em;
    text-transform: uppercase;
}

.weather-box .next .icon {
    font-size: 2em
}

.weather-box .next .temperature {
    font-size: 1.4em
}

.weather-box-large .main-box-body {
    padding: 0
}

.weather-box-large .current {
    /* background: url(../../img/samples/new-york-snow.jpg) no-repeat; */
    background-size: cover;
    border-radius: 3px;
    background-clip: padding-box;
    min-height: 340px;
    color: #fff;
    padding: 20px 30px;
}

.weather-box-large .current h4 {
    margin-bottom: 30px
}

.weather-box-large .current .place {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.weather-box-large .current .place > i {
    margin-right: 5px
}

.weather-box-large .current .temp-out-wrapper {
    margin-top: 20px
}

.weather-box-large .current .icon {
    display: block;
    float: left;
    font-size: 6em;
}

.weather-box-large .current .temp-wrapper {
    float: left;
    margin-left: 30px;
}

.weather-box-large .current .temp-wrapper .temperature {
    font-size: 3.6em;
    font-weight: 300;
    margin-top: -5px;
    text-align: left;
}

.weather-box-large .current .temp-wrapper .desc {
    margin-top: -4px;
    text-align: left;
    font-weight: 600;
}

.feed ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.feed li {
    border-bottom: 2px solid #ebebeb;
    padding: 15px 0;
    font-size: 0.875em;
    position: relative;
}

.feed li:last-child {
    border-bottom: 0
}

.feed li .img {
    float: left;
    margin-right: 10px;
}

.feed li .img img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.feed li .title {
    padding-right: 55px;
    margin-left: 60px;
}

.feed li .title a {
    font-weight: 600
}

.feed li .post-time {
    color: #878787;
    padding-top: 4px;
    margin-left: 60px;
}

.feed li .time-ago {
    position: absolute;
    top: 16px;
    right: 0;
    color: #878787;
    font-size: 0.875em;
}

.feed li .photos {
    margin-left: 60px;
    margin-top: 10px;
}

.feed li .photos .item {
    float: left;
    margin-right: 2px;
}

.feed li .photos .item img {
    width: 50px;
    height: 50px;
}

.project-box .main-box-body {
    padding: 0
}

.project-box .project-box-header {
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
}

.project-box .project-box-header .name a {
    color: #fff;
    display: block;
    font-size: 1.4em;
    font-weight: 300;
    padding: 16px 15px;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    transition: background-color 0.1s ease-in-out 0s;
}

.project-box .project-box-header .name a:hover {
    text-decoration: none
}

.project-box .project-box-header.blue-bg .name a:hover {
    background: #68b3a5
}

.project-box .project-box-content {
    padding: 30px 20px 5px;
    text-align: center;
}

.project-box .project-box-content .chart {
    position: relative;
    text-align: center;
    font-weight: 300;
    font-size: 1em;
    width: 130px;
    height: 130px;
    display: block;
    margin: 0 auto;
    padding-top: 37px;
}

.project-box .project-box-content .chart canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.project-box .project-box-content .chart .percent {
    font-size: 2.1em;
    font-weight: 600;
}

.project-box .project-box-content .chart .lbl {
    display: block;
    font-size: 0.875em;
    margin-top: -7px;
}

.project-box .project-box-footer {
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
}

.project-box .project-box-footer a {
    border-right: 1px solid #eee;
    color: #929292;
    display: block;
    float: left;
    margin: 20px 0;
    padding: 5px 10px;
    text-align: center;
    width: 33.33%;
}

.project-box .project-box-footer a:last-child {
    border: none
}

.project-box .project-box-footer a:hover {
    text-decoration: none
}

.project-box .project-box-footer .value {
    display: block;
    font-size: 1.8em;
    font-weight: 300;
}

.project-box .project-box-footer .label {
    display: block;
    font-size: 0.875em;
    font-weight: 300;
    color: #929292;
}

.project-box .project-box-ultrafooter {
    background: #f7fafa;
    border-top: 1px solid #e9eef0;
    padding: 8px 15px;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
}

.project-box .project-box-ultrafooter .project-img-owner {
    border-radius: 50%;
    background-clip: padding-box;
    display: block;
    float: left;
    height: 33px;
    margin-right: 5px;
    overflow: hidden;
    width: 33px;
}

.project-box .project-box-ultrafooter .link {
    margin-top: 6px;
    color: #c1c5c6;
}

.project-box.emerald-box .project-box-footer a:hover {
    color: #004e95
}

.project-box.red-box .project-box-footer a:hover {
    color: #f4786e
}

.project-box.yellow-box .project-box-footer a:hover {
    color: #ffc107
}

.project-box.green-box .project-box-footer a:hover {
    color: #7FC8BA
}

.project-box.purple-box .project-box-footer a:hover {
    color: #9972B5
}

.project-box.gray-box .project-box-footer a:hover {
    color: #90a4ae
}

#search-results {
    padding: 0;
    margin: 23px 0 0;
    list-style: none;
}

#search-results li {
    margin-bottom: 25px
}

#search-results li h3.title {
    border: 0 none;
    font-weight: 600;
    margin: 0 0 3px;
    padding: 0;
    font-size: 1.3em;
}

#search-results li .meta-info {
    margin-left: 83px
}

@media (max-width: 767px) {
    #search-results li .meta-info {
        margin-left: 0
    }
}

#search-results li .search-img {
    margin-right: 8px;
    margin-top: 4px;
    float: left;
}

#search-results li .link-title {
    color: #004e95;
    font-size: 0.875em;
    overflow: hidden;
}

#search-results li .desc {
    font-size: 0.875em;
    margin-top: 3px;
    max-width: 750px;
}

#search-form .input-group-btn button {
    height: 46px;
    font-size: 16px;
}

#search-form .input-group-btn button > i {
    margin-right: 3px
}

#search-form .input-group .form-control {
    font-size: 16px
}

#timeline-grid {
    position: relative
}

#timeline-grid .item {
    border-radius: 3px;
    background-clip: padding-box;
    box-shadow: 1px 1px 2px 0 #CCCCCC;
    overflow: hidden;
    background: #fff;
}

#timeline-grid .item a {
    text-decoration: none
}

#timeline-grid .item a:hover {
    text-decoration: underline
}

#timeline-grid .item img {
    width: 100%
}

#timeline-grid .item .text {
    display: block
}

#timeline-grid .item .time,
#timeline-grid .tweet .user {
    font-style: italic;
    color: #666666;
}

#timeline-grid .tweet-wrapper {
    padding: 10px;
    line-height: 16px;
    font-size: 0.875em;
    line-height: 1.4;
}

#error-page {
    background-color: #eeeeee
}

#error-box {
    min-width: 280px;
    text-align: center;
    margin: 40px auto 20px;
}

#error-box h1 {
    margin-top: 40px;
    color: #605f5f;
    padding: 0;
}

#error-box p {
    margin: 20px 0
}

.pace .pace-progress {
    background: #004e95;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 2px;
    transition: width 1s;
}

.pace-inactive {
    display: none
}

.daterange-filter {
    background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #CCCCCC;
    cursor: pointer;
    padding: 5px 10px;
}

.main-box-header .daterange-filter {
    margin-top: 7px;
    margin-bottom: 7px;
}

.filter-block {
    margin-top: 7px
}

@media only screen and (max-width: 440px) {
    .filter-block {
        float: none !important;
        clear: both;
    }
}

.filter-block .form-group {
    margin-right: 10px;
    position: relative;
}

@media only screen and (max-width: 440px) {
    .filter-block .form-group {
        float: none !important;
        margin-right: 0;
    }
}

.filter-block .form-group .form-control {
    height: 34px
}

.filter-block .form-group .search-icon {
    position: absolute;
    color: #707070;
    right: 8px;
    top: 11px;
}

.filter-block .btn {
    margin-left: 5px
}

@media only screen and (max-width: 440px) {
    .filter-block .btn {
        display: block;
        float: none !important;
        margin-bottom: 15px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 440px) {
    #reportrange {
        clear: both;
        float: none !important;
        margin-bottom: 15px;
    }
}

.the-icons {
    font-size: 0.875em;
    line-height: 2;
}

.the-icons .glyphicon {
    width: 30px;
    text-align: center;
}

/*.tabs-wrapper .nav-tabs { margin-bottom: 15px }
.tabs-wrapper .tab-content {
    margin-bottom: 20px;
    padding: 0 10px;
}*/
.dropdown-example ul.dropdown-menu {
    display: block
}

svg {
    width: 100% !important
}

.top-page-ui {
    margin-top: -10px
}

#content-wrapper.email-inbox-wrapper {
    background-color: #fff;
    min-height: inherit;
    padding: 0 15px;
}

@media (max-width: 767px) {
    #content-wrapper.email-inbox-wrapper {
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media (max-width: 419px) {
    #content-wrapper.email-inbox-wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }
}

#email-box {
    background: #fff;
    margin: 0 -15px;
    padding: 15px 15px 0;
}

@media (max-width: 767px) {
    #email-box {
        margin-left: -8px;
        margin-right: -8px;
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media (max-width: 419px) {
    #email-box {
        margin-left: -5px;
        margin-right: -5px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

#email-box ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#email-header {
    padding-bottom: 10px
}

#email-header-title {
    font-size: 1.8em;
    font-weight: 300;
    position: absolute;
    width: 200px;
}

#email-header-tools {
    margin-left: 215px;
    position: relative;
    float: left;
}

@media (max-width: 991px) {
    #email-header-tools {
        margin-left: 0
    }
}

#email-header-pagination .pagination {
    padding: 0;
    margin: 0;
}

#email-header-pagination .num-items {
    margin-right: 10px;
    margin-top: 9px;
    font-size: 0.875em;
}

#email-navigation {
    width: 200px;
    position: absolute;
}

#email-navigation .email-compose-btn {
    display: block;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 0.875em;
    font-weight: 600;
}

#email-navigation .email-compose-btn > i {
    margin-right: 3px
}

#email-nav-items {
    margin: 15px 0 5px
}

#email-nav-items li {
    border-left: 3px solid #fff
}

#email-nav-items li a {
    color: #929292;
    font-size: 0.875em;
    display: block;
    padding: 8px 5px 8px 0;
}

#email-nav-items li a i {
    margin-right: 5px;
    text-align: center;
    width: 25px;
    color: #929292;
}

#email-nav-items li a:hover {
    color: #004e95;
    text-decoration: none;
}

#email-nav-items li.active {
    background: none repeat scroll 0 0 #e7ebee;
    border-left-color: #004e95;
    font-weight: 700;
}

#email-nav-items li.active a {
    color: #004e95
}

#email-nav-labels-wrapper {
    padding: 20px 0 10px
}

#email-nav-labels-wrapper .email-nav-headline {
    color: #777;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
}

#email-nav-labels {
    padding: 5px 0 0
}

#email-nav-labels li a {
    color: #929292;
    font-size: 0.875em;
    display: block;
    padding: 8px 5px 8px 0;
}

#email-nav-labels li a:hover {
    color: #004e95;
    text-decoration: none;
}

#email-nav-labels li a i {
    margin-right: 5px;
    text-align: center;
    width: 25px;
}

#email-content {
    position: relative;
    margin-left: 215px;
}

@media (max-width: 991px) {
    #email-content {
        margin-left: 0;
        margin-bottom: 35px;
        height: auto !important;
    }
}

#email-list {
    padding: 10px 0 1px
}

#email-list li {
    background-color: #f3f7fa;
    height: 46px;
    line-height: 46px;
    overflow: hidden;
    padding-left: 250px;
    padding-right: 10px;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #e7ebee;
}

@media (max-width: 767px) {
    #email-list li {
        height: auto;
        padding-left: 45px;
        line-height: 1.5;
        min-height: 46px;
    }
}

#email-list li:first-child {
    border-top: 1px solid #d3d9dd
}

#email-list li.unread {
    background-color: #fff
}

#email-list li.unread .name,
#email-list li.unread .message .subject {
    font-weight: 700
}

#email-list li.unread .meta-info {
    background-color: #fff
}

#email-list li .chbox {
    height: 24px;
    left: 10px;
    position: absolute;
    top: 11px;
    width: 24px;
}

@media (max-width: 767px) {
    #email-list li .chbox {
        top: 50%;
        margin-top: -12px;
    }
}

#email-list li .star {
    left: 38px;
    position: absolute;
    top: 11px;
}

@media (max-width: 767px) {
    #email-list li .star {
        display: none
    }
}

#email-list li .star a:before {
    color: #ffc107;
    content: "\f006";
    display: block;
    font-family: FontAwesome;
    font-size: 1.25em;
    font-style: normal;
    font-weight: normal;
    height: 24px;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    width: 24px;
}

#email-list li .star a:hover {
    text-decoration: none
}

#email-list li .star a:hover:before {
    color: #ffa000
}

#email-list li .star a.starred:before {
    content: "\f005"
}

#email-list li .name {
    font-size: 0.875em;
    left: 70px;
    position: absolute;
    width: 170px;
}

@media (max-width: 767px) {
    #email-list li .name {
        left: 45px;
        line-height: 1.5;
        padding-top: 8px;
    }
}

#email-list li .message {
    font-size: 0.875em
}

@media (max-width: 767px) {
    #email-list li .message {
        margin-top: 24px;
        padding-bottom: 8px;
    }
}
@media (max-width: 767px) {
    .back-center {
        padding-top: 10px !important;
        float: none !important;
        text-align: center !important;
    }
}


#email-list li .message > .label {
    margin-right: 2px
}

@media (max-width: 767px) {
    #email-list li .message > .label {
        display: none
    }
}

#email-list li .message > .body {
    color: #777;
    font-weight: 400;
}

#email-list li .meta-info {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #f3f7fa;
}

@media (max-width: 767px) {
    #email-list li .meta-info {
        padding-left: 5px;
        padding-right: 5px;
    }
}

#email-list li .meta-info .attachment {
    color: #929292;
    display: inline-block;
    font-size: 1.125em;
    margin-right: 5px;
}

@media (max-width: 767px) {
    #email-list li .meta-info .attachment {
        display: none
    }
}

#email-list li .meta-info .date {
    display: inline-block;
    font-size: 0.875em;
    text-align: right;
    width: 60px;
}

@media (max-width: 767px) {
    #email-list li .meta-info .date {
        width: 50px;
        margin-top: 8px;
    }
}

#email-list li:hover,
#email-list li:hover .meta-info {
    cursor: pointer;
    background-color: #eff3f6;
}

#email-header-mobile {
    height: 36px;
    line-height: 36px;
    margin-bottom: 10px;
}

#email-header-mobile #email-header-title-mobile {
    font-size: 1.4em;
    font-weight: 300;
}

#email-detail {
    position: relative;
    margin-left: 215px;
}

@media (max-width: 991px) {
    #email-detail {
        margin-left: 0;
        margin-bottom: 35px;
    }
}

#email-detail-subject,
#email-new-title {
    border-bottom: 1px solid #e7ebee;
    border-top: 1px solid #e7ebee;
    font-weight: 300;
    line-height: 1.5;
    margin-top: 5px;
    padding: 15px 0;
}

#email-new-title {
    margin-bottom: 20px
}

#email-detail-subject .subject,
#email-new-title .subject {
    display: block;
    float: left;
    font-size: 1.5em;
    margin-right: 10px;
}

#email-detail-subject .label {
    display: inline-block;
    float: left;
    margin-top: 4px;
    padding-bottom: 5px;
    padding-top: 5px;
}

#email-detail-sender {
    border-bottom: 1px solid #e7ebee;
    height: 70px;
    padding: 10px 0 10px 60px;
    position: relative;
}

@media (max-width: 767px) {
    #email-detail-sender {
        padding-left: 0
    }
}

#email-detail-sender .picture {
    left: 0;
    position: absolute;
    top: 10px;
}

#email-detail-sender .picture img {
    border-radius: 50%;
    background-clip: padding-box;
    width: 50px;
}

#email-detail-sender .users {
    padding-top: 6px
}

#email-detail-sender .users .from .name {
    float: left;
    font-weight: 700;
    margin-right: 5px;
}

#email-detail-sender .users .from .email {
    float: left
}

#email-detail-sender .users .to {
    color: #777
}

#email-detail-sender .tools {
    margin-top: -18px;
    position: absolute;
    right: 0;
    top: 50%;
}

#email-detail-sender .tools .date {
    color: #777;
    float: left;
    font-size: 0.875em;
    line-height: 36px;
    margin-right: 10px;
}

#email-detail-sender .tools .btns {
    float: right
}

#email-detail-sender .tools .btns .dropdown-menu {
    left: auto;
    right: 0;
}

#email-body {
    padding: 18px 30px 18px 0
}

#email-body p {
    margin: 0 0 10px
}

#email-body p:last-child {
    margin-bottom: 0
}

#email-body ul,
#email-body ol {
    list-style: inherit;
    padding-left: 20px;
    margin-bottom: 10px;
}

#email-body ul li,
#email-body ol li {
    padding: 2px 0;
    line-height: 1.4;
}

#email-body h1 {
    padding-left: 0;
    margin-bottom: 15px;
    margin-top: 25px;
}

#email-body h2 {
    margin-top: 20px;
    padding: 0;
}

#email-body h3 {
    border: none;
    padding-left: 0;
    margin-top: 15px;
}

#email-detail-attachments {
    border-top: 1px solid #e7ebee;
    padding: 18px 0;
}

#email-detail-attachments #email-attachments-header .headline {
    float: left;
    font-size: 0.875em;
}

#email-detail-attachments #email-attachments-header .headline > span {
    font-weight: 600
}

#email-detail-attachments #email-attachments-header .headline > b {
    font-weight: 600
}

#email-detail-attachments #email-attachments-header .tools {
    float: left;
    font-size: 0.875em;
    margin-left: 3px;
}

#email-detail-attachments #email-attachments-content ul li {
    background: none repeat scroll 0 0 #e7ebee;
    border: 1px solid #e7ebee;
    border-radius: 3px;
    background-clip: padding-box;
    float: left;
    font-size: 0.875em;
    margin: 10px 10px 10px 0;
    overflow: hidden;
    padding: 0;
}

#email-detail-attachments #email-attachments-content ul li .img {
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    height: 87px;
    overflow: hidden;
    width: 130px;
    text-align: center;
}

#email-detail-attachments #email-attachments-content ul li .img img {
    width: 100%
}

#email-detail-attachments #email-attachments-content ul li .img > i {
    font-size: 5.7em;
    padding: 10px 0;
}

#email-detail-attachments #email-attachments-content ul li .name {
    display: block;
    padding: 4px 6px;
}

#email-detail-attachments #email-attachments-content ul li > a {
    display: block;
    padding: 0 6px 5px;
}

#email-new {
    position: relative;
    margin-left: 215px;
}

@media (max-width: 991px) {
    #email-new {
        margin-left: 0;
        margin-bottom: 55px;
    }
}

#email-new-header .form-group label,
#email-new-body label {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 9px;
    text-align: right;
}

@media (max-width: 767px) {
    #email-new-header .form-group label,
    #email-new-body label {
        padding-bottom: 4px
    }
}

#email-new-body label {
    text-align: left;
    padding-left: 5px;
}

#email-new-footer {
    margin-top: 20px;
    margin-bottom: 20px;
}

#email-new .form-control-s2 {
    width: 100%
}

#email-new .wysiwyg-editor {
    max-height: 220px;
    height: 220px;
}

#email-new .select2-container.select2-container-multi .select2-choices .select2-search-field input {
    height: 32px
}

/* Code By Muhammed */

p.formrow {
    margin: 0;
    display: block;
    clear: both;
    float: none;
}

td[colspan].linesbg {
    display: none;
}

td input[type=radio], td input[type=checkbox] {
    display: inline-block;
    margin-right: 5px;
}

.formrow_cp {
    display: none;
}

.page-content {
    padding: 15px;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
}

body:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.jsp-hidden {
    display: none;
}

#primary-nav-col {
    display: none;
}

@media (min-width: 992px) {
    #primary-nav-col {
        display: block !important;
    }
}

.yellow-text {
    color: #faa519;
}

a.yellow-text {
    text-decoration: none;
}

.collapsiblelink {
    float: left;
}

.collapsiblelink img {
    float: right;
}

/* Fixes for FEBA Issue */
#iframetop {
    position: absolute;
    z-index: 10000;
    display: none;
}

.virtual_keyboard img,
img.virtual-keyboard,
img#virtual-keyboard,
img#virtual_keyboard_password,
img#virtual_keyboard {
    position: absolute;
    right: 1em;
    top: 50%;
    width: auto;
    height: auto;
}

.virtual_keyboard_two img,
img.virtual-keyboard-two,
img#virtual-keyboard-two,
img#virtual_keyboard_password-two,
img#virtual_keyboard_two {
	position: absolute;
    right: 1em;
    top: 40%;
    width: auto;
    height: auto;

}

.keypaddiv {
    position: absolute;
    z-index: 99999999;
    background-color: #CCCCCC;
}

.datepicker.dropdown-menu {
    transform: none;
    top: auto;
    opacity: 1;
    transition: none;
    display: none;
}

.bcum-bold {
    font-size: 1.1em;
    color: #004e95;
    font-weight: bold;
}

.bcum-bold .icon {
    display: inline-block;
    margin-right: 5px;
}

.panel-heading.current {
    background: #999;
}

.panel-heading.current:hover {
    background: #888;
}

.panel-heading.current a {
    color: #eee;
}

.panel-heading.current a:hover {
    color: #fff;
    background-color: #666;
}

.form-text-box {
    display: block;
    height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;

    line-height: 14px;

    background-color: #fff;
    background-image: none;
    border: 2px solid transparent;

    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    border-radius: 3px;
    background-clip: padding-box;
    box-shadow: none;
    font-size: 12px;
    color: #768082;
}

.switch-button-label {
    float: left;

    font-size: 10pt;
    cursor: pointer;
}

.switch-button-label.off {
    color: #adadad;
}

.switch-button-label.on {
    color: #0088CC;
}

.switch-button-background {
    float: left;
    position: relative;

    background: #ccc;
    border: 1px solid #aaa;

    margin: 1px 10px;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    cursor: pointer;
}

.switch-button-button {
    position: absolute;

    left: -1px;
    top: -1px;

    background: #FAFAFA;
    border: 1px solid #aaa;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 999;
    bottom: 0;
    overflow-y: scroll;
}

.popup-modal > *:first-child {
    position: absolute;
    top: 16%;
    left: 5%;
    right: 5%;
    background-color: #fff;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
}

.form-box-content div.redbg, .form-box-content div.greenbg, .form-box-content div.yellowbg, .form-box-content div.orangebg {
    /*margin-top: -18px;*/
}

.redbgwithwidth, .greenbgwithwidth, .yellowbgwithwidth, .orangebgwithwidth {
    display: none;
}

div.redbg .fa, div.greenbg .fa, div.yellowbg .fa, div.orangebg .fa {
    color: white;
    display: block;
    
    margin-top: -0.5em;
    
    font-size: 2em;
    line-height: 1em;
    
    position: absolute;
    
    top: 50%;
    left: -1.2em;
}

div.redbg span, div.greenbg span, div.yellowbg span, div.orangebg span {
    display: none;
}

div.redbg:hover span, div.greenbg:hover span, div.yellowbg:hover span, div.orangebg:hover span {
    display: inline;
}

div.redbg, div.greenbg, div.yellowbg, div.orangebg {
    font-size: 12px;
    font-weight: bold;

    border: 1px solid;
    border-left-width: 3em;

    color: #333;
    
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.2em;
    
    position: relative;

}

div.redbg img, div.greenbg img, div.yellowbg img, div.orangebg img {
    display: none;
}

div.redbg {
    border-color: #b6261c;
    background-color: #fdf3f2;
}

div.greenbg {
    border-color: #23b644;
    background-color: #f2fdf4;
}

div.yellowbg, div.orangebg {
    border-color: #b69e21;
    background-color: #fdfbf2;
}

table .slno {
    width: 10px !important;
}

@media (max-width: 991px) {
    #secondary-nav-col {
        display: none;
        position: fixed;
        right: 0;
        z-index: 9999;
    }
}

@media (min-width: 992px) {
    #secondary-nav-col {
        display: block !important;
    }
}

.pagination .form-control {
    padding: 7px;
}

.check-box-fix {
    display: inline-block;
    margin-right: 7px !important;
}

/* Material Forms  */

.material-form-group {
    display: flex;
    height: 48px;
}

.material-label {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 15px, 0) scale(1);
    transform-origin: left top;
    transition: 240ms;
}

.material-form-group.focused .material-label {
    opacity: 1;
    transform: scale(0.75);
}

.material-control {
    align-self: flex-end;
    border: 0;
    border-bottom: 1px solid #e3e4e8;

    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0px;
}

.material-control::-webkit-input-placeholder {
    color: transparent;
    transition: 240ms;
}

.material-control:focus::-webkit-input-placeholder {
    transition: none;
}

.material-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* Responsive Table */

@media screen and (max-width: 750px) {
    .responsive table {
        border: 0;
    }

    .responsive table thead,
    .responsive table .table-header {
        display: none;
    }

    .responsive table tr {
        margin-bottom: 10px;
        display: block;

        border: 1px solid #444;
    }

    .responsive table tr:after {
        content: "";
        display: table;
        clear: both;
    }

    .responsive table tr:before {
        display: block;
    }

    .responsive table td {
        box-sizing: border-box;
        display: block;

        float: left;
        clear: left;

        width: 100% !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 1px solid #ccc;
    }

    .responsive .table-hover tr:hover td {
        color: #262525;
        background-color: transparent;
    }

    .responsive .table-hover tr:hover td a {
        color: #004e95;
    }

    .responsive table td:last-child {
        border-bottom: 0;
    }

    .responsive table td:before {
        content: attr(data-label);
        /*float: left;*/
        text-transform: uppercase;
        font-weight: bold;
    }

    .responsive table td.no-header:before {
        display: none;
    }

}

/* Custom ICONS */

/* @font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icomoon.eot?5v0o57');
    src: url('../../fonts/icomoon.eot?5v0o57#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?5v0o57') format('truetype'),
    url('../../fonts/icomoon.woff?5v0o57') format('woff'),
    url('../../fonts/icomoon.svg?5v0o57#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
} */

[class^="fednet-icon-"], [class*=" fednet-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    font-size: 26px;
    display: inline-block;
    margin-top: -2px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fednet-icon-continue:before {
    content: "\e900";
}

.fednet-icon-delete:before {
    content: "\e901";
}

.fednet-icon-download:before {
    content: "\e902";
}

.fednet-icon-failed:before {
    content: "\e903";
}

.fednet-icon-find:before {
    content: "\e904";
}

.fednet-icon-go:before {
    content: "\e905";
}

.fednet-icon-lookup:before {
    content: "\e906";
}

.fednet-icon-ok:before {
    content: "\e907";
}

.fednet-icon-search:before {
    content: "\e908";
}

.fednet-icon-submit:before {
    content: "\e909";
}

.fednet-icon-success:before {
    content: "\e90a";
}

.select2-container .select2-choice .select2-arrow {
    background: #fff !important;
    border-left: 0;
}

/* Go Button */
.go-btn button i {
    font-size: 22px;
    font-weight: bold !important;
}

.go-btn .select2-container .select2-choice > .select2-chosen {
    font-weight: bold;
}

.go-btn button span {
    display: none;
}

.go-btn button {
    border-left: 0;
    width: 40px;
}

.go-btn .select2-container .select2-choice {
    border-color: #faa519 !important;
    border-radius: 0 !important;
}

.hd-btn i.fa {
    font-size: 19px !important;
}

/* Padding Fix */
.no-left-padding {
    padding-left: 0 !important;
}

.no-right-padding {
    padding-right: 0 !important;
}

.inline-block {
    display: inline-block;
}

.p-l-10 {
    padding-left: 10px;
}

.input-append input {
    border-right: 0;
}

.input-append .input-group-btn .btn,
.input-append .input-group-btn .hd-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* Virtual Key Board */

#keypadclear {
    clear: both;
}

#keypadclear p {
    clear: both;
}

#keypadclear p span {
    float: left;
}

#keypadclear p input {
    color: #000;
    background-color: #fcfcfc !important;
    display: inline-block;
    padding: 0.5em;
    margin: 0.1em;
    min-width: 3em;
    line-height: 2em;
    outline: 0;
    font-weight: bold;
    font-size: 15px;
    border: 1px solid;
}

.keypaddiv {
    background-color: #fff;
    border: 1px solid;
    padding: 0.5em;
}

#listing {
    padding-top: 1em;
    clear: both;
}

#listing input {
    color: #fff;
    background-color: #004e95 !important;
    display: inline-block;
    padding: 0.5em;
    margin: 0.1em;
    min-width: 20%;
    line-height: 1em;
    border: 0;
    outline: 0;
    font-weight: bold;
    font-size: 13px;
}

#CLOSE_KEYPAD {
    margin-left: auto !important;
    float: right;
    color: red;
}

.tac {
    max-height: 250px;
    overflow-y: scroll;
}

.form-box-header .name .link-button {
    color: #fff;
    text-decoration: underline;
}

.min-space {
    width: 0px !important;
}

/* profile page css */

.profile-bg-1 {
    background-color: #ebebeb;
    width: inherit;
    line-height: 26px;
}

.profile-bg-2 {
    /*background-color: #faa519;*/
    width: inherit;
}

.icon-style .fa {
    font-size: 18px;
    color: #004e95;
}

.mail-icon .fa-envelope-o {
    font-size: 16px;
    color: #004e95;
}

.mobile-icon .fa-mobile {
    font-size: 25px;
    color: #004e95;
}

.p-r-6 {
    padding-right: 6px;
}

.pos-3 span {
    position: relative;
    top: -3px;
}

.pos-3 button {
    position: relative;
    top: -3px;
}

#header-navbar .nav > li > button.nav-btn {
    position: relative;
    background: transparent;
    border: 0;
}

.widget-head {
    display: none;
}

@media (max-width: 767px) {

    #col-left .panel-heading {
        background: #ffffff !important;
    }

    #col-left .panel-heading :hover,
    #col-left .panel-heading :active {
        background: #ffffff !important;
    }

    #col-left .panel-heading h4 a {
        color: rgb(114, 114, 114) !important;
        padding-left: 25px !important;

    }

    #col-left .panel-heading h4 a:hover,
    #col-left .panel-heading h4 a:active {
        color: #004e95 !important;
        padding-left: 25px !important;
    }

    #col-left .panel-sub-heading a {
        color: rgb(114, 114, 114) !important;
        padding-left: 30px !important;
    }

    #col-left .panel-sub-heading a:hover,
    #col-left .panel-sub-heading a.active {

        color: #004e95 !important;
    }

    .profile-bg-1 {
        background-color: #ffffff;

    }

    .col-border {
        border: 1px solid #cccccc;
        line-height: 24px !important;
    }

    .row-border {
        border: 1px solid #666666;
    }

    .virtual_keyboard img,
    img.virtual-keyboard,
    img#virtual-keyboard,
    img#virtual_keyboard_password,
    img#virtual_keyboard {
        display: none;
    }

    .login-password img {
        display: none;
    }
    .overflow-margin {
        margin-top: 0px !important;
    }
    .page-content{
        padding-right: 6px !important;
    }
}

/* desktop menu */
#col-left .panel-heading {
    background: #004e95 !important;
}

#col-left .panel-heading :hover {
    background-color: #f0f0f0;
}

#col-left .panel-heading h4 a {
    color: #fff !important;
}

#col-left .panel-heading h4 a:hover,
#col-left .panel-heading h4 a:active {
    color: #004e95 !important;

}

#col-left .panel-sub-heading .panel-sub-title a {
    color: #666 !important;
}

#col-left .panel-sub-heading .panel-sub-title a:hover {
    color: #999898 !important;
}

#col-left .panel-sub-heading .panel-sub-title a:active {
    color: #004e95 !important;
}

/* Dashboard */
.table.color-1 th,
.account-balance-box .account-balance-summary.color-1 {
    background-color: #c792bd;
    color: #fff;
}

.account-balance-box .account-balance-summary.color-1 > .title > a.account-balance-button > i {
    color: #c792bd;
}

.table.color-2 th,
.account-balance-box .account-balance-summary.color-2 {
    background-color: #a4d05f;
    color: #fff;
}

.account-balance-box .account-balance-summary.color-2 > .title > a.account-balance-button > i {
    color: #a4d05f;
}

.table.color-3 th,
.account-balance-box .account-balance-summary.color-3 {
    background-color: #89d2f2;
    color: #fff;
}

.account-balance-box .account-balance-summary.color-3 > .title > a.account-balance-button > i {
    color: #89d2f2;
}

.account-balance-box .account-balance-summary .collapsibles > a,
.account-balance-box .account-balance-summary .collapsibles > h5 {
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    display: block;

    position: relative;
    top: 10px;

    margin: 10px 0;

}

.dashboard-tiles .main-box-footer {
    display: none;
    margin-top: -10px;
}

.dashboard-tiles .main-box-footer {
    overflow: auto;
    max-height: 200px;
}

.dashboard-tiles .main-box-footer th {
    text-align: center;
}

.dashboard-links.dropdown-menu {
    top: auto;
    left: auto;
}

/* Dashboard */

/* Navigation Desktop */
@media (min-width: 991px) {
    body #primary-nav-col li section > div {
        -webkit-transition: all 0.5s; /* Safari */
        transition: all 0.5s;

        position: relative;
        opacity: 0;
    }

    body #primary-nav-col li section.visible {
        background: rgba(0, 0, 0, 0.2);

        display: block;
        position: fixed;

        top: 75px;
        left: 21%;
        right: 0;
        bottom: 0;

        overflow: auto;

    }

    body #primary-nav-col li section.visible > div {
        background: #fff;
        width: 250px;
    }

    body #primary-nav-col li section.visible > div.vis {
        opacity: 1;
    }
}

@media (max-width: 991px) {
    body #primary-nav-col li section.visible {
        display: block;
    }
    .m-l-210{
        margin-left: 0px !important;

    }
}

/* End Navigation Desktop */

/* Navigation Helper */
#col-left .nav-search[value=""] + ul {
    display: none;
}

#col-left .nav-search + ul {
    position: absolute;

    margin: 0;
    left: 10px;
    right: 10px;

    z-index: 999999;

    border-radius: 0;

    overflow: auto;
    max-height: 350px;
}

#col-left .nav-search + ul a {
    color: #666;
    display: block;
}

#col-left .nav-search + ul li {
    display: none;
}

#col-left .nav-search + ul li {
    border: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
    border-radius: 0;
}

#col-left .nav-search:focus + ul {
    display: block;
}

/* Navigation Helper */


/* Scrollbar Fix  */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #faa519;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6fb743;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #faa519;
}

/* Scrollbar Fix */

div.img-responsive img {
    width: 100%;
    display: block;
    margin: 0 auto;
}

label span {
    float: right;
}

.change-photo a {
    position: relative;
    top: -15px;
    float: right;
    background: #eeeeee;
}

.mail-border {
    border: 1px solid #cccccc;
    padding: 12px;
}

.overflow-margin {
    margin-top: 25px;
}

/* Dashbaord Table Scroll Fix */

@media screen and (min-width: 750px) {
    .summary-table-box {
        max-height: 150px; /* Just for the demo          */
        overflow-y: auto; /* Trigger vertical scroll    */
        overflow-x: hidden; /* Hide the horizontal scroll */
    }
}

/* Dashbaord Table Scroll Fix */

/* Overlay */
#guide-overlay {
    background: rgba(0, 0, 0, 0.6);
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99998;
}

.expose {
    z-index: 99999;
    position: relative;
}

/* Overlay */

/* Help Cursor */
.helpcursor .gt-help {
    position: absolute;
    max-width: 100px;
    font-size: 14px;
    line-height: 1.2em;
    height: auto;
    z-index: 99;
    color: #fff;
}

.helpcursor .gt-help span {
    /* background: url(../img/arrow-left.png) no-repeat center center; */
    height: 28px;
    width: 40px;
    float: left;
}

.helpcursor .gt-help.left {
    left: -180px;
    text-align: right;
}

.helpcursor .gt-help.left span {
    right: -50px;
    top: 0px;
}

.helpcursor .gt-help.right {
    right: -180px;
}

.helpcursor .gt-help.right span {
    /* background: url(../img/arrow-right.png) no-repeat center center; */
    left: -50px;
    top: 0px;
}

.helpcursor .gt-help.bottom {
    top: 100%;
    left: 50%;
    min-width: 180px;
}

.helpcursor .gt-help.bottom span {
    /* background: url(../img/arrow-bottom.png) no-repeat center center; */
    left: 0;
    top: 0;
}

.helpcursor .gt-help.top {
    top: -100%;
    left: 50%;
}

.helpcursor .gt-help.top span {
    /* background: url(../img/arrow-top.png) no-repeat center center; */
    left: -50px;
    bottom: 0px;
}

/* Help Cursor */

.m-l-210 {
    margin-left: 21%;
}


/* Responsive Fixes */
@media screen and (max-height: 600px) {
	.input-group .form-control {
		position: static;
	}
}
/*Responsive Fixes */


/* Hide Top bar in low heights */
@media screen and (max-height: 200px) {
   #page-wrapper {
       margin-top: 0;
   }

   #theme-wrapper {
   		border-right: 0;
   }

   #header-navbar {
       display: none;
   }

   #footer-bar {
       display: none;
   }

   .border-div {
   		display: none;
   }
}

@media (max-width: 767px) {
   .select2-search {
   		display: none;
   }
   .select2-offscreen, .select2-offscreen:focus {display: none;}
}
@media (max-width: 767px) {
    .footer-links {
        text-align: center !important;
        float: none !important;
    }
    #popupshp {
        padding-left: 0px !important;
        margin-left: 0px !important;
    }
}
/* Hide Top bar in low heights */

/* Radio Label Fix */
.radio-label label {
	float: none;
}
/* Radio Label Fix */


/* Height Fix to span and div form-controls */
div.form-control,
span.form-control {
	height: auto;
}
/* Height Fix to span and div form-controls */


/* Size Fix in Dashboard */
#dashboard-contents {
	margin-left: 0;
	margin-right: 0;
}
/* Size Fix in Dashboard */

/* Menu Scroll */
.nav.nav-pills.nav-stacked {
    padding-bottom: 20px;
}
/* Menu Scroll */


/* logout button */
.logout-button a{
    color: #ffffff !important;
}
.logout-button a:hover {
    color: #004e95 !important;
}
.logout-button a:active {
    color: #004e95 !important;
}
.logout-button a:focus {
    color: #004e95 !important;
}

.logout-button .hd-btn:hover a{
    color: #004e95 !important;
}


#header-navbar .nav > li > a.call-btn > i {
    font-size: 27px !important;
}

.colon-hidden {
    display: none;
}


/* terms and condition */
.terms-overflow {
    max-height: 600px;
    overflow-y: scroll;
}
.terms-top-padding {
    padding-top: 5%;
}


/* Marquee */
.marquee {
	font-size: 13px;
}
/* Marquee */


/* Width Helpers */
.w-min-250 {
    min-width: 250px;
}
/* Width Helpers */

/* Email */
#email_new .nav-tabs .glyphicon:not(.no-margin) {
    margin-right: 10px;
}

#email_new .tab-pane .list-group-item:first-child {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

#email_new .tab-pane .list-group-item:last-child {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

#email_new .tab-pane .list-group .checkbox {
    display: inline-block;
    margin: 0px;
}

#email_new .tab-pane .list-group input[type="checkbox"] {
    margin-top: 2px;
}

#email_new .tab-pane .list-group .glyphicon {
    margin-right: 5px;
}

#email_new .tab-pane .list-group .glyphicon:hover {
    color: #FFBC00;
}

#email_new a.list-group-item.read {
    color: #222;
    background-color: #F3F3F3;
}

#email_new hr {
    margin-top: 5px;
    margin-bottom: 10px;
}

#email_new .nav-pills > li > a {
    padding: 5px 10px;
}

#email_new .ad {
    padding: 5px;
    background: #F5F5F5;
    color: #222;
    font-size: 80%;
    border: 1px solid #E5E5E5;
}

#email_new .ad a.title {
    color: #15C;
    text-decoration: none;
    font-weight: bold;
    font-size: 110%;
}

#email_new .ad a.url {
    color: #093;
    text-decoration: none;
}

#email_new .fa-edit:before, #email_new .fa-pencil-square-o:before {
    content: "\f044";
    padding-right: 5px;
    position: relative;
    display: block;
    font-size: 15px;
    top: 2px;
}
#email_new .btn-danger {
    color: #fff;
    background-color: #6fb743;
    border: 1px solid #6fb743;
}

#email_new .nav-pills>li.active>a, #email_new .nav-pills>li.active>a:focus, #email_new .nav-pills>li.active>a:hover {
    color: #6fb743;
    border-left: 6px solid #6FB744;
}

#email_new .list-group-item.active>.badge, #email_new .nav-pills>.active>a>.badge {
    color: #fff;
    background-color: #6fb743;
}

#email_new .nav-pills>li.active>a, #email_new .nav-pills>li.active>a:focus, #email_new .nav-pills>li.active>a:hover {
    background-color: #fff;
}

#email_new .nav-pills .fa {
    padding-right: 7px;
    color: #777777;
}

#email_new .nav-pills>li>a:focus, #email_new .nav-pills>li>a:hover {
    color: #6fb743;
    border-left: 6px solid #6FB744 !important;
}

#email_new .nav-pills>li>a>.badge:hover, #email_new .nav-pills>li>a>.badge:active, #email_new .nav-pills>li>a>.badge:focus {
    color: #fff;
    background-color: #6fb743;
}
#email_new .checkbox label, #email_new .radio label {
    min-height: 15px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}
#email_new .nav-pills>li>a {
    color: #777777;
    border-left: 6px solid #ffffff;

}
#email_new .list-group .badge {
    color: #777777;
    background-color: #ffffff;
}

#email_new .badge {
    float: right;
    background-color: #ffffff;
    color: #777777;
}

#email_new .tab-content table tr td {
    padding-top: 13px;
}

#email_new .tab-content table tr td .checkbox input[type=checkbox]{
    margin-left: 0px;
}

#email_new .checkbox {
    margin-top: 0px !important;
}

#email_new .table {
    border: 1px solid #cccccc;
}

#email_new .action-buttons .btn {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#email_new .action-checkbox .checkbox label{

    margin-bottom: 5px;

}

#email_new .btn-default .caret {
    border-top-color: #333333;
}

.text-bold {
	font-weight: bold;	
}
/* email_new */


/* navigation Helpr */
.navigation-helper-container { position: relative; }
.navigation-helper-container .fa-times { position: absolute; right: 1.7em; top: 1.7em; display: none; }
/* navigation Helpr */

@media (max-width: 767px) {
	.drop-down-fix .input-group {
		width: 100%;
		table-layout: fixed;
	}
	
	.drop-down-fix .input-group .input-group-btn{
		width: auto;
	}
}

/* contact-us drope down */
.contact-no  .p1{
    font-size: 11px;
    padding-right: 8px;
}
.contact-no .p2{
    font-size: 11px;
    font-weight: bold;
    padding-left: 8px;
    position: relative;
}
.contact-email a {
    text-align: center !important;
    font-size: 11px !important;
    font-weight: bold !important;
    padding-left: 0px !important;
}
/* contact-us drope down */
.top-40{
    top: 40% !important;
}
.left-2 {
    position: relative;
    left: -2px;
}
.margin-top-7 {
    margin-top: 7%;
}

.abs-pos {
	position: absolute !important;	
}

@media (max-width: 767px) { 
.text-right {
	text-align: left;
}
    .credit-margin a img{
        margin-left: 80px !important;
    }
}
.credit-margin a{
    margin-left: 80px;
}


@media print {
  a[href]:after {
    content: none !important;
  }
}


.text-red * {
	color: #e64f3f !important;
}

.text-red a {
	color: #004e95 !important;
}

.hidden-rt { display: none; }