@charset "utf-8";

    .new_svcount ul li {

    list-style: none!important;

}

.new_banr {

    width: 100%;

    float: left;

    padding: 0;

    margin-top: 92px;

}

.act_option {

    position: absolute;

    top: 40px;

    width: 292px;

    height: 58px;

    background: #fff;

    right: 0;

    border: 1px solid #fe8100;

    display: none;

}

.act_option .acnt_bttn {

    width: 130px;

    margin: 10px;

    float: left;

    border: 1px solid #fe8100;

    padding: 0;

    color: #1d1d1d;

    font-size: 13px;

    cursor: pointer;

    transition: all 1s;

    text-align: center;

    height: 35px;

    line-height: 35px;

    text-decoration: none;

}

.act_option .acnt_bttn:hover {

    background: #fe8100;

    color: #fff;

}

.act_option .acnt_bttn:nth-child(2) {

    margin-left: 0;

}

body {

    font-family: Verdana, Geneva, sans-serif;

}

.ionicon {

    font-family: Ionicons;

    /* src: url(../fonts/ionicons.eot?v=2.0.0#iefix) format("embedded-opentype"), url(../fonts/ionicons.ttf?v=2.0.0) format("truetype"), url(../fonts/ionicons.woff?v=2.0.0) format("woff"), url(../fonts/ionicons.svg?v=2.0.0#Ionicons) format("svg"); */

    font-weight: 400;

    font-style: normal;

}

a:focus {

    outline: 0;

}

.clear {

    clear: both;

}

.nopads {

    padding: 0!important;

}

.pointer {

    cursor: pointer;

}

.mob_menu_wrap {

    display: none;

}

.pading5 {

    padding-left: 5px;

}

.txtsavheigt {

    font-size: 11px;

}

.fedbckicon {

    width: 100%;

    margin: 0;

    padding: 0;

    font-size: 90px;

    color: #2c318d;

}

.fedbcktext {

    width: 100%;

    margin: 0;

    padding: 0;

    font-size: 16px;

    color: #2c318d;

}

.fdbckimg img {

    width: 100%;

    margin: 0;

    padding: 0;

}

.feedback5 {

    padding-top: 45px!important;

}

.fedbcktextmob {

    display: none;

}

.logo {

    padding: 0;

}

.logo a {

    display: block;

}

.logo a img {
    margin: 2px 0 7px 0;
    padding: 0;
    max-width: 100%;
    width: 151px;
}

#head_top {

    position: fixed;

    top: 0;

    z-index: 1002;

    width: 100%;

    background: #fff;

}

#head_menu {

    position: fixed;

    top: 54px;

    z-index: 1000;

    width: 100%}

.low_zindx {

    z-index: 999!important;

}

.phoneicon {

    float: left;

    margin: 0;

    padding: 0;

    width: 22px;

    height: 25px;

    /* background-image: url(../images/phoneicon.jpg); */

}

.bord_leftt {

    border-left: 1px solid #e6e5e5;

}

.tollfrebox {

    height: 36px;

    padding-top: 5px;

}

.phonenum .pading5 a {

    font-size: 14px;

    color: #022056!important;

}

.credit-logo {

    top: 20px;

}

.pading5 a:hover {

    text-decoration: none;

}

.lnksbox {

    margin: 10px 0 0 0;

}

.lnksbox ul {

    margin: 0;

    padding: 0;

}

.lnksbox ul li {

    list-style: none;

    float: left;

    margin: 0;

    padding: 0;

    color: #313131;

    font-size: 12px;

    padding: 0 12px;

    border-right: 1px solid #ccc;

}

.lnksbox ul li:last-child {

    border: none;

}

.lnksbox ul li a {

    text-decoration: none;

    color: #313131;

    font-size: 12px;

}

.lnksbox ul li a:hover {

    text-decoration: underline;

    color: #363794;

}

.curntlnk {

    text-decoration: underline!important;

    color: #363794!important;

}

.elogin-btn {

    width: auto;

    height: 40px;

    background-color: #363794;

    border-radius: 5px;

    margin-top: 7px;

    cursor: pointer;

    float: right;

    padding-right: 10px;
    margin-right: 12px;
}

.open_acc_abslt {

    width: 250px;

    height: auto;

    background: #fe8100;

    border-radius: 5px;

    position: absolute;

    z-index: 1001;

    right: 0;

    display: none;

    padding: 0 0;

    cursor: pointer;

}

.login_box {

    width: 250px;

    height: auto;

    background: #363794;

    border-radius: 5px;

    position: absolute;

    z-index: 1001;

    top: 11px;

    right: 0;

    display: none;

    padding: 15px 0;

}

.login_box .log_head {

    padding: 5px 10px;

    color: #fff;

}

.login_box input {

    margin: 5px 10px;

    padding: 0 5px;

    height: 25px;

    width: 230px;

    font-size: 13px;

    line-height: 25px;

    background: 0 0;

    border: none;

    border-bottom: 1px solid #5a5b8f;

    color: #fff;

}

.login_box button {

    height: 35px;

    width: 80px;

    font-size: 13px;

    line-height: 35px;

    border: none;

    background: #fe8100;

    margin: 5px 10px;

    text-align: center;

    float: left;

}

.linkked {

    float: left;

    color: #fff;

    font-size: 12px;

    line-height: 35px;

    display: block;

    width: 120px;

    padding: 5px 0;

}

.linkked a {

    color: #fff;

}

.i-logn {

    color: #fff;

    font-size: 25px;

    padding: 0 5px;

    float: left;

}

.i-txt {

    color: #fff;

    font-size: 14px;

    float: left;

    padding-top: 10px;

}

.log_form iframe {

    width: 250px;

    height: auto;

}

.headrlnks {

    padding-right: 0;

}

.navbg {

    background-color: #d9d8e6;

    position: relative;

    z-index: 1000;

}

.navdiv {

    height: 40px;

}

.navdiv1 {

    background-color: #9d99cb;

    color: #161813;

    text-align: center;

    font-size: 14px;

    padding: 10px 0;

}

.trangleicn {

    float: left;

    margin: 0 0 0 -5px;

    padding: 0;

    width: 6px;

    height: 12px;

    /* background-image: url(../images/traingle.png); */

    position: absolute;

    top: 14px;

    right: -6px;

}

.navdiv3 {

    background-color: #fe8100;

    padding: 0;

    position: relative;

    cursor: pointer;

    transition: all 1s;

}

.navdiv3:hover {

    background-color: #ea8319;

}

.accnt-i {

    float: left;

    font-size: 28px;

    padding: 0 15px;

    color: #fff;

}

.accnt-txt {

    float: left;

    font-size: 13px;
    font-weight: bold;

    color: #161813;

    padding-top: 10px;

}

.birdsimg {

    float: left;

    margin: 0 0 0 -5px;

    padding: 0;

    width: 25px;

    height: 10px;

    /* background-image: url(../images/birds.png); */

    position: absolute;

    top: 5px;

    right: 10px;

}

.navdiv2 ul {

    margin: 0;

    padding: 0;

    width: 100%;

    display: inline-block;

}

.navdiv2 ul li {

    list-style: none;

    float: left;

    width: 130px;

    margin: 0;

    padding: 6px 0 0 0;

    height: 40px;

    cursor: pointer;

}

.navdiv2 ul li:first-child {

    width: 140px;

    padding-left: 25px;

}

.navdiv2 ul li:last-child {

    width: 166px;

    padding-left: 10px;

    border-right: none;

}

.navdiv2 ul li:nth-child(2) {

    width: 150px;

    padding-left: 10px;

}

.navdiv2 ul li:nth-child(3) {

    width: 110px;

    padding-left: 15px;

}

.dropdwn {

    float: left;

    font-size: 14px;

    color: #000;

    padding-left: 5px;

    padding-top: 5px;

}

.li-name {

    float: left;

    font-size: 13px;

    padding-top: 5px;

    padding-left: 2px;
    font-weight: bold;

}

.difrnt a .li-name, .fisrtab a {

    color: #333;

}

.li-icon {

    float: left;

    margin: 0 0 0 -5px;

    padding: 0;

    width: 26px;

    height: 25px;

    /* background-image: url(../images/menu-icns.png); */

}

.li-first {

    background-position: 0 0;

}

.li-second {

    background-position: -30px 0;

}

.li-third {

    background-position: -57px 0;

}

.li-fourth {

    background-position: -85px 0;

}

.li-fifth {

    background-position: -115px 0;

}

.li-sixth {

    background-position: -148px 0;

}

.li-seven {

    background-position: -178px 0;

}

.li-eight {

    background-position: -206px 0;

}

.navdiv2 ul li:first-child {

    width: 180px;

    padding-left: 25px;

}

.navdiv2 ul li:last-child {

    width: 125px;

    padding-left: 10px;

    border-right: none;

}

.navdiv2 ul li:nth-child(2) {

    width: 150px;

    padding-left: 10px;

}

.navdiv2 ul li:nth-child(3) {

    width: 110px;

    padding-left: 15px;

}

.navdiv2 ul li:hover {

    background-color: #fff;

    height: 40px;

    z-index: 200;

    position: relative;

}

.sub-li {

    position: relative;

}

.navdiv2 ul li:hover>.inrdiv {

    display: block;

}

.inrdiv {

    position: absolute;

    top: 40px;

    z-index: 1000;

    background-color: #fff;

    margin: 0;

    display: none;

    left: 0;

    right: 0;

    padding: 10px 10px 10px 10px;

    cursor: default;

    transition: all 1s;

}

.fst_ul {

    width: 970px;

    margin: 0 0 0 -162px!important;

}

.sec_ul {

    width: 970px;

    margin: 0 0 0 -342px!important;

}

.thr_ul {

    width: 970px;

    margin: 0 0 0 -492px!important;

}

.for_ul {

    width: 970px;

    margin: 0 0 0 -551px!important;

}

.four_ul {

    width: 970px;

    margin: 0 0 0 -551px!important;

}

.active_tab {

    background-color: #f9f7f1;

}

.kno_more {

    width: 100px;

    height: 35px;

    line-height: 35px;

    text-align: center;

    background-color: #363794;

    border-radius: 5px;

    color: #fff;

    position: absolute;

    bottom: 8px;

    right: 10px;

    cursor: pointer;

}

.kno_m_a {

    right: 120px;

}

.kno_m_b {

    right: 10px;

}

.kno_more .aply-butn {

    width: auto;

    display: block;

    margin: auto;

    margin-top: 0;

    margin-bottom: 0;

    height: 35px;

}

.kno_more .aply-butn a {

    position: relative;

    display: inline-block;

    padding: 0 5px;

    border: 3px solid #343794;

    color: #fff;

    font-size: 30px;

    -webkit-transition: color .5s;

    transition: color .5s;

    border-radius: 5px;

    background: #343794;

    font-size: 14px;

    text-decoration: none;

    display: block;

    text-align: center;

}

.kno_more .aply-butn a:before {

    content: '';

    position: absolute;

    top: 0;

    left: 0;

    width: 100%;

    height: 100%;

    background: #484b9e;

    z-index: -1;

    -webkit-transform: scaleX(0);

    -ms-transform: scaleX(0);

    transform: scaleX(0);

    -webkit-transition: -webkit-transform .5s;

    transition: transform .5s;

}

.kno_more .aply-butn a:hover:before {

    -webkit-transform: scaleX(1);

    -ms-transform: scaleX(1);

    transform: scaleX(1);

}

.kno_more .aply-butn a:hover {

    color: #fff;

    z-index: 2;

}

.no_active_tab li {

    background-color: none!important;

    border: none!important;

}

.note_below {

    width: 300px;

    position: absolute;

    bottom: 25px;

    font-size: 11px;

}

.equity-tab {

    margin: 0;

    padding: 0;

    list-style-type: none;

    width: 50%!important;

    float: left;

    height: auto;

}

.depos_tab li, .equity-tab li, .insurance-tab li, .loan-tab li, .nri-tab li {

    width: 100%!important;

    float: left;

    padding: 0!important;

    height: 45px!important;

    line-height: 45px!important;

}

.depos_tab li.active_tab, .equity-tab li.active_tab, .insurance-tab li.active_tab, .loan-tab li.active_tab, .nri-tab li.active_tab {

    border: 1px solid #ccc;

    border-right: 2px solid #f9f7f1;

    margin-right: -2px;

    position: relative;

    z-index: 10000;

}

.depos_tab li.hovu:hover, .equity-tab li.hovu:hover, .insurance-tab li.hovu:hover, .loan-tab li.hovu:hover, .nri-tab li.hovu:hover {

    background-color: #f2f2f5!important;

}

.depos_tab li.active_tab:hover, .equity-tab li.active_tab:hover, .insurance-tab li.active_tab:hover, .loan-tab li.active_tab:hover, .nri-tab li.active_tab:hover {

    background-color: #f9f7f1!important;

}

.depos_tab li .hide_me, .equity-tab li .hide_me, .insurance-tab li .hide_me, .loan-tab li .hide_me, .nri-tab li .hide_me {

    display: none;

}

.new_svcount ul li a {

    color: #000;

}

.depos_tab li ul li, .equity-tab li ul li, .insurance-tab li ul li, .loan-tab li ul li, .nri-tab li ul li {

    float: none;

    clear: both;

}

.equity-conten {

    height: 260px;

    border: 1px solid #ccc;

    width: 50%;

    float: left;

    background-color: #f9f7f1;

    margin-left: -1px;

    z-index: -1;

}

.insurance-tab {

    list-style-type: none;

    width: 55%!important;

    float: left;

    height: auto;

}

.someClass {

    height: 155px;

    border: 1px solid #ccc;

    width: 45%;

    float: left;

    background-color: #f9f7f1;

    margin-left: -1px;

    z-index: -1;

}

.someClass li {

    list-style: none;

    padding-left: 10px!important;

}

.someClass li .saving_img {

    width: 70px;

    height: 70px;

    border-radius: 100%;

    background: #fc6;

    margin: 40px auto 0 auto;

}

.someClass li ul {

    margin: 0;

    padding: 0;

    font-weight: 400;

}

.someClass li li {

    font-size: 13px;

    color: #5a5a5a;

    line-height: 18px;

    padding-bottom: 10px!important;

    list-style-type: disc;

}

.someClass li .featruree, .someClass li .note_me {

    margin-top: 15px!important;

}

.someClass li .note_me ul {

    margin: 0;

    padding: 0;

}

.someClass li .note_me li {

    font-size: 12px;

    line-height: 15px;

    padding-bottom: 8px!important;

    list-style-type: disc;

    padding-left: 4px!important;

}

.someClass li li:hover {

    height: auto;

}

.someClass li .int_rate {

    font-weight: 700;

    font-size: 62px;

    color: #fe8100;

    text-align: center;

}

.someClass h2 {

    font-size: 18px;

    color: #363794;

}

.someClass ul {

    width: 100%;

    margin: 0;

    padding: 0;

    height: auto;

}

.someClass ul li {

    width: 100%!important;

    margin: 0;

    padding: 0;

    height: auto;

    font-size: 12px;

    cursor: text;

}

.someClass ul li:hover {

    background-color: #f9f7f1;

    cursor: default;

}

.equity-conten li {

    list-style: none;

    padding-left: 10px!important;

}

.equity-conten li .saving_img {

    width: 70px;

    height: 70px;

    border-radius: 100%;

    background: #fc6;

    margin: 40px auto 0 auto;

}

.equity-conten li ul {

    margin: 0;

    padding: 0;

    font-weight: 400;

}

.equity-conten li li {

    font-size: 13px;

    color: #5a5a5a;

    line-height: 18px;

    padding-bottom: 10px!important;

    list-style-type: disc;

}

.equity-conten li .featruree, .equity-conten li .note_me {

    margin-top: 15px!important;

}

.equity-conten li .note_me ul {

    margin: 0;

    padding: 0;

}

.equity-conten li .note_me li {

    font-size: 12px;

    line-height: 15px;

    padding-bottom: 8px!important;

    list-style-type: disc;

    padding-left: 4px!important;

}

.equity-conten li li:hover {

    height: auto;

}

.equity-conten li .int_rate {

    font-weight: 700;

    font-size: 62px;

    color: #fe8100;

    text-align: center;

}

.depos_tab li:hover, .equity-tab li:hover, .insurance-tab li:hover, .loan-tab li:hover, .nri-tab li:hover {

    background-color: #f9f7f1!important;

}

.text_center {

    text-align: center;

}

.black_overl {

    position: fixed;

    background: #000;

    opacity: .5;

    width: 100%;

    height: 100%;

    z-index: 100;

    display: none;

    top: 0;

}

.close_container .close_menu_pop {

    width: 14px;

    float: right;

    margin-bottom: 10px;

    margin-right: 10px;

    cursor: pointer;

}

.right_brdr {

    border-right: 1px dotted #ccc;

    min-height: 240px;

    height: auto;

}

.showw_mee {

    display: block!important;

}

.tabhead img {

    display: block;

    margin: auto;

    width: 100%}

.tabhead .note_me {

    font-size: 9px;

}

.equity-conten h2 {

    font-size: 18px;

    color: #363794;

}

.accnt_dv .li-second {

    margin: 10px;

}

.accnt_dv .li-first {

    margin: 10px;

}

.accnt_dv .li-third {

    margin: 10px!important;

}

.accnt_dv .inli-icon {

    float: left;

    margin: 9px 20px 0 5px;

    padding: 0;

    width: 26px;

    height: 25px;

    /* background-image: url(../images/menu-icns.png); */

}

.tabhead h1 {

    margin: 0;

    padding: 10px 0;

    color: #073c9c;

    font-size: 14px;

    cursor: text;

}

.equity-conten ul {

    width: 100%;

    margin: 0;

    padding: 0;

    height: auto;

}

.equity-conten ul li {

    width: 100%!important;

    margin: 0;

    padding: 0;

    height: auto;

    font-size: 12px;

    cursor: text;

}

.equity-conten ul li:hover {

    background-color: #f9f7f1;

    cursor: default;

}

.ban_container {

    width: 100%;

    height: 300px;

    position: relative;

}

.banner_wrapper {

    width: 100%;

    height: 300px;

    position: relative;

    overflow: hidden;

}

.only_mob {

    display: none;

}

.pagi_wrpr {

    position: absolute;

    top: 0;

    left: -34px;

    padding-top: 15px;

}

.pagi_wrpr .pagin .activue {

    background: #333 none repeat scroll 0 0!important;

}

.pagi_wrpr .pagin li {

    float: left;

    margin-left: 6px;

    width: 10px;

    height: 10px;

    background: #fa6841 none repeat scroll 0 0;

    border-radius: 6px;

    display: block;

    cursor: pointer;

}

.no_mar, body, ul {

    margin: 0!important;

}

.bannrtitle {

    font-size: 24px;

    color: #fff;

    background-color: #000;

    width: 250px;

    height: 65px;

    display: block;

    position: absolute;

    top: 100px;

    left: 120px;

    line-height: 65px;

    text-align: center;

    opacity: .5;

}

.chngcolor {

    color: #fa8522;

}

.main-title {

    padding: 15px 0 13px 0;

}

.main-title h1 {

    font-size: 22px;

    color: #000;

    padding: 0;

    margin: 0;

}

.highlight {

    color: #f68121;

}

.circlebox {

    width: 85px;

    height: 85px;

    margin: auto;

    display: flex;
    justify-content: space-around;
    align-items: center;

    border-radius: 100%;

    /* background-image: url(../images/featuresimg.png); */

}
.circlebox > img {
    width: 42px;
}

.features-box {

    border-left: 1px solid #e1e2ef;

    height: 280px;

    transition: all 2s;

}

.features-box:hover .innr-title h2 {

    font-size: 16px;

    color: #1d1d1d!important;

}

.features-box:hover .innrlist ul li {

    font-size: 14px;

    color: #1d1d1d!important;

}

.features-box1, .features-box2, .features-box3, .features-box4 {

    transition: all 1s;

}

.features-box1:hover {

    background-color: #a4def6;

    color: #1d1d1d!important;

}

.features-box2:hover {

    background-color: #f58464;

    color: #1d1d1d!important;

}

.features-box3:hover {

    background-color: #ff9f00;

    color: #1d1d1d!important;

}

.features-box4:hover {

    background-color: #6bcfc7;

    color: #1d1d1d!important;

}

.featr1 {

    background-position: -13px -10px;

    background-color: #a4def6;

}

.featr2 {

    background-position: -130px -15px;

    background-color: #f58464;

}

.featr3 {

    background-position: -247px -20px;

    background-color: #ff9f00;

}

.featr4 {

    background-position: -343px 0;

    background-color: #6bcfc7;

}

.innr-title {

    padding-top: 15px;

    height: 40px;

}

.innr-title h2 {

    font-size: 14px;

    color: #1d1d1d;

    padding: 0;

    margin: 0;

    font-weight: 700;

    text-align: center;

}

.innrbox {

    width: 100%;

    display: inline-block;

}

.leftxt {

    text-align: left!important;

}

.features-box:first-child {

    border-left: none;

    padding-left: 0;

}

.innrlist ul {

    margin: 0;

    padding: 0 0 0 30px!important;

    height: 127px;

}

.innrlist ul li {

    float: none;

    margin: 0;

    padding: 0;

    clear: both;

    /* list-style-image: url(../images/li-star.png); */

    font-size: 12px;

    color: #6c6c6c;

}

.morebutn {

    font-size: 12px;

    color: #6c6c6c;

    padding: 0;

    display: inline-block;

    float: right;

}

.morebutn a {

    text-decoration: underline;

}

.acc-butn {

    width: 215px;

    display: block;

    margin: auto;

    margin-top: 15px;

    margin-bottom: 20px;

}

.acc-butn a {

    position: relative;

    display: inline-block;

    padding: 5px 5px;

    border: 3px solid #343794;

    color: #fff;

    font-size: 30px;

    -webkit-transition: color .5s;

    transition: color .5s;

    border-radius: 5px;

    background: #343794;

    font-size: 16px;

    text-decoration: none;

    display: block;

    text-align: center;

}

.acc-butn a:before {

    content: '';

    position: absolute;

    top: 0;

    left: 0;

    width: 100%;

    height: 100%;

    background: #484b9e;

    z-index: -1;

    -webkit-transform: scaleX(0);

    -ms-transform: scaleX(0);

    transform: scaleX(0);

    -webkit-transition: -webkit-transform .5s;

    transition: transform .5s;

}

.acc-butn a:hover:before {

    -webkit-transform: scaleX(1);

    -ms-transform: scaleX(1);

    transform: scaleX(1);

}

.acc-butn a:hover {

    color: #fff;

    z-index: 2;

}

.carebox {

    width: 100px;

    background-color: #ececf5;

    float: right;

    cursor: pointer;

    margin-top: -25px;

    border: 1px solid #355083;

}

.care_fix {

    position: fixed;

    bottom: 0;

    right: 18px;

    z-index: 26;

}

.care-icon {

    float: left;

    margin: 0 0 0 -8px;

    padding: 0;

    width: 25px;

    height: 25px;

    /* background-image: url(../images/care.png); */

}

.care {

    position: relative;

}

.feedbackk {

    position: fixed;

    bottom: -1px;

    right: 0;

    box-shadow: 0 0 4px #ccc;

    display: none;

    z-index: 1003;

}

.care-para {

    float: left;

    font-size: 14px;

    color: #000;

    padding: 5px 0;

}

.quickbox {

    /* background: url(../images/bgquick.jpg) 0 0; */

    width: 100%;

    background-repeat: repeat;

    border-top: 1px solid #000;

    padding: 0 15px;

    z-index: 100000;

}

.quickbox .main-title {

    padding: 20px 0 10px 0;

}

.quickbox .main-title h2 {

    font-size: 22px;

    color: #000;

    padding: 0;

    margin: 0;

}

.quicklinks {

    width: 100%;

    display: inline-block;

}

.quicklinks ul {

    margin: 0;

    padding: 10px 0;

    display: inline-block;

}

.quicklinks ul li {

    margin: 5px 0;

    color: #585656;

    list-style: none;

    float: left;

}

.quicklinks a {

    text-decoration: none;

    text-decoration: none;

    margin: 0;

    color: #585656;

    font-size: 12px;

    line-height: 25px;

    margin-top: 5px;

}

.aline {

    color: #666;

    padding: 0 4px;

}

.quicklinks ul li:last-child {

    border-right: none;

}

.quickbox .dropdwn, .quickbox .dropdwn2 {

    display: none;

}

.copyrght-box {

    width: 100%;

    display: inline-block;

    border-top: 1px solid #000;

    padding-bottom: 40px;

}

.copyrght {

    font-size: 11px;

    color: #5b5a5a;

    height: 25px;

    line-height: 45px;

}

.copyrght a {

    text-decoration: underline;

}

.netwrkig ul {

    margin: 0;

    float: right;

}

.netwrkig ul li {

    margin: 0;

    padding: 0 10px;

    float: left;

    border-right: none;

    padding: 0;

}

.netwrkig ul li a {

    text-decoration: none;

    color: #355083;

    font-size: 24px;

    border-right: none;

}

.ralign {

    text-align: right;

}

.browsocial {

    width: 100%;

    padding: 10px 0 0 0;

    margin: 0;

    display: inline-block;

}

.browsrnote {

    color: #797979;

    font-size: 11px;

    padding: 0;

    padding-top: 10px;

}

.scll-top {

    position: fixed;

    bottom: 0;

    right: 130px;

    z-index: 1010;

    width: 40px;

    background-color: #ececf5;

    cursor: pointer;

    margin-top: 0;

    border: 1px solid #355083;

    font-size: 21px;

    text-align: center;

    color: #355083;

    z-index: 1000;

}

.bord_leftt .elogin-btn {

    display: none;

}

.bord_leftt .open_acc_abslt {

    display: none;

}

.navdiv1 .ionicon {

    display: none;

}

.loancls .main-title {

    padding: 20px 0 40px 20px;

}

.call_me_lady {

    /* background-image: url(../images/call_us_lady.jpg); */

    background-size: cover;

    background-position: center 0;

    background-repeat: no-repeat;

}

.call_me_lady .featur_data {

    position: relative;

    height: 280px;

}

.call_me_lady .innr-title {

    position: absolute;

    bottom: 8px;

    height: auto;

    left: 0;

    right: 0;

    margin: auto;

}

.call_me_lady .innr-title h2 {

    font-size: 18px;

    text-align: center;

    line-height: 25px;

    color: #1d1d1d;

    text-shadow: 2px 2px 4px #fff;

}

.call_me_lady .eml {

    display: none;

    padding-top: 15px;

    border-top: 1px dotted #1d1d1d;

    margin-top: 15px;

}

.call_me_lady .eml a {

    color: #23527c;

    font-size: 14px;

}

.features-box {

    border-left: 1px solid #e1e2ef;

    height: 280px;

    transition: all 2s;

}

.features-box4 {

    border: none!important;

}

.features-box:hover .innr-title h2 {

    font-size: 15px;

    color: #1d1d1d!important;

}

.features-box4:hover .innr-title {

    top: 50%;

    transform: translateY(-50%);

    bottom: auto;

}

.features-box4:hover .innr-title h2 {

    font-size: 15px;

    word-break: break-all;

    line-height: 25px;

    text-shadow: 0 0 #000;

}

.features-box:hover .innrlist ul li {

    font-size: 12px;

    color: #1d1d1d!important;

}

.features-box1, .features-box2, .features-box3, .features-box4 {

    transition: all 1s;

}

.features-box1:hover {

    background-color: #a4def6;

    color: #000!important;

}

.features-box2:hover {

    background-color: #f58464;

    color: #000!important;

}

.features-box3:hover {

    background-color: #ff9f00;

    color: #000!important;

}

.features-box4:hover {

    background-color: #a4def6;

    color: #000!important;

    background-image: none;

}

.features-box4:hover .eml {

    display: block;

}

.innr-title {

    padding-top: 15px;

    height: 40px;

}

.innr-title h2 {

    font-size: 14px;

    color: #1d1d1d;

    padding: 0;

    margin: 0;

    font-weight: 700;

    text-align: center;

}

.innrbox {

    width: 100%;

    display: inline-block;

}

.difrnt:hover {

    height: 40px!important;

}

.innrlist ul {

    margin: 0;

    padding: 10px 0 0 30px!important;

    height: 127px;

}

.first_hide_cust {

    display: none;

}

.exc_cust {

    font-size: 13px;

    padding: 2px 10px;

    color: #404040;

}

.cross_btn {

    float: right;

    cursor: pointer;

    color: #fff;

}

.feedbackk {

    width: 300px;

    background: #fff;

    border-radius: 5px;

    border-left: 1px solid #2c318d;

    z-index: 1002;

}

.left-togle {

    float: left;

    padding: 0 0 0 5px;

    color: #777;

    cursor: pointer;

}

.control-togle {

    float: left;

    margin: 10px;

    position: relative;

}

.right-togle {

    float: left;

    padding: 0 10px 0 0;

    color: #585858;

    cursor: pointer;

}

.dwn-line {

    border-bottom: 1px solid #858585;

}

.circle-box {

    width: 40px;

    float: left;

    margin: -8px 0 0 0;

}

.circle-anmi {

    width: 15px;

    height: 15px;

    background-color: #2c318d;

    border-radius: 100%;

    position: absolute;

    right: 0;

}

.date-mrkt {

    float: left;

    color: #777;

    font-size: 11px;

    height: 20px;

    line-height: 20px;

    border-left: 1px solid #2a2a2a;

    padding: 0 0 0 10px;

}

.shre-mrkt {

    float: left;

    color: #8b8a8a;

    padding: 0 15px 10px 15px;

}

.act-togle {

    color: #2c318d;

}

.conten-data {

    display: inline-block;

    padding: 5px 15px 0 15px!important;

}

.font_aswm {

    font-family: FontAwesome;

}

.form-feedback {

    margin: 0;

    padding: 0;

    float: left;

}

.form-feedback .pad-10lt {

    padding: 0 0 0 10px;

}

.form-feedback .pad-10rg {

    padding: 0 15px 6px 15px;

}

.form-feedback h1 {

    font-size: 15px;

    background: #2c318d none repeat scroll 0 0;

    color: #fff;

    padding: 10px;

    margin: 0 0 10px 0;

}

.form-feedback1 h1 {

    font-size: 15px;

    background: #2c318d none repeat scroll 0 0;

    color: #fff;

    padding: 10px;

    margin: 0 0 10px 0;

}

.form-feedback .cap-a input[type=text] {

    width: 100%;

    font-size: 13px;

    line-height: 32px;

    height: 32px;

    padding-left: 10px;

    border: 1px solid #7e7878;

    margin: 0 0;

    color: #272626;

}

input:focus, select:focus, textarea:focus {

    outline: medium none;

}

.form-feedback textarea {

    width: 100%;

    font-size: 13px;

    padding-left: 10px;

    padding-top: 5px;

    border: 1px solid #7e7878;

    margin: 0 0;

    resize: none;

    height: 100px;

    color: #272626;

}

.form-feedback .cp-bxc {

    padding: 0 0 0 10px;

    margin: 0;

}

.form-feedback .c-imggs {

    width: 120px;

    float: left;

    margin: 0;

    padding: 0;

    height: 32px;

}

.form-feedback .c-imggs img {

    height: 120%;

    width: 100%;

    margin: 0 0;

}

.form-feedback .c-refshhs {

    width: 26px;

    float: left;

    margin: 0;

    padding: 4px 8px;

    cursor: pointer;

    color: #000;

    font-size: 29px;

    line-height: 28px;

}

.form-feedback .c-refshhs .refresh {

    font-size: 32px;

}

.form-feedback .c-refshhs img {

    width: 100%;

    margin: 0;

    padding: 0;

    height: 25px;

}

.form-feedback .fr_uns {

    margin: 10px 0;

}

.form-feedback .task-s {

    width: 120px;

    font-size: 18px;

    text-align: center;

    border: 1px solid #363794;

    color: #fff;

    line-height: 30px;

    font-size: 15px;

    transition: all 1s ease 0s;

    margin: auto;

    background-color: #363794;

    cursor: pointer;

}

.form-register .paddg-zero {

    padding-right: 0;

}

.form-register .cp-i {

    min-height: 34px;

}

.form-register .styleRms {

    width: 100%;

    height: 32px;

    margin: 0 0;

    overflow: hidden;

    /* background: #fff url(../images/drop-dwn.png) no-repeat scroll right center; */

}

.tex_boxselect {

    /* background: transparent url(../images/drop-dwn.png) no-repeat scroll right center!important; */

    border: 1px solid #cfd6d9;

}

.form-register .styleRms select {

    background: transparent none repeat scroll 0 0;

    width: 100%;

    line-height: 32px;

    height: 32px;

    -moz-appearance: none;

    font-size: 13px;

    color: #919191;

    border: 1px solid #efe9e9;

    padding: 0 0 0 5px;

}

.form-register .styleRms select option {

    border: medium none;

}

.careers_info p {

    color: #272626;

    font-size: 13px;

    padding: 15px 0 0 20px;

    margin: 0;

}

.missionval {

    display: flex;

    flex-direction: column;

    justify-content: center;

    height: 330px;

    overflow: hidden;

    padding-left: 0;

}

.missionval img {

    display: block;

    margin: auto;

    width: 100%}

.missiondata .careertxt {

    color: #1d1d1d;

    font-size: 16px;

    margin: 0;

    padding: 0;

    font-weight: 700;

    padding-top: 10px;

    padding-bottom: 5px;

}

.missiondata .careertxt:first-child {

    padding-top: 0;

}

.missiondata .careerul {

    padding-left: 17px;

}

.securitydata .careerul {

    padding-left: 17px;

    line-height: 2;

    padding-top: 10px;

}

.ban_container .bx-wrapper .bx-controls-direction a {

    display: none;

}

.form-feedback .image {

    height: 70px;

    width: 196px;

}

.error_class {

    /* background: #f9f9f9 url(../images/wrong.png) no-repeat scroll right center!important; */

    color: red;

}

.correct_msg1 {

    border: 1px solid green!important;

}

.correct_class {

    border: 1px solid green!important;

    /* background: #f9f9f9 url(../images/correct.png) no-repeat scroll right center!important; */

}

.feedbackk {

    width: 450px;

    background: #fff;

    border-radius: 5px;

    border-left: 1px solid #2c318d;

}

.scalebox img {

    width: 100%;

    margin: 0;

    padding: 0;

}

.smilebox ul {

    width: 100%;

    margin: 0;

    padding: 0;

    list-style: none;

    display: inline-block;

}

.smilebox ul li {

    float: left;

    margin: 0;

    padding: 0;

    list-style: none;

}

.circlass {

    width: 77px;

    height: 77px;

    float: left;

    margin: 0;

    padding: 0;

    background-color: red;

    cursor: pointer;

    /* background-image: url(../images/facee.png); */

}

.nextcl {

    float: right;

}

.breset input {

    width: 140px!important;

}

.form-feedback .pad-10rg {

    padding: 0 0 15px 0;

}

.shre-mrkt {

    height: 254px;

    position: relative;

}

.butoncontrl {

    position: absolute;

    bottom: 0;

    left: 0;

    right: 0;

    width: 96%;

    margin: 0 2%}

.exc_cust {

    padding: 2px 10px 12px 0;

}

.sldimge img {

    height: 51px;

    width: 419px;

    margin: 0;

    padding: 0 0 0 9px;

}

.scalebox {

    margin-top: -39px;

}

.scalediv .ui-widget-content {

    border: none!important;

    background: #7ea700;

}

.scalediv .ui-widget-header {

    background: #dadada;

}

.scalediv .ui-slider .ui-slider-handle {

    width: 1.5em;

    height: 1.5em;

}

.n-slde {

    display: none;

}

.form-feedback textarea {

    height: 70px;

}

.sldimge_smile img {

    height: auto;

    width: 419px;

    margin: 0;

    padding: 0 0 0 9px;

}

.scalebox_smile {

    margin-top: -56px;

    padding-left: 26px;

    padding-right: 33px;

    width: 100%}

@media only screen and (min-width:280px) and (max-width:360px) {

    .scalebox_smile {

    margin-top: -26px;

    padding-left: 13px;

    padding-right: 11px;

}

}@media only screen and (min-width:360px) and (max-width:480px) {

    .scalebox_smile {

    margin-top: -26px;

    padding-left: 13px;

    padding-right: 11px;

}

}@media only screen and (min-width:480px) and (max-width:577px) {

    .scalebox_smile {

    margin-top: -28px;

    padding-left: 15px;

    padding-right: 15px;

}

}@media only screen and (min-width:578px) and (max-width:613px) {

    .scalebox_smile {

    margin-top: -32px;

    padding-left: 17px;

    padding-right: 18px;

}

}@media only screen and (min-width:612px) and (max-width:623px) {

    .scalebox_smile {

    margin-top: -33px;

    padding-left: 17px;

    padding-right: 20px;

}

}@media only screen and (min-width:624px) and (max-width:661px) {

    .scalebox_smile {

    margin-top: -34px;

    padding-left: 18px;

    padding-right: 20px;

}

}@media only screen and (min-width:662px) and (max-width:723px) {

    .scalebox_smile {

    margin-top: -37px;

    padding-left: 19px;

    padding-right: 22px;

}

}@media only screen and (min-width:724px) and (max-width:752px) {

    .scalebox_smile {

    margin-top: -39px;

    padding-left: 20px;

    padding-right: 23px;

}

}@media only screen and (min-width:752px) and (max-width:767px) {

    .scalebox_smile {

    margin-top: -39px;

    padding-left: 20px;

    padding-right: 22px;

    width: 100%}

}@media only screen and (min-width:768px) and (max-width:991px) {

    .scalebox_smile {

    margin-top: -37px;

    padding-left: 20px;

    padding-right: 22px;

    width: 100%}

}@media only screen and (min-width:992px) and (max-width:1120px) {

    .scalebox_smile {

    margin-top: -47px;

    padding-left: 22px;

    padding-right: 26px;

    width: 100%}

}@media only screen and (min-width:1121px) and (max-width:1199px) {

    .scalebox_smile {

    margin-top: -47px;

    padding-left: 23px;

    padding-right: 27px;

    width: 100%}

}@media only screen and (min-width:1200px) and (max-width:2480px) {

    .scalebox_smile {

    margin-top: -56px;

    padding-left: 25px;

    padding-right: 34px;

    width: 100%}

}.scalediv_smile .ui-widget-content {

    border: none!important;

    background: #7ea700;

}

.scalediv_smile .ui-widget-header {

    background: #dadada;

}

.scalediv_smile .ui-slider .ui-slider-handle {

    width: 1.5em;

    height: 1.5em;

}

.n-slde-smile {

    display: none;

}

.noicte {

    width: 80px;

    height: 40px;

    line-height: 40px;

    background-color: #f68121;

    border-radius: 0 5px 5px 0;

    cursor: pointer;

    color: #fff;

    font-weight: 700;

    text-align: center;

    position: fixed;

    top: 335px;

    left: 0;

    z-index: 999;

    border-top: 1px solid #fff;

    border-right: 1px solid #fff;

    border-bottom: 1px solid #fff;

}

.close_templt {

    display: none;

    font-size: 13px;

    right: 236px;

    position: fixed;

    top: 323px;

    color: #fff;

    cursor: pointer;

    z-index: 10012;

    background-color: #333e63;

    width: 25px;

    line-height: 22px;

    height: 25px;

    text-align: center;

    border-radius: 100%;

    border: 1px solid #fff;

}

.transprntprev {

    display: none;

    background: #324057;

    width: 100%;

    height: 100%;

    opacity: .8;

    position: fixed;

    z-index: 1001;

    top: 0;

    left: 0;

    z-index: 10010;

}

.transprntdv {

    display: none;

    background: #fff;

    width: 50%;

    margin: auto;

    margin-top: 15px;

    position: fixed;

    top: 321px;

    left: 0;

    right: 0;

    border: 2px solid #fff;

    z-index: 10011;

}

.trans-cont {

    border: 1px solid #e1e2ef;

    margin: 10px;

    font-size: 12px;

    padding: 30px 20px;

}

.abtuslbl {

    width: 100px;

    height: 40px;

    line-height: 40px;

    background-color: #f68121;

    border-radius: 5px 0 0 5px;

    cursor: pointer;

    color: #fff;

    font-weight: 700;

    text-align: center;

    position: fixed;

    top: 335px;

    right: 0;

    z-index: 1000;

}

.noticediv {

    padding-top: 1%;

    padding-bottom: 1%;

    border: 1px dashed #f97d1d;

    margin-bottom: 10px;

    background-color: #fff;

    border-radius: 10px;

    margin-top: 10px;

}

.newnotice {

    line-height: 35px;

    background-color: #f68121;

    border-radius: 5px 0 0 5px;

    cursor: pointer;

    color: #fff;

    font-weight: 700;

    text-align: center;

    width: 35px;

    height: 45px;

}

.newnotice1 {

    line-height: 35px;

    background-color: #f68121;

    border-radius: 0 5px 5px 0;

    cursor: pointer;

    color: #fff;

    font-weight: 700;

    text-align: center;

    width: 35px;

    height: 45px;

}

.noleftpad {

    padding-left: 0;

    color: #ff0202;

}

.noticetopmarg {

    margin-top: 5px;

}

.mq_tag {

    padding-bottom: 7px;

}

@media(min-width:768px) and (max-width:991px) {

    .aline {

    padding: 0 2px;

}

.mob_menu_wrap ul li ul li {

    line-height: 25px;

}

.mob_menu_wrap ul li ul li a .li_txt {

    margin-top: 4px;

}

.form-feedback1 .c-imggs {

    width: 60%!important;

}

.noicte {

    width: 70px;

    top: 294px;

}

.abtuslbl {

    width: 85px;

    top: 294px;

}

.transprntdv {

    width: 55%;

    margin: auto;

    top: 293px;

}

.close_templt {

    right: 160px;

    top: 280px;

}

}@media (max-width:991px) {

    #head_menu {

    top: 95px;

}

.no_pad_req {

    padding: 0 0!important;

}

.full_wid {

    width: 100%!important;

}

.logo {

    border-bottom: 1px solid #e5e5e5;

}

.logo a {

    display: block;

    width: 100%}

.logo a img {

    display: block;

    margin: auto;

}

.tollfrebox {

    width: auto;

    float: left;

}

.phoneicon {

    float: left;

    margin: 0 15px;

}

.elogin-btn {

    width: auto;

    height: 30px;

    background-color: #fff;

    border-radius: 0;

    margin-top: 0;

    cursor: pointer;

    float: right;

    padding-right: 5px;

    border-left: 1px solid #e5e5e5;

    height: 40px;

    background-color: #363794;

}

.i-logn {

    color: #fff;

    float: left;

}

.i-txt {

    color: #fff;

    float: left;

}

.open_acc_abslt {

    width: auto;

    height: auto;

    background: #fe8100;

    border-radius: 0;

    position: static;

    z-index: 1001;

    top: 0;

    right: 0;

    display: block;

    padding: 0 15px 0 0;

    cursor: pointer;

    float: right;

}

.login_box {

    display: none;

    left: 0;

    right: 0;

    margin: auto;

}

.blck_bgg2 {

    width: 100%;

    height: 100%;

    position: fixed;

    z-index: 1001;

    background: #000;

    opacity: .5;

    display: none;

}

.mob_sm_micon {

    float: right;

    width: 20px;

    font-size: 34px;

    line-height: 63px;

}

.navdiv2 ul li:hover>.inrdiv {

    display: none;

}

.navdiv1 {

    padding: 0 0!important;

    text-align: left;

    height: 40px;

    line-height: 40px;

}

.marg-mins {

    display: block;

    float: left;

    width: 200px;

}

.navdiv1 .ionicon {

    font-size: 24px;

    padding: 0 15px;

    display: block;

    float: left;

    width: 45px;

}

.trangleicn {

    display: none;

}

.headrlnks {

    display: none;

}

.navdiv2 {

    position: absolute;

    top: 40px;

    background: #fff;

    display: none!important;

}

.inrdiv {

    display: block;

    position: relative;

}

.equity-conten {

    display: none!important;

}

.navdiv2 ul li {

    float: none;

    width: 100%;

    clear: both;

}

.navdiv2 ul {

    height: auto;

}

.navdiv2 ul li:hover {

    height: 40px;

}

.equity-tab, .loan-tab {

    width: 100%!important;

}

.mob_menu_wrap {

    width: 70%;

    background: #fff;

    position: fixed;

    top: 135px;

    display: none;

    z-index: 1001;

    overflow: auto;

}

.mob_menu_wrap ul {

    margin: 0;

    padding: 0;

    list-style: none;

}

.mob_menu_wrap ul li {

    width: 100%;

    height: auto;

    border-bottom: 1px dashed #ccc;

    clear: both;

    line-height: 35px;

    padding-left: 15px;

}

.mob_menu_wrap ul li:last-child {

    border-bottom: none;

}

.mob_menu_wrap ul li a {

    color: #333;

    text-decoration: none;

    font-size: 13px;

    display: inline-block;

    width: 100%}

.mob_menu_wrap ul li .li-icon {

    margin: 3px 0 0 0;

    float: left;

}

.mob_menu_wrap ul li a .li_txt {

    width: 300px;

    float: left;

    margin-top: 0;

    padding-left: 30px;

    font-size: 12px;

}

.mob_menu_wrap ul li .dropdwn {

    padding-top: 0;

    float: right;

    margin-right: 10px;

}

.mob_menu_wrap ul li .li-name {

    padding-left: 10px;

    padding-top: 0;

}

.mob_menu_wrap ul li ul {

    display: none;

    position: relative;

    clear: both;

    background: #fff;

    margin-left: -15px!important;

}

.mob_menu_wrap ul li .dropdwn2 {

    display: none;

    font-size: 14px;

    color: #000;

    padding-left: 5px;

    float: right;

    margin-right: 10px;

}

.frst_lvl.active {

    background: #e3e2f0;

}

.bord_leftt .elogin-btn {

    display: block;

}

.bord_leftt .open_acc_abslt {

    display: block;

}

.innr-title {

    height: 45px;

}

.elognbx {

    display: block;

}

.givheght {

    height: 35px!important;

}

.missiondata, .securitydata {

    padding: 0;

}

}@media(max-width:767px) {

    .givheght {

    height: 35px!important;

}

.main-title {

    padding: 0;

}

#head_menu {

    top: 90px;

}

.mob_menu_wrap {

    width: 90%;

    top: 130px;

}

.no_pad_req {

    padding: 0 0!important;

}

.tollfrebox {

    width: 30px;

    float: left;

}

.accnt-i {

    float: left;

    font-size: 25px;

    padding: 0 7px;

}

.accnt-txt {

    float: left;

    font-size: 13px;
    font-weight: bold;
    color: #161813;

    padding-top: 10px;

}

.i-logn {

    color: #fff;

    font-size: 20px;

    padding: 0 6px;

    float: left;

}

.i-txt {

    color: #fff;

    font-size: 12px;

    float: left;

    padding-top: 6px;

    padding-right: 10px;

}

.open_acc_abslt {

    padding: 0 5px 0 0;

}

.elogin-btn {

    height: 36px;

}

.phoneicon {

    margin: 0 8px;

}

.phonenum {

    font-size: 15px;

    color: #022056;

    padding-left: 10px;

    position: absolute;

    width: 100%;

    background: #fff;

    height: 36px;

    top: 0;

    line-height: 36px;

    display: none;

    left: 32px;

}

.quicklinks {

    /*display: none;*/

}

.quicklinks ul {

    padding: 0;

}

.quickbox .dropdwn {

    float: right;

    display: block;

}

.quickbox .dropdwn2 {

    display: none;

    font-size: 14px;

    color: #000;

    padding-left: 5px;

    float: right;

}

.copyrght {

    line-height: 16px;

    height: auto;

    padding: 5px 0!important;

}

.main-title h2 {

    font-size: 18px!important;

}

.pading5 {

    padding-left: 0;

}

.ralign {

    text-align: left;

}

.bord_leftt .elogin-btn {

    width: 30%;;
    display: block;
    padding-bottom: 45px;

}

.bord_leftt .open_acc_abslt {

    display: block;

}

.logindivbutons {

    width: 90%;

    float: left;

    margin: 0;

    padding: 0;

}

.logindivbutons .elogin-btn:nth-child(1) {

    width: 33%;
    margin-right: 10px;
    padding-bottom: 45px;
    /* float: right; */

}

.logindivbutons .elogin-btn:nth-child(2) {

    width: 30%;
    margin-right: 10px;
    padding-bottom: 45px;
    /* float: right; */

}

.logindivbutons .elogin-btn:nth-child(2) .i-logn {

    padding: 0;

}

.logindivbutons .elogin-btn:nth-child(2) .i-txt {

    padding: 9px 0 0 3px;

}

.bord_leftt .open_acc_abslt {

    position: relative;

    top: 0!important;

    width: 100%}

#head_menu {

    top: 127px;

}

.care-para {

    font-size: 12px;

}

.scll-top {

    font-size: 14px;

    height: 29px;

    line-height: 29px;

    right: 118px;

}

.copyrght-box {

    padding-bottom: 25px;

}

.copyrght-box .copyrght {

    padding: 10px 0 0 0!important;

}

.li-first {

    background-position: -4px 0;

}

.li-second {

    background-position: -30px 0;

}

.li-third {

    background-position: -57px 0;

}

.li-fourth {

    background-position: -87px 0;

}

.li-fifth {

    background-position: -115px 0;

}

.li-sixth {

    background-position: -148px 0;

}

.li-seven {

    background-position: -178px 0;

}

.li-eight {

    background-position: -206px 0;

}

.carebox {

    width: 90px;

}

.loancls {

    padding: 15px;

}

.loancls .numbr {

    padding: 3px 0;

}

.loancls .main-title {

    padding: 0 0 10px 0!important;

}

.tollfrebox {

    width: 10%;

    float: left;

}

.elogin-btn {

    width: 45%;

    float: left;

}

.open_acc_abslt {

    width: 45%;

    float: right;

}

.features-box {

    height: auto;

    padding: 20px 10px;

    border-bottom: 1px solid #ccc;

}

.innrlist {

    height: auto;

}

.innrlist ul {

    height: auto;

}

.features-box:nth-child(4) {

    border-bottom: 0;

}

.features-box .innr-title h2 {

    font-weight: 700;

    text-align: left;

}

.features-box4 .innr-title h2 {

    font-weight: 400;

    text-align: center;

}

.features-box4 .innr-title {

    width: 100%}

.morebutn {

    float: right;

    padding-right: 5px;

}

.call_me_lady {

    background-image: none;

}

.call_me_lady .featur_data {

    position: static;

    height: 100px;

}

.features-box4:hover .innr-title {

    top: 0;

    transform: none;

    bottom: auto;

}

.call_me_lady .innr-title {

    position: static;

}

.call_me_lady .innr-title h2 {

    font-size: 14px;

    line-height: 20px;

}

.features-box4:hover .innr-title h2 {

    font-size: 14px;

    word-break: break-all;

    line-height: 20px;

    text-shadow: 0 0 #000;

}

.features-box:hover .innr-title h2 {

    font-size: 14px;

    color: #1d1d1d!important;

}

.call_me_lady .eml {

    display: block;

}

.features-box:hover .innrlist ul li {

    font-size: 12px;

    color: #1d1d1d!important;

}

.features-box1, .features-box2, .features-box3, .features-box4 {

    transition: all 1s;

}

.features-box1 {

    padding-left: 10px!important;

}

.features-box1:hover {

    background-color: #fff;

    color: #1d1d1d!important;

}

.features-box2:hover {

    background-color: #fff;

    color: #1d1d1d!important;

}

.features-box3:hover {

    background-color: #fff;

    color: #1d1d1d!important;

}

.features-box4:hover {

    background-color: #fff;

    color: #1d1d1d!important;

}

.features-box .leftxt {

    text-align: center!important;

}

.features-box2 .innrlist, .features-box4 .innrlist {

    padding-top: 15px;

}

.features-box .featur_data.absolut {

    position: absolute;

    top: 0;

    height: 180px;

    padding: 10px 5px;

    width: 100%}

.outerbox .main-title {

    padding: 10px 0 20px 10px;

}

.color1 {

    background-color: #a4def6;

}

.color2 {

    background-color: #f58464;

}

.color3 {

    background-color: #ff9f00;

}

.color4 {

    background-color: #6bcfc7;

}

.innrlist ul li {

    padding: 4px 0 0 0;

    color: #1d1d1d;

}

.circlebox {

    width: 60px;

    height: 60px;

    margin: auto;

    display: block;

    border-radius: 100%;

    /* background-image: url(../images/featuresimg_mob.png); */

    float: left;

}

.featr1 {

    background-position: -15px -7px;

    background-color: #a4def6;

}

.featr2 {

    background-position: -113px -13px;

    background-color: #f58464;

}

.featr3 {

    background-position: -202px -10px;

    background-color: #ff9f00;

}

.featr4 {

    background-position: -280px 2px;

    background-color: #6bcfc7;

}

.innr-title {

    float: left;

    padding-left: 12px;

    padding-top: 20px;

}

.aline {

    padding: 0;

}

.main-title h1 {

    font-size: 18px;

}

.elogin-btn {

    border-top: none;

    border-bottom: none;

    border-right: none;

}

.elogin-btn .i-txt {

    padding-top: 10px;

}

.elogin-btn .i-logn {

    line-height: 34px;

}

.i-logn {

    color: #fff;

    float: left;

}

.i-txt {

    color: #fff;

    float: left;

}

.elogin-btn {

    width: 45%;

    float: left;

    margin: 0;

    padding: 0;

}

.open_acc_abslt {

    width: 45%;

    float: right;

    margin: 0;

    padding: 0;

}

.elognbx {

    width: 142px;

    margin: auto;

    display: block;

}

.features-box:first-child {

    padding-top: 0;

}

.call_me_lady .featur_data .innr-title {

    padding-top: 0;

    padding-left: 3px;

}

.features-box:last-child {

    padding-bottom: 0;

}

.missiondata, .securitydata {

    padding: 0 20px!important;

}

.careers_info p {

    padding-right: 20px;

}

.features-box4 {

    padding-bottom: 0;

}

.tooltip>.tooltip-arrow, .tooltip>.tooltip-inner {

    display: none;

}

.mobnobrder {

    border-left: none!important;

    border-right: none!important;

    border-bottom: none!important;

}

.moble-ul {

    padding-left: 15px;

}

.form-feedback1 .c-imggs {

    width: 80%!important;

}

.feedbackk {

    width: 310px;

}

.form-feedback .task-s {

    width: auto;

    padding: 0 20px;

    outline: 0;

}

.breset input {

    width: 100%!important;

    padding: 0 20px;

}

.scalebox {

    margin-top: 10px;

}

.n-slde {

    display: block;

}

.form-feedback textarea {

    height: 90px;

}

.fedbcktextmob {

    display: block;

    font-size: 14px;

    color: #2c318d;

    text-align: justify;

}

.fedbcktext {

    display: none;

}

.noicte {

    top: 205px;

    height: 30px;

    line-height: 30px;

}

.abtuslbl {

    top: 370px;

    height: 24px;

    line-height: 24px;

    width: 80px;

    font-size: 13px;

}

.close_templt {

    top: 18px;

    right: 13px;

}

.transprntdv {

    top: 16px;

    width: 85%}

.newnotice, .noleftpad, .noticediv {

    font-size: 12px;

}

.noticediv {

    padding-top: 10px;

}

.noleftpad {

    padding-top: 10px;

}

.mq_tag {

    padding-bottom: 3px;

}

}@media (min-width:600px) and (max-width:767px) {

    .phone_close {

    display: none!important;

}

.tollfrebox {

    width: 7%!important;

    float: left;

}

.elogin-btn {

    width: 47%;

    float: left;

    margin: 0;

    padding: 0;

}

.open_acc_abslt {

    width: 46%;

    float: right;

    margin: 0;

    padding: 0;

}

}@media (min-width:1200px) {

    .ban_container {

    height: 210px!important;

}

.banner_wrapper {

    height: 240px!important;

}

.navdiv2 ul li:nth-child(2) {

    width: 97px;

    padding-left: 13px;

}

.navdiv2 ul li:nth-child(3) {

    width: 148px;

    padding-left: 7px;

}

.navdiv2 ul li:first-child {

    width: 172px;

    padding-left: 18px;

}

.navdiv2 ul li:last-child {

    width: 125px;

    padding-left: 5px;

}

.navdiv2 ul li {

    width: 238px;

    padding-left: 4px;

}

.tollfrebox {

    padding-top: 8px;

}

.logo a {

    display: block;

    width: 205px;

}

.sub-li {

    display: block!important;

    margin: auto;

    width: 80%}

.fst_ul {

    width: 420px;

    margin: 0!important;

}

.sec_ul {

    width: 380px;

    margin: 0!important;

}

.thr_ul {

    width: 300px;

    margin: 0!important;

}

.for_ul {

    width: 1170px;

    margin: 0 0 0 -755px!important;

}

.four_ul {

    width: 450px;

    margin: 0!important;

}

.equity-tab li {

    width: 100%!important;

    padding-left: 0!important;

}

.aline {

    padding: 0 7px;

}

.bx-wrapper .bx-controls-auto, .bx-wrapper .bx-pager, .carousel-indicators {

    bottom: 7px!important;

}

.carousel-indicators .active {

    background-color: #fe8100;

    border: 1px solid #fe8100;

}

.carousel-indicators li {

    background-color: #fff;

}

.innr-title {

    height: 35px;

}

.innrlist ul {

    padding: 0 0 0 90px!important;

    height: 90px;

}

.features-box {

    height: 240px;

}

.call_me_lady .innr-title {

    bottom: 10px;

}

.call_me_lady .featur_data {

    height: 240px;

}

.call_me_lady {

    /* background-image: url(../images/call_us_lady-lg.jpg); */

    background-size: cover;

    background-position: center -78px;

    background-repeat: no-repeat;

}

.logo {

    width: 20%;

    padding: 0;

}

.extralink {

    width: 50%}

.lngbtn {

    width: 30%}

.txtsavheigt {

    font-size: 12px;

    line-height: 20px!important;

}

.noicte {

    width: 100px;

    height: 40px;

    line-height: 40px;

    background-color: #f68121;

    border-radius: 0 5px 5px 0;

    cursor: pointer;

    color: #fff;

    font-weight: 700;

    text-align: center;

    position: fixed;

    top: 335px;

    left: 0;

    z-index: 1000;

}

.abtuslbl {

    width: 100px;

    height: 40px;

    line-height: 40px;

    background-color: #f68121;

    border-radius: 5px 0 0 5px;

    cursor: pointer;

    color: #fff;

    font-weight: 700;

    text-align: center;

    position: fixed;

    top: 200;

    right: 0;

    z-index: 1000;

}

.close_templt {

    display: none;

    font-size: 13px;

    right: 393px;

    position: fixed;

    top: 323px;

    color: #fff;

    cursor: pointer;

    z-index: 10012;

    background-color: #333e63;

    width: 25px;

    line-height: 22px;

    height: 25px;

    text-align: center;

    border-radius: 100%;

    border: 1px solid #fff;

}

.transprntprev {

    display: none;

    background: #324057;

    width: 100%;

    height: 100%;

    opacity: .8;

    position: fixed;

    z-index: 1001;

    top: 0;

    left: 0;

    z-index: 10010;

}

.transprntdv {

    display: none;

    background: #fff;

    width: 40%;

    margin: auto;

    margin-top: 15px;

    position: fixed;

    top: 321px;

    left: 0;

    right: 0;

    border: 2px solid #fff;

    z-index: 10011;

}

.trans-cont {

    border: 1px solid #e1e2ef;

    margin: 10px;

    font-size: 12px;

    padding: 30px 20px;

}

}@media screen and (max-device-width:767px) and (orientation:landscape) {

    .circlebox {

    width: 60px;

    height: 60px;

    margin: auto;

    display: block;

    border-radius: 100%;

    /* background-image: url(../images/featuresimg_mob.png); */

    float: left;

}

.tollfrebox {

    width: 10%;

    float: left;

}

.feedbackk {

    position: fixed;

    bottom: 0;

    right: 0;

    z-index: 1003;

}

.phoneicon {

    margin: 0 12px;

}

.e-accntbx {

    width: 148px;

    margin: auto;

    display: block;

}

.close_templt {

    top: 18px;

    right: 10px;

}

.transprntdv {

    top: 16px;

    width: 90%}

.noicte {

    top: 253px;

}

.abtuslbl {

    top: 253px;

}

}@media screen and (min-width:0\0) {

    .feedbackk {

    right: 18px;

}

}@media screen and (-webkit-min-device-pixel-ratio:0) {

    .navdiv {

    margin-top: 0!important;

}

.mob_menu_wrap {

    top: 130px!important;

}

.open_acc_abslt {

    height: 36px;

}

}@media (min-width:320px) and (max-width:480px) {

    .mob_menu_wrap {

    width: 95%;

    top: 130px;

}

.new_banr {

    width: 100%;

    float: left;

    padding: 0;

    margin-top: 166px;

}

}@media(min-width:992px) and (max-width:1024px) {

    .lngbtn {

    width: 48%}

.extralink {

    width: 30%}

.logo {

    width: 22%;

    padding: 0;

}

.navdiv2 ul li:nth-child(2) {

    width: 84px!important;

    padding-left: 13px;

}

.navdiv2 ul li:nth-child(3) {

    width: 149px!important;

    padding-left: 7px;

}

.navdiv2 ul li:first-child {

    width: 166px;

    padding-left: 18px;

}

.navdiv2 ul li:last-child {

    width: 125px;

    padding-left: 5px;

}

.navdiv2 ul li {

    width: 132px;

    padding-left: 1;

}

}@media only screen and (max-width:767px) and (min-width:640px) {

    .new_banr {

    display: none;

}

.mobilebanner {

    height: 400px;

}

.mobilebanner .item a img, .mobilebanner ul li a img {

    display: block;

    padding-top: 160px;

    width: 100%}

.outerbox {

    margin-top: 160px;

}

}@media only screen and (max-width:640px) and (min-width:480px) {

    .new_banr {

    display: none;

}

.mobilebanner {

    height: 400px;

}

.mobilebanner .item a img, .mobilebanner ul li a img {

    display: block;

    padding-top: 160px;

    width: 100%}

.outerbox {

    margin-top: 90px;

}

}@media only screen and (max-width:480px) and (min-width:360px) {

    .new_banr {

    display: none;

}

.mobilebanner .item a img, .mobilebanner ul li a img {

    display: block;

    padding-top: 160px;

    width: 100%}

.outerbox {

    margin-top: 10px;

}

}@media only screen and (max-width:359.99px) and (min-width:320px) {

    .new_banr {

    display: none;

}

.mobilebanner .item a img, .mobilebanner ul li a img {

    display: block;

    padding-top: 160px;

    width: 100%;

    height: 400px;

}

.main-title h1 {

    font-size: 16px;

}

}
.home .container {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px){
    .home .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .home .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .home .container {
        width: 1170px;
    }
}
