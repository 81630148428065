.picker {
    font-size:16px;
    text-align:left;
    line-height:1.2;
    color:#000;
    position:absolute;
    z-index:10000;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.picker__input {
    cursor:default
}
.picker__input.picker__input--active {
    border-color:#0089ec
}
.picker__holder {
    width:100%;
    overflow-y:auto;
    -webkit-overflow-scrolling:touch;
    position:fixed;
    transition:background .15s ease-out, transform 0s .15s;
    -webkit-backface-visibility:hidden
}
/*!
 * Default mobile-first, responsive styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */

.picker__frame,
.picker__holder {
    top:0;
    bottom:0;
    left:0;
    right:0;
    -webkit-transform:translateY(100%);
    -ms-transform:translateY(100%);
    transform:translateY(100%)
}
.picker__frame {
    position:absolute;
    margin:0 auto;
    min-width:256px;
    max-width:320px;
    width:90%;
    -moz-opacity:0;
    opacity:0;
    transition:.15s ease-out
}
.picker__wrap {
    display:table;
    width:100%;
    height:100%
}
.picker__box {
    background:#fff;
    display:table-cell;
    vertical-align:middle
}
@media (min-height:26.5em) {
    .picker__box {
        font-size:1.25em
    }
}
@media (min-height:33.875em) {
    .picker__frame {
        overflow:visible;
        top:auto;
        bottom:-100%;
        max-height:80%
    }
    .picker__wrap {
        display:block
    }
    .picker__box {
        display:block;
        font-size:1.33em;
        border:1px solid #777;
        border-top-color:#898989;
        border-bottom-width:0;
        border-radius:5px 5px 0 0;
        box-shadow:0 12px 36px 16px rgba(0, 0, 0, .24);
        padding:0;
    }
    .picker--opened .picker__frame {
        top:auto;
        bottom:50%
    }
}
@media (min-height:40.125em) {
    .picker__frame {
        /* margin-bottom: 7.5% */
    }
    .picker__box {
        font-size:1.25em;
        border-bottom-width:1px;
        border-radius:5px
    }
}
.picker--opened .picker__holder {
    -webkit-transform:translateY(0);
    -ms-transform:translateY(0);
    transform:translateY(0);
    background:rgba(0, 0, 0, .32);
    zoom:1;
    transition:background .15s ease-out
}
.picker--opened .picker__frame {
    -webkit-transform:translateY(50%);
    -ms-transform:translateY(50%);
    transform:translateY(50%);
    -moz-opacity:1;
    opacity:1
}