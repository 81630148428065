.fixedSocialIcon {

    float: right;

    font-weight: 700;

    height: 120px;

    position: fixed;

    right: 0;

    top: 385px;

    z-index: 80;

    width: 34px;

    white-space: nowrap;

}

.fixedSocialIcon ul li a span:last-child {

    width: 440px;

    display: inline-block;

}

.list-unstyled {

    padding-left: 0;

    list-style: none;

}

.fixedSocialIcon ul li:hover {

    position: relative;

    left: -445px;

    transition: 0.5s ease-in-out;

}

.fixedSocialIcon ul li a:hover{

	text-decoration: none;

}

.fixedSocialIcon ul li {

    position: relative;

    width: 180px;

    left: -6px;

    transition: 0.5s ease-in-out;

    margin-bottom: 0px;

    background-color: transparent;

}

.fixedSocialIcon ul li a {

    height: 128px;

    display: block;

    color: #014286;

    line-height: 40px;

    margin-bottom: 5px;

    position: relative;

    font-weight: 600;

    font-size: 14px;

}

.fixedSocialIcon ul li a span {

    display: inline-block;

    height: 140px;

    margin-right: -5px;

    width: 40px;

    font-size: 15px;

    text-align-: center;

}

@media(min-width:768px) and (max-width:991.90px){

	.fixedSocialIcon ul li a span:last-child{

		width: 380px;

	}

	.fixedSocialIcon ul li:hover{

		left: -385px;

	}

}

@media(min-width:481px) and (max-width:767.90px){

	.fixedSocialIcon ul li a span:last-child{

		width: 285px;

	}

	.fixedSocialIcon ul li:hover{

		left: -290px;

	}

}

@media(min-width:320px) and (max-width:480.90px){

	.fixedSocialIcon ul li a span:last-child{

		width: 275px;

	}

	.fixedSocialIcon ul li:hover{

		left: -280px;

	}

}