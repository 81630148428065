.picker__footer,
.picker__header,
.picker__table {
    text-align:center
}
.picker__box {
    padding:0 1em
}
.picker__header {
    position:relative;
    padding:0.75em 0.5em;
    background-color:#fe8100;
}
.picker__month,
.picker__year {
    font-weight:500;
    display:inline-block;
    margin-left:.25em;
    margin-right:.25em
}
.picker__year {
    color:#999;
    font-size:.8em;
    font-style:italic
}
.picker__select--month,
.picker__select--year {
    border:1px solid #fff;
    padding:.5em;
    margin-left:.25em;
    margin-right:.25em;
    color:#fff;
    background:#fe8100;
    outline:none;
}
.picker__select--month {
    /* width: 35% */
}
.picker__select--year {
    /* width: 22.5% */
}
.picker__select--month:focus, .picker__select--year:focus, .picker__select--month:focus-visible, .picker__select--year:focus-visible {
    border-color:#fff;
}
.picker__nav--next,
.picker__nav--prev {
    position:absolute;
    padding:.5em;
    width:1em;
    height:1em;
    box-sizing:content-box;
    top:50%;
    cursor:pointer;
    transform:translateY(-50%);
}
.picker__nav--prev {
    left:0;
    /* padding-right: 1.25em */
}
.picker__nav--next {
    right:0;
    /* padding-left: 1.25em */
}
@media (min-width:24.5em) {
    .picker__select--month, .picker__select--year {
        /* margin-top: -.5em */
    }
    .picker__nav--next,
    .picker__nav--prev {
        /* top: .5em */
    }
    .picker__nav--prev {
        /* padding-right: 1.5em */
    }
    .picker__nav--next {
        /* padding-left: 1.5em */
    }
}
.picker__nav--next:before, .picker__nav--prev:before {
    content:" ";
    border-top:.5em solid transparent;
    border-bottom:.5em solid transparent;
    border-right:.75em solid #fff;
    width:0;
    height:0;
    display:block;
    margin:0 auto
}
.picker__nav--next:before {
    border-right:0;
    border-left:.75em solid #fff
}
/* .picker__nav--next:hover,
.picker__nav--prev:hover {
    cursor: pointer;
} */

.picker__nav--disabled,
.picker__nav--disabled:before, .picker__nav--disabled:before:hover, .picker__nav--disabled:hover {
    cursor:default;
    background:0 0;
    border-right-color:#f5f5f5;
    border-left-color:#f5f5f5
}
.picker--focused .picker__day--highlighted,
.picker__day--highlighted:hover, .picker__day--infocus:hover, .picker__day--outfocus:hover {
    cursor:pointer;
    color:#fff;
    background:#363794;
    transition:.2s ease-in-out;
}
.picker__table {
    border-collapse:collapse;
    border-spacing:0;
    table-layout:fixed;
    font-size:inherit;
    width:90%;
    margin:.75em auto .5em;
}
.picker__table td {
    margin:0;
    padding:0
}
.picker__weekday {
    width:14.285714286%;
    font-size:.75em;
    padding-bottom:.25em;
    color:#999;
    font-weight:500
}
@media (min-height:33.875em) {
    .picker__table {
        margin-bottom:.75em
    }
    .picker__weekday {
        padding-bottom:.5em
    }
}
.picker__day {
    padding:.3125em 0;
    font-weight:200;
    border:1px solid transparent
}
.picker__day--today {
    position:relative
}
.picker__day--today:before {
    content:" ";
    position:absolute;
    top:2px;
    right:2px;
    width:0;
    height:0;
    border-top:.5em solid #fff;
    border-left:.5em solid transparent
}
.picker__day--disabled:before {
    border-top-color:#aaa
}
.picker__day--outfocus {
    color:#ddd
}
.picker__day--highlighted {
    background-color:#363794;
    color:#fff;
}
.picker--focused .picker__day--selected,
.picker__day--selected,
.picker__day--selected:hover {
    background:#363794;
    color:#fff
}
.picker--focused .picker__day--disabled,
.picker__button--today[disabled],
.picker__button--today[disabled]:hover, .picker__day--disabled, .picker__day--disabled:hover {
    background:#f5f5f5;
    border-color:#f5f5f5;
    color:#ddd;
    cursor:default
}
.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
    background:#bbb
}
.picker__button--clear,
.picker__button--close,
.picker__button--today {
    border:1px solid #fff;
    background:#fff;
    font-size:.8em;
    padding:.66em 0;
    font-weight:700;
    width:33%;
    display:inline-block;
    vertical-align:bottom
}
.picker__button--clear:hover, .picker__button--close:hover, .picker__button--today:hover {
    cursor:pointer;
    color:#000;
    background:#b1dcfb;
    border-bottom-color:#b1dcfb
}
.picker__button--clear:focus, .picker__button--close:focus, .picker__button--today:focus {
    background:#b1dcfb;
    border-color:#0089ec;
    outline:0
}
.picker__button--clear:before, .picker__button--close:before, .picker__button--today:before {
    position:relative;
    display:inline-block;
    height:0
}
.picker__button--clear:before, .picker__button--today:before {
    content:" ";
    margin-right:.45em
}
.picker__button--today:before {
    top:-.05em;
    width:0;
    border-top:.66em solid #0059bc;
    border-left:.66em solid transparent
}
.picker__button--clear:before {
    top:-.25em;
    width:.66em;
    border-top:3px solid #e20
}
.picker__button--close:before {
    content:"\D7";
    top:-.1em;
    vertical-align:top;
    font-size:1.1em;
    margin-right:.35em;
    color:#777
}
.picker__button--today[disabled]:before {
    border-top-color:#aaa
}
.picker__footer {
    -webkit-box-shadow:0px -8px 8px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow:0px -9px 8px 0px rgba(0, 0, 0, 1);
    box-shadow:0px 0px 2px 0px rgba(0, 0, 0, .8);
}