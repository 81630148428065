body {
	font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
	box-sizing: border-box;
}
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"); */
@import url("./SuryodayBank _ Home_files/all.min.css");
@import url("./SuryodayBank _ Home_files/bootstrap.min.css");
@import url("./SuryodayBank _ Home_files/ionicons.css");
@import url("./SuryodayBank _ Home_files/new_header.css");
@import url("./SuryodayBank _ Home_files/jquery-ui.css");
@import url("./SuryodayBank _ Home_files/header_footer.css");
@import url("./SuryodayBank _ Home_files/side.css");
@import url("./SuryodayBank_files/suryoday.css");
@import url("./SuryodayBank_files/pickadate_base.css");
@import url("./SuryodayBank_files/pickadate_base_date.css");
@import url("./SuryodayBank_files/mobiscroll.css");
@import url("./FedNet_Dashboard_files/style.css");
@import url("./FedNet_Dashboard_files/theme_styles.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");

.header {
  border-bottom: solid 1px #9992d3;
  box-shadow: 0px 0px 5px #d3d3d3;
}
.navList {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  font-size: 14px;
}
.navList li {
  margin: 0 8px;
}
.navList a {
  color: #17a2b8;
  text-decoration: none;
}
.navList a:hover {
  color: #007bff;
}
.login_wrapper {
  /* background-color: #f1d5b0; */
  
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#efd5ae+0,f1d7b2+58,fbe8ca+100 */
  background: rgb(239,213,174); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(239,213,174,1) 0%, rgba(241,215,178,1) 58%, rgba(251,232,202,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(239,213,174,1) 0%,rgba(241,215,178,1) 58%,rgba(251,232,202,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(239,213,174,1) 0%,rgba(241,215,178,1) 58%,rgba(251,232,202,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efd5ae', endColorstr='#fbe8ca',GradientType=0 ); /* IE6-9 */

}
.banner {
  width: 65vw;
}
.login_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  width: calc(35vw - 120px);
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
.login_box label {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.login_box .text-danger {
  font-size: 12px;
  height: 22px;
}
.custom_icon {
  position: absolute;
  right: 10px;
  top: 28px;
}
.form-group {
  position: relative;
  margin-bottom: 8px;
}
.text-right {
  text-align: right;
}
.userNavList {
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style-type: none;
}
.userNavList a {
  text-decoration: none;
  color: #fff;
  padding: 8px 0 10px;
  display: block;
}
.userNavList li {
  height: 42px;
}
.userNavList .seperator {
  display: block;
  height: 100%;
  width: 1px;
  background-color: #005feb;
}
.dashboard .card-text {
  font-size: 14px;
}
.card-text a {
  text-decoration: none;
}
.service_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.service_list a {
  text-decoration: none;
  font-size: 14px;
}
.custom_circle {
  border: solid 14px transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 76px;
  width: 76px;
  margin-right: 24px;
}
.upgrade_plan {
  display: block;
  width: calc(100% + 32px);
  font-weight: bold;
  text-decoration: none;
  margin: 0 -16px -16px;
  padding: 9px;
  text-align: center;
  background-color: #f7f7f7;
}
.overflow_hide {
  overflow: hidden;
}
span.icons_dir, .icons_dir_mini {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f7f7f7;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}
.icons_dir_mini {
  font-size: 12px;
  height: 36px;
  width: 36px;
  margin-right: 16px;
}
.icons_dir_block {
  width: 120px;
  cursor: pointer;
}
.custom_badge {
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 8px;
  text-decoration: none;
}
.error_diro {
  font-size: 12px;
}
.login_bg {
  background-color: #2e3591;
}