

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'fednet-fonts' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-fb_arrow_normal_left:before {
    content: "\e600";
}

.icon-fb_add_paye:before {
    content: "\e601";
}

.icon-fb_account_summery:before {
    content: "\e602";
}

.icon-fb_accounts:before,
.icon-fb_account:before {
    content: "\e603";
}

.icon-fb_arrow_normal_right:before {
    content: "\e604";
}

.icon-fb_Guid_menu:before {
    content: "\e605";
}

.icon-fb_bank:before {
    content: "\e606";
}

.icon-fb_bell:before {
    content: "\e607";
}

.icon-fb_calender:before {
    content: "\e608";
}

.icon-fb_cup:before {
    content: "\e609";
}

.icon-fb_dashboard:before,
.icon-fb_home:before {
    content: "\e60b";
}

.icon-fb_logout:before {
    content: "\e60c";
}

.icon-fb_svg_mail:before {
    content: "\e60d";
}

.icon-fb_svg_makfastpayment:before {
    content: "\e60e";
}

.icon-fb_menu_push_from_left:before {
    content: "\e60f";
}

.icon-fb_push_from_right:before {
    content: "\e610";
}

.icon-fb_mini_statement:before {
    content: "\e611";
}

.icon-fb_minus:before {
    content: "\e612";
}

.icon-fb_navigation_help:before {
    content: "\e613";
}

.icon-fb_plus:before {
    content: "\e614";
}

.icon-fb_Refresh:before {
    content: "\e615";
}

.icon-fb_repeat:before {
    content: "\e616";
}


.icon-fb_requests:before,
.icon-fb_request:before {
    content: "\e617";
}

.icon-fb_round_arrow_left:before {
    content: "\e618";
}

.icon-fb_RounD_arrow_right:before {
    content: "\e619";
}

.icon-fb_round_minus:before {
    content: "\e61a";
}

.icon-fb_roundplus:before {
    content: "\e61b";
}

.icon-fb_rupees:before {
    content: "\e61c";
}

.icon-fb_search:before {
    content: "\e61d";
}

.icon-fb_sent_money:before {
    content: "\e61e";
}

.icon-fb_value_added_services:before,
.icon-fb_add-on_services:before,
.icon-fb_services:before {
    content: "\e61f";
}

.icon-fb_tab_services:before {
    content: "\e620";
}

.icon-fb_statment_of_account:before {
    content: "\e621";
}

.icon-fb_tic:before {
    content: "\e622";
}

.icon-fb_svg_time:before {
    content: "\e623";
}

.icon-fb_transactions:before {
    content: "\e624";
}

.icon-fb_transfer_funds:before,
.icon-fb_funds_transfer:before,
.icon-fb_transactions2:before {
    content: "\e625";
}

.icon-fb_beneficiaries:before,
.icon-beneficiaries:before {
    content: "\e900";
}

.icon-fb_bill_payments:before,
.icon-bill-payments:before {
    content: "\e901";
}

.icon-call-center:before {
    content: "\e902";
}


.icon-fb_general_service:before,
.icon-fb_general_services:before,
.icon-fb_customer_service:before,
.icon-customer-service:before {
    content: "\e903";
}

.icon-guide-me:before {
    content: "\e904";
}

.icon-fb_manage_tax:before,
.icon-manage-tax:before {
    content: "\e905";
}

.icon-fb_my_profile:before,
.icon-my-profile:before {
    content: "\e906";
}

.icon-fb_debit_card_services:before {
  content: "\e907";
}
.icon-fb_credit_cards:before {

  content: "\e907";

}


